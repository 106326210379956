<template>
  <div class="maintenance">
    <div class="toggle" :class="{ active: isMaintenance }">
      <div class="row">
        <h3>Maintenance settings</h3>
      </div>
      <span class="subtitle">Status</span>
      <div class="status-text row small-gap">
        {{ isMaintenance ? " Maintenance" : "Live" }}

        <span class="indicator" :class="{ active: isMaintenance }" />
      </div>

      <button
        class="btn"
        @click="toggleMaintenance"
        :class="isMaintenance ? 'warning' : 'success'"
      >
        {{ isMaintenance ? "Disable maintenance" : "Activate maintenance" }}
      </button>
    </div>
    <div class="title">
      <div class="row align-start">
        <div class="column">
          <span class="subtitle">Title</span>
          <input type="text" v-model="title" placeholder="Enter a new title" />
          <button @click="setTitle">Save title</button>
        </div>
        <div class="column">
          <span class="subtitle">Current title</span>
          <span class="text">{{ maintenanceData.title }}</span>
        </div>
      </div>
    </div>
    <div class="message">
      <div class="row align-start">
        <div class="column">
          <span class="subtitle">Message</span>
          <textarea
            v-model="message"
            placeholder="Enter a new message"
            rows="5"
          >
          </textarea>

          <button @click="setMessage">Save message</button>
        </div>

        <div class="column content">
          <span class="subtitle">Current message</span>
          <p class="text">{{ maintenanceData.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Maintenance",
  data() {
    return {
      message: "",
      title: "",
    };
  },
  methods: {
    toggleMaintenance() {
      this.$store.dispatch("settings/updateMaintenance", !this.isMaintenance);
    },
    setMessage() {
      this.$store.dispatch("settings/setMaintenanceMessage", this.message);
    },
    setTitle() {
      this.$store.dispatch("settings/setMaintenanceTitle", this.title);
    },
  },
  computed: {
    ...mapGetters("settings", ["isMaintenance", "maintenanceData"]),
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 700px;

  &.small-gap {
    gap: 5px;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-start {
    align-items: flex-start;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  white-space: break-spaces;

  &.content {
    .text {
      min-width: 400px;
      display: block;
    }
  }
}

.toggle,
.message,
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 50px;

  input {
    width: 350px;
    margin-bottom: 0;
  }

  textarea {
    width: 350px;
  }
  .highlight {
    font-weight: 600;
  }

  button {
    width: 200px;
  }
}
.indicator {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: grey;
  &.active {
    background-color: green;
  }
}

.subtitle {
  font-weight: 500;
}
</style>
