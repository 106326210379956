// import { db } from "../main.js";
// import { firestoreAction } from 'vuexfire'
// import firebase from "firebase/app";

const massAddShortlists = {
  namespaced: true,
  state: {
    selectedProduct: null,
    loading: false,
    init: false,
  },
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setSelectedProduct: (state, payload) => {
      state.selectedProduct = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
  },
  getters: {
    getCompanyShortlistNameById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).name;
    },
    getCompanyShortlistCompanyIdById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).companyId;
    },
    getSharedShortlistNameInternalById: (state, getters, rootState) => (id) => {
      return rootState.sharedShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).nameInternal;
    },
  },
  actions: {
    setupEditing: (context) => {
      console.log(context);
    },
    exitEditing: (context) => {
      console.log(context);
      context.commit("setSelectedProduct", null);
    },
  },
};
export default massAddShortlists;
