/* eslint-disable */
import { db } from "../main.js";
import { firestoreAction } from "vuexfire";

const registerAnalytics = {
  namespaced: true,
  state: {
    init: false,
    detailDocs: [],
    completedDocs: [],
    thisWeek: [],
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
  },
  getters: {
    detailDocSteps: (state) => (id) => {
      const doc = state.detailDocs.find((doc) => doc.id === id);

      if (doc !== undefined) {
        const steps = Object.keys(doc)
          .filter((key) => key !== "lastUpdate")
          .map((key) => {
            const stepData = doc[key];
            stepData.id = key;
            stepData.timestampDate = stepData.timestamp.toDate();
            return stepData;
          })
          .sort(
            (a, b) => b.timestampDate.getTime() - a.timestampDate.getTime()
          );
        return steps;
      } else {
        return [];
      }
    },
  },
  actions: {
    bindCompletedDocs: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "completedDocs",
        db
          .collection("app")
          .doc("registerAnalytics")
          .collection("completed")
          .orderBy("timestamp", "desc")
      );
    }),
    bindDetailDocs: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "detailDocs",
        db
          .collection("app")
          .doc("registerAnalytics")
          .collection("detail")
          .orderBy("lastUpdate", "desc")
          .limit(20)
      );
    }),
  },
};
export default registerAnalytics;
