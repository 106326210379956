<template>
  <!--- Chat window -->
  <div class="chat-window">
    <div class="drop-active" v-if="$refs.upload && $refs.upload.dropActive">
      <h2>Drop file to upload.</h2>
    </div>
    <div class="chat-header">
      <h3>
        <span class="chat-header-room-name">
          <span class="name-icons"
            >{{ selectedChatRoomName }}
            <div
              v-if="
                selectedChatRoom !== 'mass' &&
                selectedChatRoomData.type == 'personal'
              "
              class="flag-notification-settings"
            >
              <img
                :src="
                  require('../../assets/flags/' +
                    getUserLangById(selectedChatRoomData.identifier) +
                    '.png')
                "
              />

              <span
                class="material-icons"
                :class="{
                  off:
                    getPushNotificationsSettings(
                      selectedChatRoomData.identifier
                    ).chat == false,
                }"
                >{{
                  getPushNotificationsSettings(selectedChatRoomData.identifier)
                    .chat
                    ? "notifications"
                    : "notifications_off"
                }}</span
              >
            </div></span
          >
          <span
            class="company"
            v-if="
              selectedChatRoom !== 'mass' &&
              selectedChatRoomData.type == 'personal'
            "
            >{{ getCompanyNameByUserId(selectedChatRoomData.identifier) }}</span
          >
        </span>
      </h3>
      <button
        class="manually-clear-notification"
        @click="manuallyClearNotifications"
        v-if="notifications[selectedChatRoom] !== undefined"
      >
        Clear notifications
      </button>
      <button
        v-else-if="selectedChatRoom !== 'mass'"
        class="manually-clear-notification"
        @click="manuallyAddNotification"
      >
        Add notification
      </button>
    </div>

    <div class="chat-container">
      <div
        v-for="(message, index) in chatRoomMessages"
        :key="message.id"
        :class="{ admin: message.admin }"
        class="message-outer"
      >
        <div class="message">
          <span class="sent-by"
            >{{ message.authorName }}
            <span v-if="message.type !== undefined && message.type === 'post'">
              - News & Offers</span
            ></span
          >
          <span class="image" v-if="message.image && message.imageResized">
            <img
              @click="
                $store.commit('chat/setLightboxImage', message.imageResized)
              "
              :src="
                message.imageResized ? message.imageResized : message.imageFull
              "
            />
          </span>
          <span
            class="image-loader"
            v-else-if="message.image && !message.imageResized"
          >
            <span>Uploading and resizing image...</span>
          </span>

          <span
            class="product"
            v-if="message.productId !== undefined && message.productId !== null"
          >
            <ChatProductCard
              :product="getProductByFirebaseId(message.productId)"
            />
          </span>

          <span
            class="shortlist"
            v-if="
              message.shortlistId !== undefined && message.shortlistId !== null
            "
          >
            <div>
              <div class="chat-selected-shortlist-icon">
                <div class="material-icons">list_alt</div>
              </div>
              <div class="chat-selected-shortlist-name">
                <div class="sub-header">Shortlist</div>
                <div class="name">
                  {{ getShortlistName(message.shortlistId) }}
                </div>
                <!-- <div
                  class="name"
                  v-if="
                    getSharedShortlistById(message.shortlistId) !== undefined
                  "
                >
                  {{ getSharedShortlistById(message.shortlistId).name }}
                </div>
                <div
                  class="name"
                  v-else-if="
                    getCompanyShortlistById(message.shortlistId) !== undefined
                  "
                >
                  {{ getCompanyShortlistById(message.shortlistId).name }}
                </div> -->
                <!-- <div class="name" v-else>Deleted shortlist</div> -->
              </div>
            </div>
          </span>

          <div
            class="chat-news-message"
            v-if="message.type !== undefined && message.type === 'post'"
          >
            <span>{{ message.postType }}</span>
            <p>{{ message.postTitle }}</p>
          </div>
          <span
            class="message-content"
            v-else-if="message.message !== ''"
            v-html="message.message"
            @click="console(message.id)"
          >
          </span>
          <span class="timestamp">{{ message.readableTime }}</span>
          <span
            class="timestamp"
            v-if="
              (index === indexOfFirstIncomingMessage ||
                index == indexOfFirstAdminMessage) &&
              message.readBy !== undefined &&
              message.readBy.length > 0
            "
          >
            Läst av {{ message.readBy.join(", ") }}
          </span>
          <span class="timestamp" v-if="message.roomCount !== undefined">
            Sent to {{ message.roomCount }} {{ message.type }} chat rooms.
          </span>
        </div>
      </div>
    </div>

    <div class="chat-uploaded-file" v-if="files[0]">
      <div class="preview-image">
        <img :src="files[0].blob" />
        <button class="remove-upload" @click="files = []">
          <span class="material-icons">close</span>
        </button>
      </div>
    </div>

    <div class="chat-selected-product" v-if="selectedProduct !== false">
      <ChatProductCard :product="getProductByFirebaseId(selectedProduct)" />
      <button class="remove-product" @click="removeProduct">
        <span class="material-icons">close</span>
      </button>
    </div>

    <div class="chat-selected-shortlist" v-if="selectedShortlist !== false">
      <div class="chat-selected-shortlist-icon">
        <div class="material-icons">list_alt</div>
      </div>
      <div class="chat-selected-shortlist-name">
        <div class="sub-header">Shortlist</div>
        <div class="name">{{ selectedShortlist.name }}</div>
      </div>
      <button class="remove-shortlist" @click="removeShortlist">
        <span class="material-icons">close</span>
      </button>
    </div>

    <div
      class="chat-toolbar"
      v-if="!files[0] && !selectedProduct && !selectedShortlist"
    >
      <file-upload
        ref="upload"
        extensions="png,jpg,jpeg"
        accept="image/png,image/jpg,image/jpeg"
        v-model="files"
        @input-file="inputFile"
        @input-filter="inputFilter"
        :multiple="false"
        :drop="true"
        class="new-file-upload"
        v-if="selectedChatRoom !== 'mass'"
      >
        <button class="chat-toolbar-button">
          <span class="material-icons"> image </span>
          Image
        </button>
      </file-upload>

      <button class="chat-toolbar-button" @click="toggleAddProduct">
        <span class="material-icons"> sell </span>
        Product

        <div class="chat-toolbox-popup products" v-if="productOpen" @click.stop>
          <h4>Select a product</h4>

          <input
            type="search"
            placeholder="Search products..."
            v-model="productSearch"
          />
          <div class="chat-toolbar-products">
            <div
              v-for="product in getProductsBySearch(productSearch)"
              :key="product.id"
              @click.stop="selectedProduct = product.firebaseId"
            >
              <span class="id">{{ product.vismaId }}</span>
              <span class="stock">{{ product.stock }}{{ product.unit }}</span>
              <span class="name">{{ product.name }}</span>
            </div>
          </div>
        </div>
      </button>

      <button
        class="chat-toolbar-button"
        @click="toggleAddShortlist"
        v-if="selectedChatRoom !== 'mass'"
      >
        <span class="material-icons"> list_alt </span>
        Shortlist

        <div
          class="chat-toolbox-popup shortlists"
          v-if="shortlistOpen"
          @click.stop
        >
          <h4>Select a shortlist</h4>

          <div class="chat-toolbar-shortlists">
            <div
              v-for="shortlist in selectableShortlists"
              :key="shortlist.id"
              @click.stop="selectedShortlist = shortlist"
            >
              <span class="type">{{ shortlist.type }}</span>
              <span class="name">{{ shortlist.name }}</span>
            </div>
          </div>
        </div>
      </button>
    </div>

    <div class="chat-message-form">
      <textarea
        ref="chatMessage"
        type="text"
        placeholder="Message"
        @keydown.enter.shift.exact.prevent="sendMessage"
      />

      <button @click="sendMessage" class="send-button">
        <span class="material-icons" v-if="!loading"> send </span>
        <span class="send-loader" v-else></span>
        Send
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import { db } from "../../main";
// let audio = new Audio(require('@/assets/message.mp3'))

export default {
  name: "ChatWindow",
  data() {
    return {
      loading: false,
      ownerOfRoom: false,
      files: [],
      selectedProduct: false,
      productSearch: "",
      productOpen: false,
      shortlistOpen: false,
      selectableShortlists: [],
      selectedShortlist: false,
    };
  },

  components: {},
  computed: {
    ...mapState({
      userId: (state) => state.settings.user.id,
      selectedChatRoom: (state) => state.chat.selectedChatRoom,
      chatRoomMessages: (state) => state.chat.chatRoomMessages,
      appBrand: (state) => state.settings.appSettings.app,
      notifications: (state) => state.chat.notifications,
      userVismaId: (state) => state.settings.user.vismaId,
    }),
    ...mapGetters("chat", [
      "selectedChatRoomName",
      "getAccountOwner",
      "selectedChatRoomData",
      "indexOfFirstIncomingMessage",
      "indexOfFirstAdminMessage",
      "chatMessageByIndex",
      "getShortlistName",
    ]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "getProductByFirebaseId",
    ]),
    ...mapGetters("users", [
      "getUserNameById",
      "getUserCompanyById",
      "getUserLangById",
      "getPushNotificationsSettings",
      "getCompanyNameByUserId",
    ]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("sharedShortlists", ["getSharedShortlistById"]),
    ...mapGetters("companyShortlists", ["getCompanyShortlistById"]),
  },
  methods: {
    console: function (message) {
      console.log(message);
      console.log(this.indexOfFirstIncomingMessage);
    },
    toggleAddProduct: function () {
      if (this.productOpen) {
        this.productOpen = false;
        this.selectedProduct = false;
        return;
      } else {
        this.shortlistOpen = false;
        this.productOpen = true;

        return;
      }
    },
    toggleAddShortlist: async function () {
      if (this.shortlistOpen) {
        this.shortlistOpen = false;
        this.selectableShortlists = [];
      } else {
        this.selectedProduct = false;
        this.productOpen = false;
        this.shortlistOpen = true;

        let companyId;

        if (this.selectedChatRoomData.type == "company") {
          companyId = this.selectedChatRoomData.identifier;
        } else {
          companyId = this.getUserCompanyById(
            this.selectedChatRoomData.identifier
          );
        }

        const company = this.getCompanyByVismaId(companyId);

        Object.keys(company.shortlists).forEach((key) => {
          this.selectableShortlists.push({
            id: key,
            name: company.shortlists[key].name,
            type: company.shortlists[key].type,
          });
        });
      }
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log("response", newFile.response);
        if (newFile.xhr) {
          //  Get the response status code
          console.log("status", newFile.xhr.status);
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|png)$/i.test(newFile.name)) {
          return prevent();
        }
      }

      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    manuallyClearNotifications: function () {
      this.$store
        .dispatch("chat/removeNotification", this.selectedChatRoom)
        .then(() => {
          console.log(this.indexOfFirstIncomingMessage);
          if (this.indexOfFirstIncomingMessage !== -1) {
            const chatMessage = this.chatMessageByIndex(
              this.indexOfFirstIncomingMessage
            );
            if (
              chatMessage.readBy === undefined ||
              chatMessage.readBy.length === 0
            ) {
              this.$store
                .dispatch("chat/markMessageAsRead", {
                  chatRoomId: this.selectedChatRoom,
                  messageId: chatMessage.id,
                  name: this.getUserNameById(this.userId),
                })
                .then(() => {
                  console.log("done");
                });
            }
          }
        });
    },
    manuallyAddNotification: function () {
      this.$store
        .dispatch("chat/addNotification", this.selectedChatRoom)
        .then(() => {
          console.log("done");
        });
    },
    sendMessage: function () {
      if (this.loading) {
        return false;
      }

      let message = this.$refs.chatMessage.value;
      if (
        message === "" &&
        this.files[0] === undefined &&
        this.selectedProduct === false &&
        this.selectedShortlist === false
      ) {
        console.log("Invalid message");
        return false;
      }

      let chatId = this.selectedChatRoom;
      this.loading = true;
      this.$refs.chatMessage.value = "";

      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "may",
        "jun",
        "jul",
        "aug",
        "sep",
        "oct",
        "nov",
        "dec",
      ];
      let d = new Date();
      const readableTime =
        d.getDate() +
        " " +
        months[d.getMonth()] +
        ", " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2);

      let imageFile = null;
      let imageFileType = null;
      if (this.files[0]) {
        (imageFile = this.files[0].file), (imageFileType = this.files[0].type);
      }

      let authorName = "";

      if (this.appBrand === "fiskidag") {
        authorName = "Fisk Idag - " + this.getUserNameById(this.userId);
      } else if (this.appBrand === "fsg") {
        authorName = "FSG - " + this.getUserNameById(this.userId);
      } else {
        authorName =
          this.appBrand[0].toUpperCase() +
          this.appBrand.slice(1) +
          " - " +
          this.getUserNameById(this.userId);
      }

      this.$store
        .dispatch("chat/sendMessage", {
          chatId: chatId,
          message: message,
          author: this.userId,
          authorName: authorName,
          admin: true,
          readableTime: readableTime,
          imageFile: imageFile,
          imageFileType: imageFileType,
          productId: this.selectedProduct,
          shortlistId:
            this.selectedShortlist !== false ? this.selectedShortlist.id : null,
        })
        .then(() => {
          console.log(" Seeeennnt ");

          this.loading = false;
          this.files = [];
          this.selectedProduct = false;
          this.productOpen = false;
          this.shortlistOpen = false;
          this.selectedShortlist = false;
          this.selectableShortlists = [];

          if (this.selectedChatRoom === "mass") {
            this.$toast.center(
              "Mass message sent successfully! Page will refresh in 3 seconds."
            );

            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        });
    },
    removeProduct: function () {
      this.selectedProduct = false;
      this.productOpen = false;
    },
    removeShortlist: function () {
      this.shortlistOpen = false;
      this.selectedShortlist = false;
      this.selectableShortlists = [];
    },
  },
  watch: {
    // notifications() {
    //   if (
    //     this.getAccountOwner(this.selectedChatRoom) === this.userVismaId &&
    //     this.notifications[this.selectedChatRoom] !== undefined
    //   ) {
    //     this.$store
    //       .dispatch("chat/removeNotification", this.selectedChatRoom)
    //       .then(() => {
    //         console.log("Cleared notification");
    //       });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
label[for="file"] {
  cursor: pointer !important;
  z-index: -1 !important;
}
</style>
