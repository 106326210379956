<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>Amount of posts</label>
        <input
          type="number"
          placeholder="Amount of posts"
          :value="selectedBlock.limit"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'limit',
              type: 'number',
              value: $event.target.value,
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesUpsellBlockForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
