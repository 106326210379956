<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>Background media</label>
        <input
          type="text"
          placeholder="Background media url"
          :value="selectedBlock.bgMedia"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'bgMedia',
              type: 'trim',
              value: $event.target.value,
            })
          "
        />
      </div>
      <div class="form-input">
        <label>Height (in px)</label>
        <input
          type="number"
          :value="selectedBlock.height"
          placeholder="Height"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'height',
              type: 'number',
              value: $event.target.value,
            })
          "
        />
      </div>
    </div>
    <div class="web-pages-lang-fields">
      <div
        v-for="(lang, index) in enabledLanguages"
        :key="'lang-' + index"
        class="web-pages-lang-group"
      >
        <img :src="require('../../assets/flags/' + lang.code + '.png')" />
        <div class="form-input">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            :value="selectedBlock.title[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'title.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input">
          <label>Text</label>
          <textarea
            placeholder="Text"
            :value="selectedBlock.text[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'text.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          ></textarea>
        </div>
        <div class="form-input">
          <label>Button text</label>
          <input
            type="text"
            placeholder="Button text"
            :value="selectedBlock.primaryButton[lang.code].text"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'primaryButton.' + lang.code + '.text',
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>Button url - https if external, /xxx if internal</label>
          <input
            type="text"
            placeholder="Url"
            :value="selectedBlock.primaryButton[lang.code].url"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'primaryButton.' + lang.code + '.url',
                type: 'trim',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>Button style</label>
          <div class="form-input-radio">
            <input
              type="radio"
              :id="'primary-' + lang.code"
              value="primary"
              :checked="
                selectedBlock.primaryButton[lang.code].style === 'primary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'primaryButton.' + lang.code + '.style',
                  type: 'text',
                  value: 'primary',
                })
              "
            />

            <label :for="'primary-' + lang.code">Primary</label>
          </div>

          <div class="form-input-radio">
            <input
              type="radio"
              :id="'secondary-' + lang.code"
              value="secondary"
              :checked="
                selectedBlock.primaryButton[lang.code].style === 'secondary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'primaryButton.' + lang.code + '.style',
                  type: 'text',
                  value: 'secondary',
                })
              "
            />
            <label :for="'secondary-' + lang.code">Secondary</label>
          </div>
        </div>
        <div class="form-input check">
          <label>Open in new tab</label>
          <input
            type="checkbox"
            :checked="
              selectedBlock.primaryButton[lang.code].target === '_blank'
            "
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'primaryButton.' + lang.code + '.target',
                type: 'text',
                value: $event.target.checked ? '_blank' : '',
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesHeroBlockForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
