<template>
  <div class="unavailable-dates">
    <h4>Unavailable dates</h4>
    <date-picker
      v-model="unavailableDates"
      type="date"
      valueType="format"
      placeholder="öh"
      :multiple="true"
      :inline="true"
      format="YYYY-MM-DD"
      :lang="lang"
    ></date-picker>
  </div>
</template>

<script>
// import { mapState, mapGetters } from 'vuex';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "UnavailableDates",
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  components: {
    DatePicker,
  },
  mounted() {},
  created() {
    this.$store.commit(
      "settings/setUnavailableDates",
      this.$store.state.settings.appSettings.unavailableDates
    );
  },
  computed: {
    unavailableDates: {
      get() {
        return this.$store.state.settings.unavailableDates;
      },
      set(value) {
        this.$store.dispatch("settings/updateUnavailableDates", value);
      },
    },
  },
  methods: {},
};
</script>
