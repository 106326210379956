<template>
  <div>
    <div class="table-header-small">
      Products shown to company - {{ singleCompanyProducts.length }}
    </div>
    <div class="admin-table single-company-products-table">
      <div class="table-header">
        <span class="label id">Id</span>
        <span class="label name">Product</span>
        <span
          v-if="
            company[0].productsPrice === 3 || company[0].productsPrice === 4
          "
          class="label price"
          >Price</span
        >
      </div>
      <div class="inner">
        <div v-for="product in singleCompanyProducts" :key="product.id">
          <span class="id">{{ product.vismaId }} </span>
          <span class="name">{{ product.name }}</span>
          <span v-if="product.price !== 0" class="price">{{
            product.price
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "SingleCompanyProducts",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState({
      company: (state) => state.companies.singleCompany,
    }),
    ...mapGetters("companies", ["singleCompanyProducts"]),
  },
  mounted() {},
};
</script>
