var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-mass-message-selector"},[(_vm.filterOpen)?_c('div',{staticClass:"chat-mass-message-selector-wrapper"},[_c('div',{staticClass:"chat-mass-message-selector-inner"},[_c('div',{staticClass:"notifications-group-select"},[_c('div',{staticClass:"admin-table company-categories"},[_vm._m(0),_c('div',{staticClass:"inner"},_vm._l((_vm.companyCategories),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(_vm.selectedCompanyCategories.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'add',
                      field: 'CompanyCategories',
                      value: key,
                    })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'remove',
                      field: 'CompanyCategories',
                      value: key,
                    })}}},[_vm._v(" Remove ")])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup1Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups1),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                      _vm.excludedPriceGroups1.indexOf(key) === -1 &&
                      _vm.selectedPriceGroups1.indexOf(key) === -1
                    )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'add',
                        field: 'PriceGroups1',
                        value: key,
                      })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups1.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'remove',
                        field: 'PriceGroups1',
                        value: key,
                      })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                      _vm.selectedPriceGroups1.indexOf(key) === -1 &&
                      _vm.excludedPriceGroups1.indexOf(key) === -1
                    )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'PriceGroups1',
                        value: key,
                      })}}},[_vm._v("Add")]):_vm._e(),(_vm.selectedPriceGroups1.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'PriceGroups1',
                        value: key,
                      })}}},[_vm._v("Remove")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup2Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups2),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                      _vm.excludedPriceGroups2.indexOf(key) === -1 &&
                      _vm.selectedPriceGroups2.indexOf(key) === -1
                    )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'add',
                        field: 'PriceGroups2',
                        value: key,
                      })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups2.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'remove',
                        field: 'PriceGroups2',
                        value: key,
                      })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                      _vm.selectedPriceGroups2.indexOf(key) === -1 &&
                      _vm.excludedPriceGroups2.indexOf(key) === -1
                    )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'PriceGroups2',
                        value: key,
                      })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedPriceGroups2.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'PriceGroups2',
                        value: key,
                      })}}},[_vm._v(" Remove ")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup3Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups3),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                      _vm.excludedPriceGroups3.indexOf(key) === -1 &&
                      _vm.selectedPriceGroups3.indexOf(key) === -1
                    )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'add',
                        field: 'PriceGroups3',
                        value: key,
                      })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups3.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'remove',
                        field: 'PriceGroups3',
                        value: key,
                      })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                      _vm.selectedPriceGroups3.indexOf(key) === -1 &&
                      _vm.excludedPriceGroups3.indexOf(key) === -1
                    )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'PriceGroups3',
                        value: key,
                      })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedPriceGroups3.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'PriceGroups3',
                        value: key,
                      })}}},[_vm._v(" Remove ")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_vm._m(1),_c('div',{staticClass:"inner"},_vm._l((_vm.routes),function(value,key){return _c('div',{key:'route-' + key},[_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                      _vm.excludedRoutes.indexOf(key) === -1 &&
                      _vm.selectedRoutes.indexOf(key) === -1
                    )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'add',
                        field: 'Routes',
                        value: key,
                      })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedRoutes.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                        type: 'remove',
                        field: 'Routes',
                        value: key,
                      })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                      _vm.selectedRoutes.indexOf(key) === -1 &&
                      _vm.excludedRoutes.indexOf(key) === -1
                    )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'Routes',
                        value: key,
                      })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedRoutes.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'Routes',
                        value: key,
                      })}}},[_vm._v(" Remove ")]):_vm._e()])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Companies")]),_c('div',{staticClass:"company-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companySearch),expression:"companySearch"}],attrs:{"type":"text","placeholder":"Search company..."},domProps:{"value":(_vm.companySearch)},on:{"input":function($event){if($event.target.composing)return;_vm.companySearch=$event.target.value}}})])]),_c('div',{staticClass:"inner"},_vm._l((_vm.getCompaniesBySearch(_vm.companySearch)),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value.name))]),_c('div',{staticClass:"actions"},[(_vm.selectedCompanies.indexOf(value.vismaId) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'add',
                      field: 'Companies',
                      value: value.vismaId,
                    })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'remove',
                      field: 'Companies',
                      value: value.vismaId,
                    })}}},[_vm._v(" Remove ")])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_vm._m(2),_c('div',{staticClass:"inner"},_vm._l((_vm.getAdminAccountOwners),function(value,index){return _c('div',{key:'sales-users-' + index},[_c('span',[_vm._v(_vm._s(value.name))]),_c('div',{staticClass:"actions"},[(_vm.selectedSalesAdmins.indexOf(value.vismaId) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'add',
                      field: 'SalesAdmins',
                      value: value.vismaId,
                    })}}},[_vm._v(" Add filter ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateSelection', {
                      type: 'remove',
                      field: 'SalesAdmins',
                      value: value.vismaId,
                    })}}},[_vm._v(" Remove filter ")])])])}),0)]),_c('ChatFilterSelections'),_c('div',{staticClass:"admin-table"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Final companies - "+_vm._s(_vm.finalCompanies.length))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.finalCompanies),function(value,i){return _c('div',{key:'cat-' + i},[_c('span',[_vm._v(_vm._s(_vm.getCompanyByVismaId(value.vismaId).name))]),_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('chat/updateExclusion', {
                    type: 'add',
                    field: 'Companies',
                    value: value.vismaId,
                  })}}},[_vm._v(" Exclude ")])])}),0)])],1),_c('div',{staticClass:"chat-mass-message-selector-actions"},[_c('div',[_c('button',{on:{"click":_vm.selectAll}},[_vm._v("Select all")]),_c('button',{on:{"click":function($event){return _vm.$store.dispatch('chat/clearAllSelections')}}},[_vm._v(" Clear all ")])]),_c('div',{staticClass:"chat-mass-message-selector-type"},[_c('div',{staticClass:"chat-new-filter-check",on:{"click":function($event){return _vm.$store.commit('chat/setSelectedType', 'company')}}},[_c('div',{staticClass:"check",class:{ active: _vm.selectedType == 'company' }}),_c('label',[_vm._v("Company")])]),_c('div',{staticClass:"chat-new-filter-check",on:{"click":function($event){return _vm.$store.commit('chat/setSelectedType', 'personal')}}},[_c('div',{staticClass:"check",class:{ active: _vm.selectedType == 'personal' }}),_c('label',[_vm._v("Personal")])])]),_c('div',[_c('button',{on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('button',{on:{"click":_vm.save}},[_vm._v("Continue")])])])])]):_vm._e(),(!_vm.massSending)?_c('button',{on:{"click":function($event){return _vm.$store.commit('chat/setFilterOpen', true)}}},[_vm._v(" Create mass message ")]):_vm._e(),(_vm.massSending)?_c('div',{staticClass:"mass-sending-in-progress"},[_c('span',{staticClass:"title"},[_vm._v("Sending mass message")]),_c('span',{staticClass:"target"},[_vm._v("Target: "+_vm._s(_vm.finalCompanies.length)+" companies")]),_c('span',{staticClass:"type"},[_vm._v("Roomtype: "+_vm._s(_vm.selectedType))]),_c('div',{staticClass:"actions"},[_c('button',{on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('button',{on:{"click":function($event){return _vm.$store.commit('chat/setFilterOpen', true)}}},[_vm._v(" Change selection ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Company Category")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Routes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Filter by sales admin")])])
}]

export { render, staticRenderFns }