<template>
  <div class="web-pages-editor-actions">
    <button
      v-if="editing !== 'create' && editing !== false"
      class="delete"
      @click="doDelete()"
    >
      Delete
    </button>
    <div class="right-buttons">
      <button class="back" @click="goBack()">Back to list</button>
      <button class="save" @click="doSave()">Save</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WebPagesEditorActions",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      editing: (state) => state.webPages.editing,
      editingMenus: (state) => state.webPages.editingMenus,
    }),
  },
  mounted() {},
  methods: {
    goBack: function () {
      this.$store.commit("webPages/setEditing", false);
      this.$store.commit("webPages/setEditingMenus", false);
      this.$store.dispatch("webPages/resetEditing");
    },
    doDelete: async function () {
      await this.$store.dispatch("webPages/deleteWebPage");
      this.$toast.center("Web page deleted.");
    },
    doSave: async function () {
      if (this.editing !== false) {
        const result = await this.$store.dispatch("webPages/saveWebPage");
        console.log(result);
        this.$toast.center(result.message);
      } else {
        // TODO : Save menu editing.
        console.log("start");
        const result = await this.$store.dispatch("webPages/saveMenus");

        console.log(result);
        this.$toast.center(result.message);
      }
    },
  },
};
</script>
