import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const cartUpsell = {
  namespaced: true,
  state: {
    upsellLists: [],
    selectedUpsellList: null,
    editingName: null,
    editingType: "cart",
    products: [],
    currentStep: 0, // 0 = Filter companies, 1 = Products
    loading: false,
    selectedCompanies: [],
    excludedCompanies: [],
    setListsConfirm: false,
    selectedCompanyCategories: [],
    selectedPriceGroups1: [],
    excludedPriceGroups1: [],
    selectedPriceGroups2: [],
    excludedPriceGroups2: [],
    init: false,
    editingPriceGroups1: [],
    editingPriceGroups2: [],
    listPublic: false,
  },
  mutations: {
    setSelectedUpsellList: (state, payload) => {
      state.selectedUpsellList = payload;
    },
    setEditingName: (state, payload) => {
      state.editingName = payload;
    },
    setEditingType: (state, payload) => {
      state.editingType = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setCurrentStep: (state, payload) => {
      state.currentStep = payload;
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setSelectedCompanies: (state, payload) => {
      state.selectedCompanies = payload;
    },
    setExcludedCompanies: (state, payload) => {
      state.excludedCompanies = payload;
    },
    setSetListsConfirm: (state, payload) => {
      state.setListsConfirm = payload;
    },
    setSelectedCompanyCategories: (state, payload) => {
      state.selectedCompanyCategories = payload;
    },
    setSelectedPriceGroups1: (state, payload) => {
      state.selectedPriceGroups1 = payload;
    },
    setExcludedPriceGroups1: (state, payload) => {
      state.excludedPriceGroups1 = payload;
    },
    setSelectedPriceGroups2: (state, payload) => {
      state.selectedPriceGroups2 = payload;
    },
    setExcludedPriceGroups2: (state, payload) => {
      state.excludedPriceGroups2 = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
    setEditingPriceGroups1: (state, payload) => {
      state.editingPriceGroups1 = payload;
    },
    setEditingPriceGroups2: (state, payload) => {
      state.editingPriceGroups2 = payload;
    },
    setListPublic: (state, payload) => {
      state.listPublic = payload;
    },
  },
  getters: {
    homeUpsellLists: (state, getters, rootState) => {
      let homeUpsellLists = rootState.settings.appHelpers.homeUpsellOrder;

      // Sort by object value
      let sortedLists = Object.keys(homeUpsellLists).sort(function (a, b) {
        return homeUpsellLists[a] - homeUpsellLists[b];
      });

      return sortedLists;
    },
    homeUpsellListsWithData: (state, getters) => {
      let homeUpsellLists = getters.homeUpsellLists;
      let lists = [];

      homeUpsellLists.forEach((listId) => {
        const list = state.upsellLists.find((list) => list.id === listId);

        if (list !== undefined) {
          lists.push(list);
        }
      });

      return lists;
    },
    // orderedHomeUpsellLists: (state, getters, rootState) => {},
    currentUpsellLists: (state) => (searchString, type) => {
      let lists = state.upsellLists;

      if (type === "cart") {
        lists = lists.filter((list) => list.type === "cart");
      } else if (type === "home") {
        lists = lists.filter((list) => list.type === "home");
      }

      if (searchString === "") {
        return lists;
      } else {
        if (searchString.includes("@")) {
          return lists.filter((list) =>
            list.author.email.toLowerCase().includes(searchString.toLowerCase())
          );
        } else {
          return lists.filter((list) =>
            list.name.toLowerCase().includes(searchString.toLowerCase())
          );
        }
      }
    },
    selectedUpsellListData: (state) => {
      if (state.selectedUpsellList === null) {
        return null;
      }
      return state.upsellLists.find(
        (list) => list.id === state.selectedUpsellList
      );
    },
    currentCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;

      const listData = getters.selectedUpsellListData;

      console.log(listData.type);

      if (listData.type === "cart") {
        return companies.filter((company) => {
          return company.upsell === state.selectedUpsellList;
        });
      } else if (listData.type === "home") {
        return companies.filter((company) => {
          if (company.homeUpsell === undefined) {
            console.log(company);
            return false;
          }
          return company.homeUpsell.includes(state.selectedUpsellList);
        });
      }

      return [];
    },
    productsWithData: (state, getters, rootState, rootGetters) => {
      let products = [];
      state.products.forEach((productId) => {
        const product =
          rootGetters["products/getProductByFirebaseId"](productId);
        if (product !== undefined) {
          products.push(product);
        }
      });

      return products;
    },
    getUpsellListNameById: (state) => (id) => {
      let list = state.upsellLists.find((list) => list.id === id);

      return list === undefined ? "No list" : list.name;
    },
    getUpsellListTypeById: (state) => (id) => {
      let list = state.upsellLists.find((list) => list.id === id);

      return list === undefined ? "No type" : list.type;
    },
    selectedCompaniesData: (state, getters, rootState) => {
      let companies = rootState.companies.companies;

      return companies
        .filter((company) => state.selectedCompanies.includes(company.id))
        .map((company) => {
          let listName = getters.getUpsellListNameById(company.upsell);
          return {
            list: listName,
            name: company.name,
            id: company.id,
          };
        });
    },
    currentlySelectedCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;

      companies = companies
        .filter((company) => {
          if (
            state.selectedCompanyCategories.length > 0 &&
            state.selectedCompanyCategories.indexOf(
              company.companyCategory.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups1.length > 0 &&
            state.selectedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups2.length > 0 &&
            state.selectedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedCompanies.length > 0 &&
            state.selectedCompanies.indexOf(company.id) !== -1
          ) {
            return true;
          }
          return false;
        })
        .filter((company) => {
          if (
            state.excludedCompanies.length > 0 &&
            state.excludedCompanies.indexOf(company.vismaId) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups1.length > 0 &&
            state.excludedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups2.length > 0 &&
            state.excludedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedCompanies.length > 0 &&
            state.excludedCompanies.indexOf(company.id) !== -1
          ) {
            return false;
          }
          return true;
        });

      return companies.map((company) => {
        return company.id;
      });
    },
    currentlySelectedCompaniesData: (state, getters, rootState) => {
      let companies = rootState.companies.companies;

      return companies
        .filter((company) =>
          getters.currentlySelectedCompanies.includes(company.id)
        )
        .map((company) => {
          let listName = getters.getUpsellListNameById(company.upsell);
          return {
            list: listName,
            name: company.name,
            id: company.id,
          };
        });
    },
  },
  actions: {
    bindUpsellLists: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "upsellLists",
        db.collection("upsellLists").orderBy("name", "asc")
      );
    }),
    unbindUpsellLists: firestoreAction((context) => {
      return context.unbindFirestoreRef("upsellLists");
    }),
    createUpsellList: firestoreAction((context) => {
      return db
        .collection("upsellLists")
        .add({
          name: "New list",
          products: {},
          author: {
            email: context.rootState.settings.user.email,
            firebaseId: context.rootState.settings.user.uid,
            vismaId: context.rootState.settings.user.vismaId,
          },
          type: "cart",
          selectedPriceGroups1: [],
          selectedPriceGroups2: [],
        })
        .then((doc) => {
          context.commit("setSelectedUpsellList", doc.id);
          return context.dispatch("setupEditing").then(() => {
            return;
          });
        });
    }),
    deleteUpsellList: firestoreAction((context) => {
      const deleteId = context.state.selectedUpsellList;

      return db
        .collection("upsellLists")
        .doc(deleteId)
        .delete()
        .then(() => {
          return context.dispatch("exitEditing").then(() => {
            return;
          });
        });
    }),
    deleteHomeList: firestoreAction((context, payload) => {
      const companyId = payload.companyId;
      const listId = payload.listId;

      return db
        .collection("companies")
        .doc(companyId)
        .update({
          homeUpsell: firebase.firestore.FieldValue.arrayRemove(listId),
        })
        .then(() => {
          console.log("removed");
          return;
        });
    }),
    saveList: firestoreAction((context) => {
      let products = {};

      context.state.products.forEach((productId, index) => {
        products[productId] = index;
      });

      // If type changes to home
      if (
        context.state.editingType === "home" &&
        context.getters.selectedUpsellListData.type === "cart"
      ) {
        db.collection("app")
          .doc("appHelpers")
          .update({
            ["homeUpsellOrder." + context.state.selectedUpsellList]: 9999,
          });
      } else if (
        context.state.editingType === "cart" &&
        context.getters.selectedUpsellListData.type === "home"
      ) {
        db.collection("app")
          .doc("appHelpers")
          .update({
            ["homeUpsellOrder." + context.state.selectedUpsellList]:
              firebase.firestore.FieldValue.delete(),
          });
      }

      let publicList = false;
      if (
        context.state.editingType === "home" &&
        context.state.listPublic === true
      ) {
        publicList = true;
      }

      return db
        .collection("upsellLists")
        .doc(context.state.selectedUpsellList)
        .update({
          name: context.state.editingName,
          type: context.state.editingType,
          products: products,
          selectedPriceGroups1: context.state.editingPriceGroups1,
          selectedPriceGroups2: context.state.editingPriceGroups2,
          public: publicList,
        })
        .then(() => {
          return context.dispatch("exitEditing").then(() => {
            return;
          });
        });
    }),
    saveListsToCompanies: firestoreAction(async (context) => {
      const listId = context.state.selectedUpsellList;
      const companies = context.getters.currentlySelectedCompanies;
      const listType = context.getters.getUpsellListTypeById(listId);

      context.commit("setLoading", true);

      if (listType === "cart") {
        await Promise.all(
          companies.map((company) => {
            return db.collection("companies").doc(company).update({
              upsell: listId,
            });
          })
        );
      } else if (listType === "home") {
        await Promise.all(
          companies.map((company) => {
            return db
              .collection("companies")
              .doc(company)
              .update({
                homeUpsell: firebase.firestore.FieldValue.arrayUnion(listId),
              });
          })
        );
      }

      context.dispatch("clearSelections");
      context.commit("setSetListsConfirm", false);
      context.commit("setSelectedUpsellList", null);
      context.commit("setLoading", false);
    }),
    setupEditing: (context) => {
      context.commit("setCurrentStep", 0);
      context.commit(
        "setEditingName",
        context.getters.selectedUpsellListData.name
      );

      context.commit(
        "setEditingType",
        context.getters.selectedUpsellListData.type
      );

      context.commit(
        "setEditingPriceGroups1",
        context.getters.selectedUpsellListData.selectedPriceGroups1
      );

      context.commit(
        "setEditingPriceGroups2",
        context.getters.selectedUpsellListData.selectedPriceGroups2
      );

      context.commit(
        "setProducts",
        Object.entries(context.getters.selectedUpsellListData.products)
          .sort(([, a], [, b]) => a - b)
          .map((product) => product[0])
      );

      context.commit(
        "setListPublic",
        context.getters.selectedUpsellListData.public
      );
    },
    exitEditing: (context) => {
      context.commit("setSelectedUpsellList", null);
      context.commit("setEditingName", null);
      context.commit("setEditingType", "cart");
      context.commit("setProducts", {});
      context.commit("setCurrentStep", 0);
      context.commit("setListPublic", false);
    },
    updateEditingSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["editing" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setEditing" + field, newSelection);
      console.log("here");
    },
    updateSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["selected" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setSelected" + field, newSelection);

      console.log(context.state["selected" + field]);
    },
    updateExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field, newSelection);
    },
    clearSelections: (context) => {
      context.commit("setSelectedCompanyCategories", []);
      context.commit("setSelectedPriceGroups1", []);
      context.commit("setSelectedPriceGroups2", []);
      context.commit("setExcludedPriceGroups1", []);
      context.commit("setExcludedPriceGroups2", []);
      context.commit("setSelectedCompanies", []);
      context.commit("setExcludedCompanies", []);
    },
  },
};
export default cartUpsell;
