var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("1. Background image")]),_c('input',{attrs:{"type":"text","placeholder":"Media url"},domProps:{"value":_vm.selectedBlock.box1.media},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'box1.media',
            type: 'trim',
            value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("1. Background color - hex")]),_c('input',{attrs:{"type":"text","placeholder":"Background color - hex"},domProps:{"value":_vm.selectedBlock.box1.bgColor},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'box1.bgColor',
            type: 'trim',
            value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("1. Layout style")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"1-layout-split","value":"split"},domProps:{"checked":_vm.selectedBlock.box1.layout === 'split'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.layout',
              type: 'text',
              value: 'split',
            })}}}),_c('label',{attrs:{"for":"1-layout-split"}},[_vm._v("Split")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"1-layout-overlay","value":"overlay"},domProps:{"checked":_vm.selectedBlock.box1.layout === 'overlay'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.layout',
              type: 'text',
              value: 'overlay',
            })}}}),_c('label',{attrs:{"for":"1-layout-overlay"}},[_vm._v("Overlay")])])]),_c('div',{staticClass:"web-pages-form-divider"}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("2. Background image")]),_c('input',{attrs:{"type":"text","placeholder":"Media url"},domProps:{"value":_vm.selectedBlock.box2.media},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'box2.media',
            type: 'trim',
            value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("2. Background color - hex")]),_c('input',{attrs:{"type":"text","placeholder":"Background color - hex"},domProps:{"value":_vm.selectedBlock.box2.bgColor},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'box2.bgColor',
            type: 'trim',
            value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("2. Layout style")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"2-layout-split","value":"split"},domProps:{"checked":_vm.selectedBlock.box2.layout === 'split'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.layout',
              type: 'text',
              value: 'split',
            })}}}),_c('label',{attrs:{"for":"2-layout-split"}},[_vm._v("Split")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"2-layout-overlay","value":"overlay"},domProps:{"checked":_vm.selectedBlock.box2.layout === 'overlay'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.layout',
              type: 'text',
              value: 'overlay',
            })}}}),_c('label',{attrs:{"for":"2-layout-overlay"}},[_vm._v("Overlay")])])])]),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,index){return _c('div',{key:'lang-' + index,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("1. Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedBlock.box1.title[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.title.' + lang.code,
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("1. Text")]),_c('textarea',{attrs:{"placeholder":"Text"},domProps:{"value":_vm.selectedBlock.box1.text[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.text.' + lang.code,
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("1. Button text")]),_c('input',{attrs:{"type":"text","placeholder":"Button text"},domProps:{"value":_vm.selectedBlock.box1.button[lang.code].text},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.button.' + lang.code + '.text',
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("1. Button url - https if external, /xxx if internal")]),_c('input',{attrs:{"type":"text","placeholder":"Button url"},domProps:{"value":_vm.selectedBlock.box1.button[lang.code].url},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.button.' + lang.code + '.url',
              type: 'trim',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("1. Button style")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'primary-' + lang.code,"value":"primary"},domProps:{"checked":_vm.selectedBlock.box1.button[lang.code].style === 'primary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'box1.button.' + lang.code + '.style',
                type: 'text',
                value: 'primary',
              })}}}),_c('label',{attrs:{"for":'primary-' + lang.code}},[_vm._v("Primary")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'secondary-' + lang.code,"value":"secondary"},domProps:{"checked":_vm.selectedBlock.box1.button[lang.code].style === 'secondary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'box1.button.' + lang.code + '.style',
                type: 'text',
                value: 'secondary',
              })}}}),_c('label',{attrs:{"for":'secondary-' + lang.code}},[_vm._v("Secondary")])])]),_c('div',{staticClass:"form-input check"},[_c('label',[_vm._v("1. Open in new tab")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedBlock.box1.button[lang.code].target === '_blank'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box1.button.' + lang.code + '.target',
              type: 'text',
              value: $event.target.checked ? '_blank' : '',
            })}}})]),_c('div',{staticClass:"web-pages-form-divider"}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("2. Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedBlock.box2.title[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.title.' + lang.code,
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("2. Text")]),_c('textarea',{attrs:{"placeholder":"Text"},domProps:{"value":_vm.selectedBlock.box2.text[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.text.' + lang.code,
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("2. Button text")]),_c('input',{attrs:{"type":"text","placeholder":"Button text"},domProps:{"value":_vm.selectedBlock.box2.button[lang.code].text},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.button.' + lang.code + '.text',
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("2. Button url - https if external, /xxx if internal")]),_c('input',{attrs:{"type":"text","placeholder":"Button url"},domProps:{"value":_vm.selectedBlock.box2.button[lang.code].url},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.button.' + lang.code + '.url',
              type: 'trim',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("2. Button style")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'primary-' + lang.code,"value":"primary"},domProps:{"checked":_vm.selectedBlock.box2.button[lang.code].style === 'primary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'box2.button.' + lang.code + '.style',
                type: 'text',
                value: 'primary',
              })}}}),_c('label',{attrs:{"for":'primary-' + lang.code}},[_vm._v("Primary")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'secondary-' + lang.code,"value":"secondary"},domProps:{"checked":_vm.selectedBlock.box2.button[lang.code].style === 'secondary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'box2.button.' + lang.code + '.style',
                type: 'text',
                value: 'secondary',
              })}}}),_c('label',{attrs:{"for":'secondary-' + lang.code}},[_vm._v("Secondary")])])]),_c('div',{staticClass:"form-input check"},[_c('label',[_vm._v("2. Open in new tab")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedBlock.box2.button[lang.code].target === '_blank'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'box2.button.' + lang.code + '.target',
              type: 'text',
              value: $event.target.checked ? '_blank' : '',
            })}}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }