<template>
  <div class="upsell-set-lists-confirm">
    <div
      class="upsell-set-lists-confirm-container"
      :class="{ loading: loading }"
    >
      <h3 v-if="getUpsellListTypeById(selectedUpsellList) === 'cart'">
        Changing to cart list:
        <span>{{ getUpsellListNameById(selectedUpsellList) }}</span>
      </h3>
      <h3 v-else>Adding home list to:</h3>

      <div class="selected-companies">
        <div
          v-for="company in currentlySelectedCompaniesData"
          :key="'selected-' + company.id"
        >
          <div class="close" @click="removeCompany(company.id)">X</div>
          {{ company.name
          }}<span v-if="getUpsellListTypeById(selectedUpsellList) === 'cart'">{{
            company.list
          }}</span>
        </div>
      </div>

      <div class="upsell-set-lists-buttons">
        <button @click="cancel">Cancel</button>
        <button @click="confirm">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "UpsellSetListsConfirm",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
      selectedUpsellList: (state) => state.cartUpsell.selectedUpsellList,
      selectedCompanies: (state) => state.cartUpsell.selectedCompanies,
    }),
    ...mapGetters("cartUpsell", [
      "selectedCompaniesData",
      "getUpsellListNameById",
      "currentlySelectedCompaniesData",
      "getUpsellListTypeById",
    ]),
  },
  components: {},
  methods: {
    removeCompany: function (id) {
      this.$store.dispatch("cartUpsell/updateExclusion", {
        type: "add",
        field: "Companies",
        value: id,
      });
    },
    cancel: function () {
      this.$store.commit("cartUpsell/setSelectedUpsellList", null);
      this.$store.commit("cartUpsell/setSetListsConfirm", false);
    },
    confirm: function () {
      if (this.loading) {
        return;
      }
      this.$store.dispatch("cartUpsell/saveListsToCompanies");
    },
  },
};
</script>
