import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const languages = {
  namespaced: true,
  state: {
    translationData: null,
    selectedLanguage: null,
    selectedLanguageDoc: null,
    fieldsDoc: null,
    selectedGroup: "general",
    editingStrings: null,
    loading: false,
    addNew: false,
    unsavedChanges: false,
    editingPublished: null,
  },
  mutations: {
    setSelectedLanguage: (state, payload) => {
      state.selectedLanguage = payload;
    },
    setSelectedGroup: (state, payload) => {
      state.selectedGroup = payload;
    },
    setEditingStrings: (state, payload) => {
      state.editingStrings = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setAddNew: (state, payload) => {
      state.addNew = payload;
    },
    setUnsavedChanges: (state, payload) => {
      state.unsavedChanges = payload;
    },
    setEditingPublished: (state, payload) => {
      state.editingPublished = payload;
    },
  },
  getters: {
    allLanguages: (state) => {
      let returnArr = [];
      for (var lang in state.translationData.languages) {
        returnArr.push({
          code: lang,
          name: state.translationData.languages[lang],
        });
      }
      return returnArr.sort((a, b) => a.name.localeCompare(b.name));
    },
    disabledLanguages: (state, getters) => {
      return getters.allLanguages.filter(
        (lang) => state.translationData.activeLanguages[lang.code] === undefined
      );
    },
    enabledLanguages: (state, getters) => {
      return getters.allLanguages.filter(
        (lang) => state.translationData.activeLanguages[lang.code] !== undefined
      );
    },
    selectedLanguageStrings: (state) => {
      if (state.selectedLanguageDoc !== null) {
        return state.selectedLanguageDoc.strings;
      } else {
        return null;
      }
    },
    selectedLanguagePublished: (state) => {
      if (state.selectedLanguageDoc !== null) {
        return state.selectedLanguageDoc.published;
      } else {
        return false;
      }
    },
    strings: (state) => {
      if (state.fieldsDoc !== null) {
        return state.fieldsDoc.strings;
      } else {
        return null;
      }
    },
    emptyStrings: (state, getters) => {
      let returnObj = {};

      Object.keys(getters.strings).forEach((groupKey) => {
        returnObj[groupKey] = {};

        Object.keys(getters.strings[groupKey]).forEach((fieldKey) => {
          returnObj[groupKey][fieldKey] = "";
        });
      });

      return returnObj;
    },
    stringGroups: (state, getters) => {
      let returnArr = Object.keys(getters.strings)
        .filter((group) => group !== "general")
        .sort((a, b) => a.localeCompare(b));

      returnArr.unshift("general");
      return returnArr;
    },
  },
  actions: {
    bindFieldsDoc: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "fieldsDoc",
        db
          .collection("app")
          .doc("translations")
          .collection("languages")
          .doc("fields")
      );
    }),
    bindTranslationData: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "translationData",
        db.collection("app").doc("translations")
      );
    }),
    bindLanguage: firestoreAction((context, payload) => {
      context.commit("setSelectedLanguage", payload);

      return context.bindFirestoreRef(
        "selectedLanguageDoc",
        db
          .collection("app")
          .doc("translations")
          .collection("languages")
          .doc(payload)
      );
    }),
    deleteLanguage: firestoreAction((context) => {
      if (
        context.state.selectedLanguage !== null &&
        context.state.selectedLanguage !== "en"
      ) {
        let p1 = new Promise((resolve) => {
          db.collection("app")
            .doc("translations")
            .update({
              ["activeLanguages." + context.state.selectedLanguage]:
                firebase.firestore.FieldValue.delete(),
            })
            .then(() => {
              resolve();
            });
        });

        let p2 = new Promise((resolve) => {
          db.collection("app")
            .doc("translations")
            .collection("languages")
            .doc(context.state.selectedLanguage)
            .delete()
            .then(() => {
              resolve();
            });
        });

        return Promise.all([p1, p2]);
      }
    }),
    addLanguage: firestoreAction((context, payload) => {
      let p1 = new Promise((resolve) => {
        db.collection("app")
          .doc("translations")
          .update({
            ["activeLanguages." + payload.code]: payload.name,
          })
          .then(() => {
            resolve();
          });
      });

      let p2 = new Promise((resolve) => {
        db.collection("app")
          .doc("translations")
          .collection("languages")
          .doc(payload.code)
          .set({
            strings: context.getters.emptyStrings,
            published: false,
          })
          .then(() => {
            resolve();
          });
      });

      return Promise.all([p1, p2]);
    }),
    setupLanguage: firestoreAction((context, payload) => {
      context.dispatch("bindLanguage", payload).then(() => {
        let arrayStrings = {};
        // Transform to array so we can sort fields alphabetically
        Object.keys(context.getters.selectedLanguageStrings).forEach(
          (groupKey) => {
            let groupArr = [];
            for (const [fieldKey, fieldValue] of Object.entries(
              context.getters.selectedLanguageStrings[groupKey]
            )) {
              groupArr.push({ key: fieldKey, value: fieldValue });
            }

            arrayStrings[groupKey] = groupArr.sort((a, b) =>
              a.key.localeCompare(b.key)
            );
          }
        );
        context.commit("setUnsavedChanges", false);
        context.commit("setEditingStrings", arrayStrings);
        context.commit(
          "setEditingPublished",
          context.getters.selectedLanguagePublished
        );
      });
    }),
    saveChanges: firestoreAction((context) => {
      let formattedStrings = {};

      // Transfrom back from array to object.
      Object.keys(context.state.editingStrings).forEach((groupKey) => {
        formattedStrings[groupKey] = {};

        context.state.editingStrings[groupKey].forEach((field) => {
          formattedStrings[groupKey][field.key] = field.value;
        });
      });

      return db
        .collection("app")
        .doc("translations")
        .collection("languages")
        .doc(context.state.selectedLanguage)
        .set(
          {
            strings: formattedStrings,
            published: context.state.editingPublished,
          },
          { merge: true }
        )
        .then(() => {
          context.commit("setUnsavedChanges", false);
          return;
        });
    }),
  },
};
export default languages;
