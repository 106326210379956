var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upsell-home-segments"},[_c('h4',[_vm._v("Selections below only activates on new companies")]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup1Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups1),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(_vm.editingPriceGroups1.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('cartUpsell/updateEditingSelection', {
                  type: 'add',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Add")]):_vm._e(),(_vm.editingPriceGroups1.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('cartUpsell/updateEditingSelection', {
                  type: 'remove',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Remove")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup2Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups2),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(_vm.editingPriceGroups2.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('cartUpsell/updateEditingSelection', {
                  type: 'add',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v("Add")]):_vm._e(),(_vm.editingPriceGroups2.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('cartUpsell/updateEditingSelection', {
                  type: 'remove',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v("Remove")]):_vm._e()])]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }