<template>
  <div class="upsell-home-segments">
    <h4>Selections below only activates on new companies</h4>
    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup1Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups1"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="editingPriceGroups1.indexOf(key) === -1"
                @click="
                  $store.dispatch('cartUpsell/updateEditingSelection', {
                    type: 'add',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action add"
                >Add</span
              >
              <span
                v-if="editingPriceGroups1.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateEditingSelection', {
                    type: 'remove',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action remove"
                >Remove</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup2Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups2"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="editingPriceGroups2.indexOf(key) === -1"
                @click="
                  $store.dispatch('cartUpsell/updateEditingSelection', {
                    type: 'add',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action add"
                >Add</span
              >
              <span
                v-if="editingPriceGroups2.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateEditingSelection', {
                    type: 'remove',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action remove"
                >Remove</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "UpsellHomeSegmens",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      editingPriceGroups1: (state) => state.cartUpsell.editingPriceGroups1,
      editingPriceGroups2: (state) => state.cartUpsell.editingPriceGroups2,
    }),
    ...mapGetters("cartUpsell", ["currentCompanies"]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("settings", ["priceGroup1Name", "priceGroup2Name"]),
  },
  components: {},
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
  },
};
</script>
