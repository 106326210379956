<template>
  <div class="admin-table">
    <div class="table-header">
      <span class="category label">Final users - {{ finalUsers.length }}</span>
    </div>
    <div class="inner">
      <div v-for="(value, key) in finalUsers" :key="'cat-' + key">
        <span
          >{{ getUserNameById(value) }}<br />
          <span class="company">{{ getCompanyNameByUserId(value) }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PostsSelectionFinalUsers",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("users", ["getUserNameById", "getCompanyNameByUserId"]),
    ...mapGetters("notifications", ["finalUsers"]),
  },
};
</script>
