<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>1. Background image</label>
        <input
          type="text"
          placeholder="Media url"
          :value="selectedBlock.box1.media"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'box1.media',
              type: 'trim',
              value: $event.target.value,
            })
          "
        />
      </div>
      <div class="form-input">
        <label>1. Background color - hex</label>
        <input
          type="text"
          :value="selectedBlock.box1.bgColor"
          placeholder="Background color - hex"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'box1.bgColor',
              type: 'trim',
              value: $event.target.value,
            })
          "
        />
      </div>
      <div class="form-input radio">
        <label>1. Layout style</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="1-layout-split"
            value="split"
            :checked="selectedBlock.box1.layout === 'split'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.layout',
                type: 'text',
                value: 'split',
              })
            "
          />

          <label for="1-layout-split">Split</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="1-layout-overlay"
            value="overlay"
            :checked="selectedBlock.box1.layout === 'overlay'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.layout',
                type: 'text',
                value: 'overlay',
              })
            "
          />

          <label for="1-layout-overlay">Overlay</label>
        </div>
      </div>

      <div class="web-pages-form-divider"></div>

      <div class="form-input">
        <label>2. Background image</label>
        <input
          type="text"
          placeholder="Media url"
          :value="selectedBlock.box2.media"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'box2.media',
              type: 'trim',
              value: $event.target.value,
            })
          "
        />
      </div>
      <div class="form-input">
        <label>2. Background color - hex</label>
        <input
          type="text"
          :value="selectedBlock.box2.bgColor"
          placeholder="Background color - hex"
          @input="
            $store.dispatch('webPages/handleBlockInput', {
              key: 'box2.bgColor',
              type: 'trim',
              value: $event.target.value,
            })
          "
        />
      </div>
      <div class="form-input radio">
        <label>2. Layout style</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="2-layout-split"
            value="split"
            :checked="selectedBlock.box2.layout === 'split'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.layout',
                type: 'text',
                value: 'split',
              })
            "
          />

          <label for="2-layout-split">Split</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="2-layout-overlay"
            value="overlay"
            :checked="selectedBlock.box2.layout === 'overlay'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.layout',
                type: 'text',
                value: 'overlay',
              })
            "
          />

          <label for="2-layout-overlay">Overlay</label>
        </div>
      </div>
    </div>
    <div class="web-pages-lang-fields">
      <div
        v-for="(lang, index) in enabledLanguages"
        :key="'lang-' + index"
        class="web-pages-lang-group"
      >
        <img :src="require('../../assets/flags/' + lang.code + '.png')" />
        <div class="form-input">
          <label>1. Title</label>
          <input
            type="text"
            placeholder="Title"
            :value="selectedBlock.box1.title[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.title.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input">
          <label>1. Text</label>
          <textarea
            placeholder="Text"
            :value="selectedBlock.box1.text[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.text.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          ></textarea>
        </div>
        <div class="form-input">
          <label>1. Button text</label>
          <input
            type="text"
            placeholder="Button text"
            :value="selectedBlock.box1.button[lang.code].text"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.button.' + lang.code + '.text',
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>1. Button url - https if external, /xxx if internal</label>
          <input
            type="text"
            placeholder="Button url"
            :value="selectedBlock.box1.button[lang.code].url"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.button.' + lang.code + '.url',
                type: 'trim',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>1. Button style</label>
          <div class="form-input-radio">
            <input
              type="radio"
              :id="'primary-' + lang.code"
              value="primary"
              :checked="
                selectedBlock.box1.button[lang.code].style === 'primary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'box1.button.' + lang.code + '.style',
                  type: 'text',
                  value: 'primary',
                })
              "
            />

            <label :for="'primary-' + lang.code">Primary</label>
          </div>

          <div class="form-input-radio">
            <input
              type="radio"
              :id="'secondary-' + lang.code"
              value="secondary"
              :checked="
                selectedBlock.box1.button[lang.code].style === 'secondary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'box1.button.' + lang.code + '.style',
                  type: 'text',
                  value: 'secondary',
                })
              "
            />
            <label :for="'secondary-' + lang.code">Secondary</label>
          </div>
        </div>
        <div class="form-input check">
          <label>1. Open in new tab</label>
          <input
            type="checkbox"
            :checked="selectedBlock.box1.button[lang.code].target === '_blank'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box1.button.' + lang.code + '.target',
                type: 'text',
                value: $event.target.checked ? '_blank' : '',
              })
            "
          />
        </div>
        <div class="web-pages-form-divider"></div>

        <div class="form-input">
          <label>2. Title</label>
          <input
            type="text"
            placeholder="Title"
            :value="selectedBlock.box2.title[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.title.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input">
          <label>2. Text</label>
          <textarea
            placeholder="Text"
            :value="selectedBlock.box2.text[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.text.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          ></textarea>
        </div>
        <div class="form-input">
          <label>2. Button text</label>
          <input
            type="text"
            placeholder="Button text"
            :value="selectedBlock.box2.button[lang.code].text"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.button.' + lang.code + '.text',
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>2. Button url - https if external, /xxx if internal</label>
          <input
            type="text"
            placeholder="Button url"
            :value="selectedBlock.box2.button[lang.code].url"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.button.' + lang.code + '.url',
                type: 'trim',
                value: $event.target.value,
              })
            "
          />
        </div>
        <div class="form-input radio">
          <label>2. Button style</label>
          <div class="form-input-radio">
            <input
              type="radio"
              :id="'primary-' + lang.code"
              value="primary"
              :checked="
                selectedBlock.box2.button[lang.code].style === 'primary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'box2.button.' + lang.code + '.style',
                  type: 'text',
                  value: 'primary',
                })
              "
            />

            <label :for="'primary-' + lang.code">Primary</label>
          </div>

          <div class="form-input-radio">
            <input
              type="radio"
              :id="'secondary-' + lang.code"
              value="secondary"
              :checked="
                selectedBlock.box2.button[lang.code].style === 'secondary'
              "
              @change="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'box2.button.' + lang.code + '.style',
                  type: 'text',
                  value: 'secondary',
                })
              "
            />
            <label :for="'secondary-' + lang.code">Secondary</label>
          </div>
        </div>
        <div class="form-input check">
          <label>2. Open in new tab</label>
          <input
            type="checkbox"
            :checked="selectedBlock.box2.button[lang.code].target === '_blank'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'box2.button.' + lang.code + '.target',
                type: 'text',
                value: $event.target.checked ? '_blank' : '',
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesTwoContentBoxesForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
