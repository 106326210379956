var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications-selected-items"},[(
      _vm.selectedCompanyCategories.length > 0 &&
      _vm.selectedCompanyCategories.length ===
        Object.keys(_vm.companyCategories).length
    )?_c('div',[_c('b',[_vm._v("Company categories: ")]),_c('br'),_c('span',[_vm._v(" All categories are selected. ")])]):(_vm.selectedCompanyCategories.length > 0)?_c('div',[_c('b',[_vm._v("Company categories: ")]),_c('br'),_vm._l((_vm.selectedCompanyCategories),function(item){return _c('span',{key:'selected-' + item},[_vm._v(" "+_vm._s(_vm.companyCategories[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'CompanyCategories',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.selectedPriceGroups1.length > 0)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.priceGroup1Name)+": ")]),_c('br'),_vm._l((_vm.selectedPriceGroups1),function(item){return _c('span',{key:'selected-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups1[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'PriceGroups1',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.selectedPriceGroups2.length > 0)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.priceGroup2Name)+" ")]),_c('br'),_vm._l((_vm.selectedPriceGroups2),function(item){return _c('span',{key:'selected-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups2[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'PriceGroups2',
            value: item,
          })}}},[_vm._v("close")]),_c('br')])})],2):_vm._e(),(_vm.selectedPriceGroups3.length > 0)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.priceGroup3Name)+": ")]),_c('br'),_vm._l((_vm.selectedPriceGroups3),function(item){return _c('span',{key:'selected-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups3[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'PriceGroups3',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.selectedRoutes.length > 0)?_c('div',[_c('b',[_vm._v("Routes: ")]),_c('br'),_vm._l((_vm.selectedRoutes),function(item){return _c('span',{key:'selected-route-' + item},[_vm._v(" "+_vm._s(_vm.routes[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'Routes',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.excludedPriceGroups1.length > 0)?_c('div',[_c('b',[_vm._v("Excluded "+_vm._s(_vm.priceGroup1Name)+": ")]),_c('br'),_vm._l((_vm.excludedPriceGroups1),function(item){return _c('span',{key:'excluded-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups1[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
            type: 'remove',
            field: 'PriceGroups1',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.excludedPriceGroups2.length > 0)?_c('div',[_c('b',[_vm._v("Excluded "+_vm._s(_vm.priceGroup2Name)+": ")]),_c('br'),_vm._l((_vm.excludedPriceGroups2),function(item){return _c('span',{key:'excluded-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups2[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
            type: 'remove',
            field: 'PriceGroups2',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.excludedPriceGroups3.length > 0)?_c('div',[_c('b',[_vm._v("Excluded "+_vm._s(_vm.priceGroup3Name)+": ")]),_c('br'),_vm._l((_vm.excludedPriceGroups3),function(item){return _c('span',{key:'excluded-' + item},[_vm._v(" "+_vm._s(_vm.priceGroups3[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
            type: 'remove',
            field: 'PriceGroups3',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.excludedRoutes.length > 0)?_c('div',[_c('b',[_vm._v("Excluded routes: ")]),_c('br'),_vm._l((_vm.excludedRoutes),function(item){return _c('span',{key:'excluded-route-' + item},[_vm._v(" "+_vm._s(_vm.routes[item])+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
            type: 'remove',
            field: 'Routes',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.selectedCompanies.length > 0)?_c('div',[_c('b',[_vm._v("Companies: ")]),_c('br'),_vm._l((_vm.selectedCompanies),function(item){return _c('span',{key:'selected-' + item},[(_vm.getCompanyByVismaId(item))?_c('div',[_vm._v(" "+_vm._s(_vm.getCompanyByVismaId(item).name)+" ")]):_c('div',[_vm._v("Deleted company: "+_vm._s(item))]),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'Companies',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.selectedSalesAdmins.length > 0)?_c('div',[_c('b',[_vm._v("Filter by sales admin: ")]),_c('br'),_vm._l((_vm.selectedSalesAdmins),function(item){return _c('span',{key:'selected-' + item},[_vm._v(" "+_vm._s(_vm.getUserNameByVismaId(item))+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
            type: 'remove',
            field: 'SalesAdmins',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.excludedCompanies.length > 0)?_c('div',[_c('b',[_vm._v("Excluded companies: ")]),_c('br'),_vm._l((_vm.excludedCompanies),function(item){return _c('span',{key:'selected-' + item},[(_vm.getCompanyByVismaId(item) !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.getCompanyByVismaId(item).name)+" ")]):_c('div',[_vm._v("Deleted company: "+_vm._s(item))]),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
            type: 'remove',
            field: 'Companies',
            value: item,
          })}}},[_vm._v("close")])])})],2):_vm._e(),(_vm.finalCompanies.length === 0)?_c('div',{staticClass:"no-matches"},[_c('span',[_vm._v("No company matches selection")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }