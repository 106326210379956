<template>
  <div class="web-pages-editor-form">
    <div class="form-input">
      <label>ID</label>
      <input
        type="text"
        v-model="id"
        placeholder="ID"
        :disabled="editing !== 'create'"
      />
    </div>
    <div class="form-input">
      <label>Meta title</label>
      <input type="text" v-model="metaTitle" placeholder="Meta title" />
    </div>
    <div class="form-input">
      <label>Meta description</label>
      <input
        type="text"
        v-model="metaDescription"
        placeholder="Meta description"
      />
    </div>
    <div class="form-input">
      <label>Meta image</label>
      <input type="text" v-model="metaImage" placeholder="Meta image" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WebPagesList",
  computed: {
    ...mapState({
      editing: (state) => state.webPages.editing,
    }),
    id: {
      get() {
        return this.$store.state.webPages.editingId;
      },
      set(value) {
        this.$store.commit("webPages/setEditingId", value);
      },
    },
    metaTitle: {
      get() {
        return this.$store.state.webPages.editingMetaTitle;
      },
      set(value) {
        this.$store.commit("webPages/setEditingMetaTitle", value);
      },
    },
    metaDescription: {
      get() {
        return this.$store.state.webPages.editingMetaDescription;
      },
      set(value) {
        this.$store.commit("webPages/setEditingMetaDescription", value);
      },
    },
    metaImage: {
      get() {
        return this.$store.state.webPages.editingMetaImage;
      },
      set(value) {
        this.$store.commit("webPages/setEditingMetaImage", value);
      },
    },
  },
  methods: {},
};
</script>
