<template>
  <div class="block-selector">
    <div class="block-select-dropdown" @click="newBlockOpen = !newBlockOpen">
      <span class="selected">
        Add new block
        <span class="material-icons" :class="{ active: newBlockOpen }"
          >expand_more</span
        >
      </span>
      <div class="options" v-if="newBlockOpen">
        <span
          v-for="(type, index) in blockTypes"
          :key="'block-select-' + index"
          @click.stop="addBlock(type)"
          >{{ type }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "WebPagesBlockSelector",
  data() {
    return {
      newBlockOpen: false,
      blockTypes: [
        "hero",
        "upsell",
        "news",
        "content-box",
        "2-content-boxes",
        "coworkers",
      ],
    };
  },
  computed: {
    ...mapState({
      editingBlocks: (state) => state.webPages.editingBlocks,
    }),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {
    addBlock: function (blockType) {
      this.newBlockOpen = false;
      let blockData;

      console.log(this.enabledLanguages);

      switch (blockType) {
        case "hero": {
          const titleContent = {};
          const textContent = {};
          const buttonContent = {};
          this.enabledLanguages.forEach((lang) => {
            titleContent[lang.code] = "";
            textContent[lang.code] = "";
            buttonContent[lang.code] = {
              text: "",
              url: "",
              target: "",
              style: "primary",
            };
          });

          blockData = {
            type: "hero",
            bgMedia: "",
            height: "",
            title: titleContent,
            text: textContent,
            primaryButton: buttonContent,
            layout: "split",
          };
          break;
        }
        case "upsell":
          blockData = {
            type: "upsell",
            position: "",
          };
          break;
        case "news":
          blockData = {
            type: "news",
            limit: "",
          };
          break;
        case "content-box": {
          const titleContent = {};
          const textContent = {};
          const buttonContent = {};
          this.enabledLanguages.forEach((lang) => {
            titleContent[lang.code] = "";
            textContent[lang.code] = "";
            buttonContent[lang.code] = {
              text: "",
              url: "",
              target: "",
              style: "primary",
            };
          });

          blockData = {
            type: "content-box",
            box: {
              media: "",
              bgColor: "",
              title: titleContent,
              text: textContent,
              button: buttonContent,
              layout: "split",
            },
          };
          break;
        }
        case "2-content-boxes": {
          const titleContent1 = {};
          const textContent1 = {};
          const buttonContent1 = {};
          const titleContent2 = {};
          const textContent2 = {};
          const buttonContent2 = {};

          this.enabledLanguages.forEach((lang) => {
            titleContent1[lang.code] = "";
            textContent1[lang.code] = "";
            buttonContent1[lang.code] = {
              text: "",
              url: "",
              target: "",
              style: "primary",
            };

            titleContent2[lang.code] = "";
            textContent2[lang.code] = "";
            buttonContent2[lang.code] = {
              text: "",
              url: "",
              target: "",
              style: "primary",
            };
          });
          blockData = {
            type: "2-content-boxes",
            box1: {
              media: "",
              bgColor: "",
              title: titleContent1,
              text: textContent1,
              button: buttonContent1,
              layout: "split",
            },
            box2: {
              media: "",
              bgColor: "",
              title: titleContent2,
              text: textContent2,
              button: buttonContent2,
              layout: "split",
            },
          };
          break;
        }
        case "coworkers": {
          const titleContent = {};
          const workTitleContent = {};
          this.enabledLanguages.forEach((lang) => {
            titleContent[lang.code] = "";
          });
          blockData = {
            type: "coworkers",
            groups: [
              {
                title: titleContent,
                coworkers: [
                  {
                    name: "",
                    title: workTitleContent,
                    imageUrl: "",
                    email: "",
                    phone: "",
                  },
                ],
              },
            ],
          };
        }
      }

      this.$store.commit("webPages/addEditingBlock", blockData);
      this.$store.commit(
        "webPages/setSelectedBlockIndex",
        this.editingBlocks.length - 1
      );
    },
  },
};
</script>
