<template>
  <div class="language-selector-wrapper">
    <div class="language-selector">
      <div
        class="language-select-button"
        v-for="lang in enabledLanguages"
        :key="'button-' + lang.code"
        @click="changeLanguage(lang.code)"
        :class="{ active: selectedLanguage === lang.code }"
      >
        <img :src="require('../../assets/flags/' + lang.code + '.png')" />
        {{ lang.name }}
      </div>
    </div>
    <div
      class="language-add-button"
      @click="$store.commit('languages/setAddNew', true)"
    >
      <span class="material-icons">add_circle_outline</span>
      Add language
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LanguagesSelector",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedLanguage: (state) => state.languages.selectedLanguage,
      unsavedChanges: (state) => state.languages.unsavedChanges,
    }),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  methods: {
    changeLanguage: function (code) {
      let confirm = true;

      if (this.unsavedChanges === true) {
        confirm = window.confirm("You have unsaved changes, continue?");
      }

      if (confirm) {
        this.$store.dispatch("languages/setupLanguage", code);
      }
    },
  },
};
</script>
