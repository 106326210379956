<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div
      v-for="(group, groupIndex) in selectedBlock.groups"
      :key="'group-' + groupIndex"
    >
      <h2 class="web-pages-group-title" style="margin-bottom: 15px">
        Group {{ groupIndex + 1 }}
        <span
          class="material-icons delete-icon"
          @click="deleteCoworkerGroup(groupIndex)"
          >delete</span
        >
      </h2>

      <div class="web-pages-lang-fields">
        <div
          v-for="(lang, langIndex) in enabledLanguages"
          :key="'lang-' + groupIndex + '-' + langIndex"
          class="web-pages-lang-group"
        >
          <img :src="require('../../assets/flags/' + lang.code + '.png')" />
          <div class="form-input">
            <label>Title</label>
            <input
              type="text"
              placeholder="Title"
              :value="selectedBlock.groups[groupIndex].title[lang.code]"
              @input="
                $store.dispatch('webPages/handleBlockInput', {
                  key: 'groups.' + groupIndex + '.title.' + lang.code,
                  type: 'text',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </div>
      </div>

      <h2>Coworkers</h2>
      <div
        v-for="(coworker, coworkerIndex) in group.coworkers"
        :key="'coworker-' + groupIndex + '-' + coworkerIndex"
        class="single-coworker"
      >
        <div class="single-coworker-actions">
          <span
            class="material-icons delete-icon"
            @click="deleteCoworker(groupIndex, coworkerIndex)"
            >delete</span
          >
          <span
            class="material-icons move-icon"
            :class="{ disabled: coworkerIndex === 0 }"
            @click="
              $store.commit('webPages/moveCoworkerUp', {
                groupIndex,
                coworkerIndex,
              })
            "
            >arrow_upward</span
          >
          <span
            class="material-icons move-icon"
            :class="{ disabled: coworkerIndex === group.coworkers.length - 1 }"
            @click="
              $store.commit('webPages/moveCoworkerDown', {
                groupIndex,
                coworkerIndex,
              })
            "
            >arrow_downward</span
          >
        </div>

        <div class="web-pages-universal-fields">
          <div class="form-input">
            <label>Name</label>
            <input
              type="text"
              placeholder="Name"
              :value="
                selectedBlock.groups[groupIndex].coworkers[coworkerIndex].name
              "
              @input="
                $store.dispatch('webPages/handleBlockInput', {
                  key:
                    'groups.' +
                    groupIndex +
                    '.coworkers.' +
                    coworkerIndex +
                    '.name',
                  type: 'text',
                  value: $event.target.value,
                })
              "
            />
          </div>
          <div class="form-input">
            <label>Image url</label>
            <input
              type="text"
              placeholder="Image url"
              :value="
                selectedBlock.groups[groupIndex].coworkers[coworkerIndex]
                  .imageUrl
              "
              @input="
                $store.dispatch('webPages/handleBlockInput', {
                  key:
                    'groups.' +
                    groupIndex +
                    '.coworkers.' +
                    coworkerIndex +
                    '.imageUrl',
                  type: 'trim',
                  value: $event.target.value,
                })
              "
            />
          </div>
          <div class="form-input">
            <label>Email</label>
            <input
              type="text"
              placeholder="Email"
              :value="
                selectedBlock.groups[groupIndex].coworkers[coworkerIndex].email
              "
              @input="
                $store.dispatch('webPages/handleBlockInput', {
                  key:
                    'groups.' +
                    groupIndex +
                    '.coworkers.' +
                    coworkerIndex +
                    '.email',
                  type: 'trim',
                  value: $event.target.value,
                })
              "
            />
          </div>
          <div class="form-input">
            <label>Phone</label>
            <input
              type="text"
              placeholder="Phone"
              :value="
                selectedBlock.groups[groupIndex].coworkers[coworkerIndex].phone
              "
              @input="
                $store.dispatch('webPages/handleBlockInput', {
                  key:
                    'groups.' +
                    groupIndex +
                    '.coworkers.' +
                    coworkerIndex +
                    '.phone',
                  type: 'text',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </div>
        <br />
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + groupIndex + '-' + coworkerIndex + '-' + langIndex"
            class="web-pages-lang-group"
          >
            <img
              :src="require('../../assets/flags/' + lang.code + '.png')"
              style="width: 24px"
            />
            <div class="form-input">
              <label>Worktitle</label>
              <input
                type="text"
                placeholder="Worktitle"
                :value="
                  selectedBlock.groups[groupIndex].coworkers[coworkerIndex]
                    .title[lang.code]
                "
                @input="
                  $store.dispatch('webPages/handleBlockInput', {
                    key:
                      'groups.' +
                      groupIndex +
                      '.coworkers.' +
                      coworkerIndex +
                      '.title.' +
                      lang.code,
                    type: 'text',
                    value: $event.target.value,
                  })
                "
              />
            </div>
          </div>
        </div>
      </div>
      <button class="add-coworker-button" @click="addCoworker(groupIndex)">
        Add coworker
      </button>
    </div>
    <button class="add-coworker-group-button" @click="addCoworkerGroup">
      Add coworker group
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesCoworkersBlockForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {
    deleteCoworkerGroup: function (index) {
      const confirm = window.confirm(
        "Are you sure you want to delete this group?"
      );

      if (confirm) {
        console.log(index);
        this.$store.commit("webPages/removeCoworkerGroup", index);
      }
    },
    addCoworker: function (groupIndex) {
      const workTitleContent = {};
      this.enabledLanguages.forEach((lang) => {
        workTitleContent[lang.code] = "";
      });

      const userData = {
        name: "",
        imageUrl: "",
        email: "",
        phone: "",
        title: workTitleContent,
      };

      this.$store.commit("webPages/addCoworker", {
        groupIndex: groupIndex,
        userData: userData,
      });
    },
    addCoworkerGroup: function () {
      const titleContent = {};
      this.enabledLanguages.forEach((lang) => {
        titleContent[lang.code] = "";
      });

      this.$store.commit("webPages/addCoworkerGroup", {
        title: titleContent,
        coworkers: [],
      });
    },
    deleteCoworker: function (groupIndex, coworkerIndex) {
      const confirm = window.confirm(
        "Are you sure you want to delete this coworker?"
      );

      if (confirm) {
        this.$store.commit("webPages/removeCoworker", {
          groupIndex: groupIndex,
          coworkerIndex: coworkerIndex,
        });
      }
    },
  },
};
</script>
