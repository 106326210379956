import { db } from "./../main.js";
import { firestoreAction } from "vuexfire";

const users = {
  namespaced: true,
  state: {
    init: false,
    userList: [],
    singleUser: null,
    pushNotificationSettings: {},
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    setPushNotificationSettings: (state, payload) => {
      state.pushNotificationSettings = payload;
    },
  },
  getters: {
    getPushNotificationsSettings: (state) => (id) => {
      if (state.pushNotificationSettings[id] === undefined) {
        return { chat: false, deliveryInfo: false, newsAndOffers: false };
      }
      return state.pushNotificationSettings[id];
    },
    getUserBySearch: (state) => (searchString) => {
      if (!searchString) {
        state.userList.map((user) => {
          if (user == undefined) {
            console.log(user);
          }
        });
        return state.userList;
      }
      return state.userList.filter((user) =>
        user.email.toLowerCase().includes(searchString.toLowerCase())
      );
    },
    getUserEmailById: (state) => (id) => {
      var email = "";

      state.userList.forEach((user) => {
        if (user.id === id) {
          email = user.email;
        }
      });

      if (email) {
        return email;
      } else {
        return "User not found: " + id;
      }
    },
    getUserNameById: (state) => (id) => {
      var name = "Deleted user";

      state.userList.forEach((user) => {
        if (user.id === id) {
          name = user.name;
        }
      });
      return name;
    },
    getUserNameByVismaId: (state) => (id) => {
      let user = state.userList.find((user) => user.vismaId === parseInt(id));

      if (user) {
        return user.name;
      } else {
        return "No user matches: " + id;
      }
    },
    getUserCompanyById: (state) => (id) => {
      var company = "";

      state.userList.find((user) => {
        if (user.id === id) {
          company = user.companyId;
          return true;
        }
      });
      return company;
    },
    getUserLangById: (state) => (id) => {
      var lang = "";

      state.userList.find((user) => {
        if (user.id === id) {
          lang = user.lang;
          return true;
        }
      });
      return lang;
    },
    getUserById: (state) => (id) => {
      return state.userList.find((user) => user.uid === id);
    },
    getUserByVismaId: (state) => (id) => {
      return state.userList.find((user) => user.vismaId === id);
    },
    getCompanyNameByUserId:
      (state, getters, rootState, rootGetters) => (id) => {
        const companyId = getters.getUserById(id).companyId;
        return rootGetters["companies/getCompanyNameByVismaId"](companyId);
      },
    getAdminUsers: (state) => {
      return state.userList.filter(
        (user) => user.role === "admin" || user.role === "superAdmin"
      );
    },
    getAdminAccountOwners: (state, getters, rootState) => {
      return state.userList.filter((user) => {
        if (user.vismaId === undefined) {
          return false;
        }

        if (user.role === "admin" || user.role === "superAdmin") {
          if (
            rootState.companies.companies.find(
              (company) => company.accountOwnerVismaId === user.vismaId
            ) !== undefined
          ) {
            return true;
          }
        }
        return false;
      });
    },
    getAdminUsersListSelectOptions: (state, getters) => {
      let returnArr = [];

      for (const value of Object.values(getters.getAdminUsers)) {
        // console.log( key );
        returnArr.push({
          label: value.name,
          id: value.id,
        });
      }

      return returnArr;
    },
    getSignedInUsers: (state) => {
      return state.userList.filter((user) => user.firstSignIn !== undefined);
    },
    requestedRemovalUsers: (state, getters, rootState) => {
      return rootState.settings.generalHelpers.accountRemovalRequests;
    },
    usersAwaitingApproval: (state) => {
      return state.userList.filter(
        (company) => company.registerAccepted === false
      );
    },
  },
  actions: {
    bindUserList: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "userList",
        db.collection("users").orderBy("name")
      );
    }),
    readPushNotificationSettings: async (context) => {
      let pushNotificationSettings = {};

      await Promise.all(
        context.state.userList.map(async (user) => {
          const settings = await db
            .collection("users")
            .doc(user.uid)
            .collection("data")
            .doc("settings")
            .get();

          if (settings.data() === undefined) {
            return;
          }
          if (settings.data().pushNotifications === undefined) {
            return;
          }
          const pushNotifications = settings.data().pushNotifications;
          pushNotificationSettings[user.uid] = pushNotifications;
        })
      );

      context.commit("setPushNotificationSettings", pushNotificationSettings);
      return;
    },
    updateUserRole: firestoreAction((context, data) => {
      // return the promise so we can await the write
      return db.collection("users").doc(data.uid).set(
        {
          role: data.role,
        },
        { merge: true }
      );
    }),
    updateUserCompany: firestoreAction((context, data) => {
      return db.collection("users").doc(data.uid).set(
        {
          companyId: data.company,
        },
        { merge: true }
      );
    }),
    bindSingleUser: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        "singleUser",
        db.collection("users").doc(payload.id)
      );
    }),
    approveUser: firestoreAction((context, payload) => {
      return db.collection("users").doc(payload).update({
        registerAccepted: true,
      });
    }),
  },
};
export default users;
