<template>
  <div class="delivery-date-settings">
    <h3>Delivery Date Settings</h3>
    <h4>Standard delivery cut-off</h4>

    <div class="delivery-hours-minutes">
      <input type="text" placeholder="HH" v-model="hours" />
      :
      <input type="text" placeholder="mm" v-model="minutes" />
      <button
        v-if="
          hours !== deliveryStandardTime.hours ||
          minutes !== deliveryStandardTime.minutes
        "
        @click="updateTime"
      >
        Save time changes
      </button>
    </div>

    <div class="delivery-same-day">
      <div class="option" @click="deliverySameDay(true)">
        <span
          class="radio"
          :class="{ active: deliveryStandardTime.sameDay === true }"
        ></span
        >Same day
      </div>
      <div class="option" @click="deliverySameDay(false)">
        <span
          class="radio"
          :class="{ active: deliveryStandardTime.sameDay === false }"
        ></span
        >Day before
      </div>
    </div>
    <div class="delivery-weekends">
      <h4>Allow delivery on weekends</h4>
      <div class="delivery-check" @click="toggleDay(6)">
        <div
          class="check"
          :class="{ active: !deliveryBlockedDays.includes(6) }"
        ></div>
        <label>Saturdays</label>
      </div>
      <div class="delivery-check" @click="toggleDay(7)">
        <div
          class="check"
          :class="{ active: !deliveryBlockedDays.includes(7) }"
        ></div>
        <label>Sundays</label>
      </div>
    </div>

    <UnavailableDates v-if="userRole.slug == 'superAdmin'" />

    <DeliveryCutOff v-if="appBrand !== 'fsg' && appBrand !== 'fiskerikajen'" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "DeliveryDateSettings",
  data() {
    return {
      hours: "12",
      minutes: "00",
    };
  },
  created() {
    this.hours = this.deliveryStandardTime.hours;
    this.minutes = this.deliveryStandardTime.minutes;
  },
  methods: {
    toggleDay: function (dayIndex) {
      let days = this.deliveryBlockedDays;

      if (days.includes(dayIndex)) {
        days = days.filter((entry) => entry !== dayIndex);
      } else {
        days.push(dayIndex);
      }

      this.$store
        .dispatch("settings/updateDeliveryBlockedDays", days)
        .then(() => {
          console.log("doneee");
        });
    },
    updateTime: function () {
      if (
        isNaN(parseInt(this.hours)) ||
        isNaN(parseInt(this.minutes)) ||
        parseInt(this.hours) > 23 ||
        parseInt(this.hours) < 0 ||
        parseInt(this.minutes) > 59 ||
        parseInt(this.minutes) < 0
      ) {
        this.hours = this.deliveryStandardTime.hours;
        this.minutes = this.deliveryStandardTime.minutes;
        return;
      }

      this.$store
        .dispatch("settings/updateStandardDeliveryTime", {
          hours: this.hours,
          minutes: this.minutes,
        })
        .then(() => {
          console.log("done");
        });
    },
    deliverySameDay: function (value) {
      if (this.deliveryStandardTime.sameDay === value) {
        return;
      }

      this.$store
        .dispatch("settings/updateStandardDeliverySameDay", value)
        .then(() => {
          console.log("done");
        });
    },
  },
  computed: {
    ...mapState({
      appBrand: (state) => state.settings.appSettings.app,
    }),
    ...mapGetters("settings", [
      "deliveryBlockedDays",
      "deliveryStandardTime",
      "userRole",
    ]),
  },
};
</script>
