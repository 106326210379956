<template>
  <div class="chat-admins">
    <div
      class="chat-admin-selector"
      :class="{ active: selectedChatAdmin === 'all' }"
      @click="setChatAdmin('all')"
    >
      <span class="name">All chats</span>
      <span class="notification" v-if="totalNotificationCount > 0">{{
        totalNotificationCount
      }}</span>
    </div>

    <div
      v-for="admin in adminUsersWithChatRooms"
      :key="admin.id"
      class="chat-admin-selector"
      :class="{ active: selectedChatAdmin === admin.vismaId }"
      @click="setChatAdmin(admin.vismaId)"
    >
      <span class="name">{{ admin.name }}</span>
      <span
        class="notification"
        :class="{ user: admin.id === userId }"
        v-if="notificationCountByAdmin(admin.vismaId) > 0"
        >{{ notificationCountByAdmin(admin.vismaId) }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ChatAdminSelector",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      userId: (state) => state.settings.user.id,
      selectedChatAdmin: (state) => state.chat.selectedChatAdmin,
    }),
    ...mapGetters("chat", [
      "totalNotificationCount",
      "notificationCountByAdmin",
      "adminUsersWithChatRooms",
    ]),
  },
  methods: {
    setChatAdmin: function (selected) {
      if (this.selectedChatAdmin === selected) {
        return false;
      }

      this.$store.commit("chat/setSelectedChatRoom", false);
      this.ownerOfRoom = false;
      this.search = "";
      this.$store.commit("chat/setSelectedChatAdmin", selected);
    },
  },
};
</script>
