<template>
  <div id="home">
    <div class="analytics-header">
      <h1>Home</h1>

      <div class="shortlists-filter">
        <div class="shortlists-dropdown">
          <div
            class="selected"
            @click="dropdownOpen = !dropdownOpen"
            :class="{ open: dropdownOpen }"
          >
            <span v-if="accountOwner === 'all'">All</span>
            <span v-else>{{ getUserNameByVismaId(accountOwner) }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="options" v-if="dropdownOpen">
            <div
              class="option"
              @click="selectAccountOwner('all')"
              :class="{ active: 'all' === accountOwner }"
            >
              All
            </div>
            <div
              class="option"
              v-for="option in getAdminUsers"
              :key="'admin-' + option.id"
              @click="selectAccountOwner(option.vismaId)"
              :class="{ active: option.vismaId === accountOwner }"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="account-owner-selector">
        <div class="account-owner-all" @click="selectAccountOwner(userVismaId)">
          <div class="radio" :class="{ active: accountOwner != 'all' }"></div>
          My companies
        </div>
        <div class="account-owner-mine" @click="selectAccountOwner('all')">
          <div class="radio" :class="{ active: accountOwner == 'all' }"></div>
          All companies
        </div>
      </div> -->
    </div>
    <div class="analytics-wrapper">
      <div class="analytics-widget analytics-company-no-users">
        <h2>Companies without users - {{ companiesWithoutUsers.length }}</h2>
        <div class="analytics-scroll-container">
          <div
            v-for="company in companiesWithoutUsers"
            :key="'no-users-' + company.id"
            @click="navigateToCompany(company.vismaId)"
            class="analytics-companies-without-users-row"
          >
            {{ company.name }} -
            <span>{{ getUserNameByVismaId(company.accountOwnerVismaId) }}</span>
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-company-no-users">
        <h2>
          Companies with never signed in users -
          {{ companiesWithNeverSignedInUsers.length }}
        </h2>
        <div class="analytics-scroll-container">
          <div
            v-for="company in companiesWithNeverSignedInUsers"
            :key="'never-signed-in-' + company.id"
            @click="navigateToCompany(company.vismaId)"
          >
            {{ company.name }}
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-users-with-no-orders">
        <h2>
          Signed in users with no orders -
          {{ usersWithNoOrders.length }}
        </h2>
        <div class="analytics-scroll-container">
          <div
            v-for="user in signedInUsersWithoutOrders"
            :key="'user-no-orders-' + user.id"
            @click="navigateToUser(user.id)"
            class="analytics-signed-in-users-without-order-row"
          >
            {{ user.name }}
            <span>{{ getCompanyNameByVismaId(user.company) }}</span>
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="surveys-list" v-if="surveysInit && surveys.length > 0">
      <div class="survey" :key="surveys[0].id">
        <div class="survey-info">
          <div class="survey-question">{{ surveys[0].question }}</div>
          <div>Type: {{ surveys[0].type }}</div>
          <div v-if="surveys[0].ending.toDate() > now" class="survey-ending">
            <span style="color: var(--color-success)">Active until:</span>
            {{ surveys[0].ending.toDate() | moment("YYYY-MM-DD — HH:mm") }}
          </div>
          <div v-else>
            <span style="color: var(--color-danger)">Finished:</span>
            {{ surveys[0].ending.toDate() | moment("YYYY-MM-DD — HH:mm") }}
          </div>
        </div>
        <div class="survey-answers">
          <div class="survey-answers-header">
            Answers -
            {{ Object.keys(filteredAnswers(surveys[0].answers)).length }}
          </div>
          <div class="survey-number-stats" v-if="surveys[0].type === 'number'">
            <div>
              <span>Average:</span>
              {{ surveyAverage(filteredAnswers(surveys[0].answers)) }}
            </div>
            <div>
              <span>1:</span>
              {{ surveyAmountOf(filteredAnswers(surveys[0].answers), 1) }}
            </div>
            <div>
              <span>2:</span>
              {{ surveyAmountOf(filteredAnswers(surveys[0].answers), 2) }}
            </div>
            <div>
              <span>3:</span>
              {{ surveyAmountOf(filteredAnswers(surveys[0].answers), 3) }}
            </div>
            <div>
              <span>4:</span>
              {{ surveyAmountOf(filteredAnswers(surveys[0].answers), 4) }}
            </div>
            <div>
              <span>5:</span>
              {{ surveyAmountOf(filteredAnswers(surveys[0].answers), 5) }}
            </div>
          </div>

          <div class="survey-answers-inner">
            <div
              v-for="(answer, userId) in filteredAnswers(surveys[0].answers)"
              :key="surveys[0].id + userId"
              class="survey-answer"
            >
              <div class="survey-answer-user">
                <span class="name">{{ getUserNameById(userId) }}</span>
                <span class="company">{{
                  getCompanyNameByVismaId(getUserCompanyById(userId))
                }}</span>
              </div>
              <div
                class="answer"
                :class="{ number: surveys[0].type === 'number' }"
              >
                {{ answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminLog from "@/changelog/admin.json";
import appLog from "@/changelog/app.json";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      adminLog: adminLog,
      appLog: appLog,
      dropdownOpen: false,
      now: new Date(),
    };
  },
  computed: {
    ...mapState({
      accountOwner: (state) => state.analytics.accountOwner,
      userVismaId: (state) => state.settings.user.vismaId,
      usersWithNoOrders: (state) => state.analytics.usersWithNoOrders,
      surveysInit: (state) => state.surveys.init,
      surveys: (state) => state.surveys.surveys,
    }),
    ...mapGetters("analytics", [
      "companiesWithoutUsers",
      "companiesWithNeverSignedInUsers",
      "signedInUsersWithoutOrders",
    ]),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("users", [
      "getUserNameByVismaId",
      "getAdminUsers",
      "getUserNameById",
      "getUserCompanyById",
    ]),
  },
  components: {},
  methods: {
    async selectAccountOwner(owner) {
      this.$store.commit("analytics/setAccountOwner", owner);
      this.dropdownOpen = false;
    },
    navigateToCompany: function (id) {
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
    navigateToUser: function (id) {
      this.$router.push({ name: "SingleUser", params: { id } });
    },
    filteredAnswers: function (answers) {
      if (this.accountOwner === "all") {
        return answers;
      }

      const returnObj = {};

      Object.keys(answers).forEach((uid) => {
        const companyId = this.getUserCompanyById(uid);
        const accountOwner = this.getCompanyAccountOwnerByVismaId(companyId);

        if (accountOwner === this.accountOwner) {
          returnObj[uid] = answers[uid];
        }
      });

      return returnObj;
    },
    surveyAverage: function (answers) {
      let sum = 0;
      let count = 0;
      Object.keys(answers).forEach((uid) => {
        sum += answers[uid];
        count++;
      });

      if (count === 0) return parseFloat(0).toFixed(2);

      return parseFloat(sum / count).toFixed(2);
    },
    surveyAmountOf: function (answers, value) {
      let count = 0;
      Object.keys(answers).forEach((uid) => {
        if (answers[uid] === value) {
          count++;
        }
      });

      return count;
    },
  },
  async created() {
    if (this.usersWithNoOrders === false) {
      this.$store.commit("analytics/setUsersWithNoOrders", false);
    }
    if (this.$cookies.get("version") === "dev") {
      await new Promise((r) => setTimeout(r, 300));
      await this.$store.dispatch("surveys/bindSurveys");
      this.$store.commit("surveys/setInit", true);
    }
  },
  beforeDestroy() {
    if (this.$cookies.get("version") === "dev") {
      this.$store.dispatch("surveys/unbindSurveys");
      this.$store.commit("surveys/setInit", false);
    }
  },
};
</script>
