<template>
  <div class="no-credit-message">
    <h3>No Credit Message</h3>
    <div class="row align-start">
      <div class="column">
        <span class="subtitle">Message</span>
        <textarea name="no-credit-message" rows="5" v-model="newMessage" />
        <button class="ncm-btn" @click="updateNoCreditMessage">Save</button>
      </div>
      <div class="column content">
        <span class="subtitle">Current message</span>
        <p class="text">{{ creditMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";

export default {
  name: "NoCreditMessage",
  mounted() {},
  data() {
    return {
      newMessage: "",
    };
  },
  computed: {
    creditMessage: {
      get() {
        return this.$store.state.settings.appSettings.noCreditMessage;
      },
      set(value) {
        this.newMessage = value;
      },
    },
  },
  methods: {
    async updateNoCreditMessage() {
      this.$store.dispatch("settings/updateNoCreditMessage", this.newMessage);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-credit-message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 60px;
  max-width: 450px;

  textarea {
    width: 350px;
  }
}

.subtitle {
  font-weight: 500;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 700px;

  &.small-gap {
    gap: 5px;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-start {
    align-items: flex-start;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  white-space: break-spaces;

  &.content {
    .text {
      min-width: 400px;
      display: block;
    }
  }
}

.ncm-btn {
  width: 100px;
}
</style>
