var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications-group-select"},[_c('div',{staticClass:"admin-table company-categories"},[_vm._m(0),_c('div',{staticClass:"inner"},_vm._l((_vm.companyCategories),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(_vm.selectedCompanyCategories.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'add',
                field: 'CompanyCategories',
                value: key,
              })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'remove',
                field: 'CompanyCategories',
                value: key,
              })}}},[_vm._v(" Remove ")])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup1Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups1),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                _vm.excludedPriceGroups1.indexOf(key) === -1 &&
                _vm.selectedPriceGroups1.indexOf(key) === -1
              )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'add',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups1.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'remove',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                _vm.selectedPriceGroups1.indexOf(key) === -1 &&
                _vm.excludedPriceGroups1.indexOf(key) === -1
              )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Add")]):_vm._e(),(_vm.selectedPriceGroups1.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups1',
                  value: key,
                })}}},[_vm._v("Remove")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup2Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups2),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                _vm.excludedPriceGroups2.indexOf(key) === -1 &&
                _vm.selectedPriceGroups2.indexOf(key) === -1
              )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'add',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups2.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'remove',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                _vm.selectedPriceGroups2.indexOf(key) === -1 &&
                _vm.excludedPriceGroups2.indexOf(key) === -1
              )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedPriceGroups2.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups2',
                  value: key,
                })}}},[_vm._v(" Remove ")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup3Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups3),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                _vm.excludedPriceGroups3.indexOf(key) === -1 &&
                _vm.selectedPriceGroups3.indexOf(key) === -1
              )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'add',
                  field: 'PriceGroups3',
                  value: key,
                })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedPriceGroups3.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'remove',
                  field: 'PriceGroups3',
                  value: key,
                })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                _vm.selectedPriceGroups3.indexOf(key) === -1 &&
                _vm.excludedPriceGroups3.indexOf(key) === -1
              )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups3',
                  value: key,
                })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedPriceGroups3.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups3',
                  value: key,
                })}}},[_vm._v(" Remove ")]):_vm._e()])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_vm._m(1),_c('div',{staticClass:"inner"},_vm._l((_vm.routes),function(value,key){return _c('div',{key:'route-' + key},[_c('div',[_c('span',[_vm._v(_vm._s(value))]),_c('div',{staticClass:"actions"},[(
                _vm.excludedRoutes.indexOf(key) === -1 &&
                _vm.selectedRoutes.indexOf(key) === -1
              )?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'add',
                  field: 'Routes',
                  value: key,
                })}}},[_vm._v("Exclude")]):_vm._e(),(_vm.excludedRoutes.indexOf(key) !== -1)?_c('span',{staticClass:"action exclude",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateExclusion', {
                  type: 'remove',
                  field: 'Routes',
                  value: key,
                })}}},[_vm._v("Remove exclusion")]):_vm._e(),(
                _vm.selectedRoutes.indexOf(key) === -1 &&
                _vm.excludedRoutes.indexOf(key) === -1
              )?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'Routes',
                  value: key,
                })}}},[_vm._v(" Add ")]):_vm._e(),(_vm.selectedRoutes.indexOf(key) !== -1)?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'Routes',
                  value: key,
                })}}},[_vm._v(" Remove ")]):_vm._e()])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Companies")]),_c('div',{staticClass:"company-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companySearch),expression:"companySearch"}],attrs:{"type":"text","placeholder":"Search company..."},domProps:{"value":(_vm.companySearch)},on:{"input":function($event){if($event.target.composing)return;_vm.companySearch=$event.target.value}}})])]),_c('div',{staticClass:"inner"},_vm._l((_vm.getCompaniesBySearch(_vm.companySearch)),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value.name))]),_c('div',{staticClass:"actions"},[(_vm.selectedCompanies.indexOf(value.vismaId) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'add',
                field: 'Companies',
                value: value.vismaId,
              })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'remove',
                field: 'Companies',
                value: value.vismaId,
              })}}},[_vm._v(" Remove ")])])])}),0)]),_c('div',{staticClass:"admin-table company-categories"},[_vm._m(2),_c('div',{staticClass:"inner"},_vm._l((_vm.getAdminAccountOwners),function(value,index){return _c('div',{key:'sales-users-' + index},[_c('span',[_vm._v(_vm._s(value.name))]),_c('div',{staticClass:"actions"},[(_vm.selectedSalesAdmins.indexOf(value.vismaId) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'add',
                field: 'SalesAdmins',
                value: value.vismaId,
              })}}},[_vm._v(" Add filter ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('notifications/updateSelection', {
                type: 'remove',
                field: 'SalesAdmins',
                value: value.vismaId,
              })}}},[_vm._v(" Remove filter ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Company Category")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Routes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Filter by sales admin")])])
}]

export { render, staticRenderFns }