<template>
  <div v-if="editingStrings !== null" class="language-content">
    <div class="language-content-inner">
      <div class="language-group-selector">
        <div
          class="language-group-button"
          v-for="group in stringGroups"
          :key="'group-button-' + group"
          :class="{ active: selectedGroup === group }"
          @click="$store.commit('languages/setSelectedGroup', group)"
        >
          {{ group }}
          <span class="empty-counter" v-if="emptyByGroup(group) > 0">{{
            emptyByGroup(group)
          }}</span>
        </div>
      </div>

      <div class="group-fields">
        <div
          v-for="(field, index) in editingStrings[selectedGroup]"
          :key="selectedLanguage + field.key"
          class="languages-input-container"
        >
          <span class="label" :class="{ empty: field.value === '' }">{{
            field.key
          }}</span>
          <input
            type="text"
            :placeholder="strings[selectedGroup][field.key]"
            :value="field.value"
            @change="inputChange($event, index, selectedGroup)"
          />
        </div>
      </div>
    </div>
    <div class="language-content-footer">
      <button
        v-if="selectedLanguage !== 'en'"
        @click="deleteLanguage"
        class="delete"
      >
        <span class="material-icons">delete</span>
        Delete language
      </button>

      <div class="language-content-footer-right">
        <label>Published:</label>
        <div class="published-radio">
          <div
            class="option"
            @click="$store.commit('languages/setEditingPublished', true)"
          >
            <span
              class="radio"
              :class="{ active: editingPublished === true }"
            ></span
            >Yes
          </div>
          <div
            class="option"
            @click="$store.commit('languages/setEditingPublished', false)"
          >
            <span
              class="radio"
              :class="{ active: editingPublished === false }"
            ></span
            >No
          </div>
        </div>

        <button class="save" @click="saveChanges">
          <span class="material-icons">save</span>Save changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LanguagesFields",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedLanguage: (state) => state.languages.selectedLanguage,
      editingStrings: (state) => state.languages.editingStrings,
      editingPublished: (state) => state.languages.editingPublished,
      selectedGroup: (state) => state.languages.selectedGroup,
    }),
    ...mapGetters("languages", ["enabledLanguages", "stringGroups", "strings"]),
  },
  methods: {
    inputChange: function (event, index, group) {
      let strings = JSON.parse(JSON.stringify(this.editingStrings));

      strings[group][index].value = event.target.value.trim();

      this.$store.commit("languages/setUnsavedChanges", true);
      this.$store.commit("languages/setEditingStrings", strings);
    },
    deleteLanguage: function () {
      let confirm = window.confirm(
        "Delete language and all translations?\nThis can not be undone."
      );
      if (confirm) {
        this.$store.commit("languages/setLoading", true);
        this.$store.dispatch("languages/deleteLanguage").then(() => {
          this.$toast.center("Delete successful");
          this.$store.commit("languages/setEditingStrings", null);
          this.$store.commit("languages/setLoading", false);
        });
      }
    },
    saveChanges: function () {
      this.$store.commit("languages/setLoading", true);
      this.$store.dispatch("languages/saveChanges").then(() => {
        this.$toast.center("Save successfull");
        this.$store.commit("languages/setLoading", false);
      });
    },
    emptyByGroup: function (group) {
      let count = 0;

      this.editingStrings[group].forEach((field) => {
        if (field.value === "") {
          count++;
        }
      });

      return count;
    },
  },
};
</script>
