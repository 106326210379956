<template>
  <div class="languages-add-new" v-if="addNew">
    <div class="languages-add-new-container">
      <h3 v-if="disabledLanguages.length > 0">Add new language</h3>
      <h3 v-else>All languages already activated</h3>

      <span
        class="material-icons close"
        @click="$store.commit('languages/setAddNew', false)"
        >close</span
      >

      <div class="languages-add-new-inner">
        <div
          v-for="lang in disabledLanguages"
          :key="'add-new-' + lang.code"
          class="select-new-language-button"
          @click="addLanguage(lang.code, lang.name)"
        >
          <img :src="require('../../assets/flags/' + lang.code + '.png')" />
          {{ lang.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LanguagesAddNewPopup",
  mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      addNew: (state) => state.languages.addNew,
    }),
    ...mapGetters("languages", ["disabledLanguages"]),
  },
  methods: {
    addLanguage: function (code, name) {
      this.$store.commit("languages/setLoading", true);
      this.$store
        .dispatch("languages/addLanguage", { code: code, name: name })
        .then(() => {
          this.$store.dispatch("languages/setupLanguage", code).then(() => {
            this.$store.commit("languages/setAddNew", false);
            this.$store.commit("languages/setLoading", false);
            this.$toast.center("Added language " + name);
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
