<template>
  <div class="shortlists-product-selection-container">
    <div class="upsell-companies-segments">
      <UpsellCurrentListCompanies />

      <UpsellHomeSegments v-if="type == 'home'" />
    </div>

    <div class="shortlists-products-outer">
      <div class="shortlists-products-display">
        <h4>Products</h4>

        <draggable
          class="shortlists-products-inner"
          v-model="products"
          @start="onDragStart"
          @end="onDragEnd"
        >
          <div
            v-for="(product, index) in productsWithData"
            :key="'display-' + product.firebaseId + index"
          >
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="pic">
              <span v-if="product.image != ''" class="material-icons">
                image
              </span>
            </span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="Object.keys(products).includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
          </div>
        </draggable>
      </div>

      <div class="shortlists-products-search">
        <input type="search" placeholder="Search product..." v-model="search" />
        <div class="shortlist-search-container">
          <div v-for="product in getProductsBySearch(search)" :key="product.id">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="pic">
              <span v-if="product.image != ''" class="material-icons">
                image
              </span>
            </span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="products.includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
            <span
              v-else
              class="action add"
              @click="productAdd(product.firebaseId)"
              >Add</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
// import { db } from "../../main";

export default {
  name: "UpsellProductSelection",
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapState({
      products: (state) => state.cartUpsell.products,
      type: (state) => state.cartUpsell.editingType,
    }),
    ...mapGetters("products", ["getProductsBySearch"]),
    ...mapGetters("cartUpsell", ["productsWithData"]),
    products: {
      get() {
        return this.$store.state.cartUpsell.products;
      },
      set(value) {
        this.$store.commit("cartUpsell/setProducts", value);
      },
    },
  },
  components: { draggable },
  methods: {
    productRemove: function (firebaseId) {
      let newProducts = JSON.parse(JSON.stringify(this.products));
      newProducts = newProducts.filter((id) => id !== firebaseId);
      this.$store.commit("cartUpsell/setProducts", newProducts);
    },
    productAdd: function (firebaseId) {
      let newProducts = JSON.parse(JSON.stringify(this.products));
      newProducts.push(firebaseId);
      this.$store.commit("cartUpsell/setProducts", newProducts);
    },
    onDragStart: function (evt) {
      console.log(evt);
      // this.$store.commit("notifications/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      console.log({
        item: evt.item,
        from: evt.from,
        to: evt.to,
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
      });

      console.log(this.products);

      // this.$store.commit("notifications/setSelectedBlockIndex", evt.newIndex);
    },
  },
};
</script>
