import { db } from "../main.js";
import { firestoreAction } from "vuexfire";

const priceLists = {
  namespaced: true,
  state: {
    priceLists: null,
    selectedPriceList: null,
    init: false,
  },
  mutations: {
    setInit(state, payload) {
      state.init = payload;
    },
    setPriceLists(state, payload) {
      state.priceLists = payload;
    },
  },
  getters: {
    getStandardPriceList: (state) => {
      return state.priceLists.find((list) => list.id === "standard");
    },
    getPriceListById: (state) => (id) => {
      return state.priceLists.find((list) => list.id === id);
    },
  },
  actions: {
    bindSelectedPriceList: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        "selectedPriceList",
        db.collection("priceListsNew").doc(payload)
      );
    }),
    unbindSelectedPriceList: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("selectedPriceList");
    }),
  },
};
export default priceLists;
