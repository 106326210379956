<template>
  <div class="shortlist-edit-footer">
    <button @click="exitShortlist" class="leave-button">
      Exit without saving
    </button>
    <button @click="saveShortlist">Save</button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompanyShortlistsFooter",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.companyShortlists.loading,
    }),
  },
  components: {},
  methods: {
    exitShortlist: function () {
      if (this.loading === true) {
        return;
      }

      this.$store.dispatch("companyShortlists/exitEditing");
    },
    saveShortlist: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.commit("companyShortlists/setLoading", true);

      this.$store.dispatch("companyShortlists/saveShortlist").then(() => {
        this.$store.commit("companyShortlists/setLoading", false);
      });
    },
  },
};
</script>
