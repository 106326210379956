<template>
  <div class="web-pages-current-blocks-container">
    <div v-if="editingBlocks.length > 0" class="web-pages-current-blocks">
      <draggable v-model="editingBlocks" @start="onDragStart" @end="onDragEnd">
        <div
          v-for="(block, index) in editingBlocks"
          :key="'block-' + index"
          class="web-pages-current-single-block"
          :class="{ active: index === selectedBlockIndex }"
          @click="selectBlock(index)"
        >
          <p>{{ block.type }}</p>
          <span
            class="material-icons delete-icon"
            @click.stop="deleteBlock(index)"
            >delete</span
          >
        </div>
      </draggable>
    </div>
    <div v-else class="web-pages-no-blocks">
      <p>No blocks added yet</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "WebPagesCurrentBlocks",
  data() {
    return {};
  },
  components: { draggable },
  computed: {
    ...mapState({
      selectedBlockIndex: (state) => state.webPages.selectedBlockIndex,
    }),
    editingBlocks: {
      get() {
        return this.$store.state.webPages.editingBlocks;
      },
      set(value) {
        this.$store.commit("webPages/setEditingBlocks", value);
      },
    },
  },
  mounted() {},
  methods: {
    onDragStart: function (evt) {
      this.$store.commit("webPages/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      this.$store.commit("webPages/setSelectedBlockIndex", evt.newIndex);
    },
    selectBlock: function (index) {
      if (this.selectedBlockIndex === index) {
        index = null;
      }

      this.$store.commit("webPages/setSelectedBlockIndex", index);
    },
    deleteBlock: function (index) {
      console.log("run");

      const confirm = window.confirm(
        "Are you sure you want to delete this block?"
      );

      if (confirm) {
        this.$store.commit("webPages/setSelectedBlockIndex", null);
        this.$store.commit("webPages/deleteBlock", index);
      }
    },
  },
};
</script>
