<template>
  <div class="chat-product-card">
    <div class="chat-product-top">
      <!-- TODO : IMG -->
      <div v-if="product.image !== ''" class="chat-product-image">
        <img :src="product.image" />
      </div>
      <div class="chat-product-meta">
        <div class="chat-product-title-sku">
          <h4>{{ product.name }}</h4>
          <span class="sku">{{
            product.name === "Deleted product" ? "" : product.sku
          }}</span>
        </div>
        <p class="product-description">{{ product.description }}</p>
      </div>
    </div>

    <div class="chat-product-bottom">
      <div class="product-stock-price">
        <span class="stock">Lagerstatus</span>
        <span class="price">Pris</span>
      </div>
      <span class="material-icons">add_circle_outline</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
