<template>
  <div class="shortlists-company-boxes-container">
    <div class="admin-table">
      <div class="table-header">
        <span class="category label">Company Category</span>
      </div>
      <div class="inner">
        <div v-for="(value, key) in companyCategories" :key="'cat-' + key">
          <span>{{ value }}</span>
          <span
            v-if="selectedCompanyCategories.indexOf(key) === -1"
            @click="
              $store.dispatch('sharedShortlists/updateSelection', {
                type: 'add',
                field: 'CompanyCategories',
                value: key,
              })
            "
            class="action add"
          >
            Add
          </span>
          <span
            v-else
            @click="
              $store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'CompanyCategories',
                value: key,
              })
            "
            class="action remove"
          >
            Remove
          </span>
        </div>
      </div>
    </div>

    <div class="admin-table">
      <div class="table-header">
        <span class="category label">{{ priceGroup1Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups1"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>
            <span
              v-if="selectedPriceGroups1.indexOf(key) === -1"
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups1',
                  value: key,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups1',
                  value: key,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table">
      <div class="table-header">
        <span class="category label">{{ priceGroup2Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups2"
          :class="{ hidden: isHidden(key) }"
          :key="'cat-' + key"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>
            <span
              v-if="selectedPriceGroups2.indexOf(key) === -1"
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups2',
                  value: key,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups2',
                  value: key,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table">
      <div class="table-header">
        <span class="category label">{{ priceGroup3Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups3"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'" class="value-wrapper">
            <span>{{ value }}</span>
            <span
              v-if="selectedPriceGroups3.indexOf(key) === -1"
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'add',
                  field: 'PriceGroups3',
                  value: key,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('sharedShortlists/updateSelection', {
                  type: 'remove',
                  field: 'PriceGroups3',
                  value: key,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table">
      <div class="table-header">
        <span class="category label">Companies</span>
        <div class="company-search">
          <input type="text" v-model="search" placeholder="Search company..." />
        </div>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in getCompaniesBySearch(search)"
          :key="'cat-' + key"
        >
          <span>{{ value.name }}</span>
          <span
            v-if="selectedCompanies.indexOf(value.vismaId) === -1"
            @click="
              $store.dispatch('sharedShortlists/updateSelection', {
                type: 'add',
                field: 'Companies',
                value: value.vismaId,
              })
            "
            class="action add"
          >
            Add
          </span>
          <span
            v-else
            @click="
              $store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'Companies',
                value: value.vismaId,
              })
            "
            class="action remove"
          >
            Remove
          </span>
        </div>
      </div>
    </div>

    <SharedShortlistsFinalCompanies />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "SharedShortlistsCompanySelection",
  data() {
    return {
      search: "",
    };
  },
  components: {},
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies.companies,
      selectedCompanyCategories: (state) =>
        state.sharedShortlists.selectedCompanyCategories,
      selectedPriceGroups1: (state) =>
        state.sharedShortlists.selectedPriceGroups1,
      selectedPriceGroups2: (state) =>
        state.sharedShortlists.selectedPriceGroups2,
      selectedPriceGroups3: (state) =>
        state.sharedShortlists.selectedPriceGroups3,
      selectedCompanies: (state) => state.sharedShortlists.selectedCompanies,
      companyCategories: (state) => state.settings.taxonomies.categories,
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      priceGroups3: (state) => state.settings.taxonomies.priceGroup3,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId", "getCompaniesBySearch"]),
    ...mapGetters("users", ["getUserNameByVismaId", "getAdminAccountOwners"]),
    ...mapGetters("settings", [
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none !important;
}
</style>
