import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import Vue from "vue";
// import firebase from "firebase/app";
// import { nanoid } from "nanoid";
const popups = {
  namespaced: true,
  state: {
    init: false,
    surveys: [],
    editingMode: null,
    editingQuestion: null,
    editingType: null,
    editingMinLabel: "",
    editingMaxLabel: "",
    editingEnding: null,
    filter: "all",
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    setEditingMode: (state, data) => {
      state.editingMode = data;
    },
    setEditingQuestion: (state, data) => {
      state.editingQuestion = data;
    },
    setEditingType: (state, data) => {
      state.editingType = data;
    },
    setEditingMinLabel: (state, data) => {
      state.editingMinLabel = data;
    },
    setEditingMaxLabel: (state, data) => {
      state.editingMaxLabel = data;
    },
    setEditingEnding: (state, data) => {
      state.editingEnding = data;
    },
    setFilter: (state, data) => {
      console.log("here");
      state.filter = data;
    },
  },
  getters: {},
  actions: {
    bindSurveys: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "surveys",
        db.collection("surveys").orderBy("ending", "desc")
      );
    }),
    async saveSurvey({ state }) {
      console.log("here");

      const ending = firebase.firestore.Timestamp.fromDate(
        Vue.moment(state.editingEnding, "YYYY-MM-DD — HH:mm").toDate()
      );
      const surveyData = {
        answers: {},
        ending: ending,
        maxLabel: state.editingMaxLabel === null ? "" : state.editingMaxLabel,
        minLabel: state.editingMinLabel === null ? "" : state.editingMinLabel,
        question: state.editingQuestion,
        type: state.editingType,
      };

      console.log(state.editingMode);

      if (state.editingMode === "create") {
        surveyData.created = new Date();

        await db.collection("surveys").add(surveyData);
      } else {
        await db
          .collection("surveys")
          .doc(state.editingMode)
          .update({
            ending: ending,
            question: state.editingQuestion,
            maxLabel:
              state.editingMaxLabel === null ? "" : state.editingMaxLabel,
            minLabel:
              state.editingMinLabel === null ? "" : state.editingMinLabel,
          });
      }
    },
    clearEditing({ commit }) {
      commit("setEditingMode", null);
      commit("setEditingQuestion", null);
      commit("setEditingType", null);
      commit("setEditingMinLabel", "");
      commit("setEditingMaxLabel", "");
      commit("setEditingEnding", null);
    },
    async deleteSurvey({ state }) {
      if (state.editingMode === null || state.editingMode === "create") {
        // Nothing to delete
        return;
      }

      await db.collection("surveys").doc(state.editingMode).delete();
    },
    setupEditing({ commit }, payload) {
      const survey = payload;

      console.log(
        Vue.moment(survey.ending.toDate()).format("YYYY-MM-DD — HH:mm")
      );

      commit("setEditingMode", survey.id);
      commit("setEditingQuestion", survey.question);
      commit("setEditingType", survey.type);
      commit("setEditingMinLabel", survey.minLabel);
      commit("setEditingMaxLabel", survey.maxLabel);
      commit(
        "setEditingEnding",
        Vue.moment(survey.ending.toDate()).format("YYYY-MM-DD — HH:mm")
      );
    },
    unbindSurveys: firestoreAction((context) => {
      return context.unbindFirestoreRef("surveys");
    }),
    filteredAnswers: ({ state, rootState, rootGetters }, answers) => {
      console.log(answers);
      console.log(rootState);
      console.log(rootGetters);

      if (state.filter === "all") {
        console.log("here");
        console.log(answers);
        return answers;
      }

      return [];
    },
  },
};
export default popups;
