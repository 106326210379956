<template>
  <div class="product-image-toggle">
    <h3>Activate product images</h3>
    <div class="product-check" @click="update">
      <div
        class="check"
        :class="{ active: displayProductImages === true }"
      ></div>
      <label>Display product images if available</label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductImageToggle",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  created() {},
  computed: {
    ...mapGetters("settings", ["displayProductImages"]),
  },
  methods: {
    update: function () {
      this.$store.dispatch(
        "settings/updateDisplayProductImages",
        !this.displayProductImages
      );
    },
  },
};
</script>
