<template>
  <div class="web-pages-editor">
    <WebPagesEditorForm />
    <WebPagesBuilder />
    <WebPagesEditorActions />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "WebPagesEditor",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      editing: (state) => state.webPages.editing,
      editingId: (state) => state.webPages.editingId,
      editingMetaTitle: (state) => state.webPages.editingMetaTitle,
    }),
    ...mapGetters("webPages", ["currentPage"]),
  },
  created() {
    this.$store.dispatch("webPages/setupEditing");
  },
  mounted() {},
  methods: {
    goBack: function () {
      this.$store.commit("webPages/setEditing", false);
      this.$store.dispatch("webPages/resetEditing");
    },
  },
};
</script>
