var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortlists-company-boxes-container"},[_c('div',{staticClass:"admin-table"},[_vm._m(0),_c('div',{staticClass:"inner"},_vm._l((_vm.companyCategories),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value))]),(_vm.selectedCompanyCategories.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
              type: 'add',
              field: 'CompanyCategories',
              value: key,
            })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
              type: 'remove',
              field: 'CompanyCategories',
              value: key,
            })}}},[_vm._v(" Remove ")])])}),0)]),_c('div',{staticClass:"admin-table"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup1Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups1),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),(_vm.selectedPriceGroups1.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'add',
                field: 'PriceGroups1',
                value: key,
              })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'PriceGroups1',
                value: key,
              })}}},[_vm._v(" Remove ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup2Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups2),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',[_c('span',[_vm._v(_vm._s(value))]),(_vm.selectedPriceGroups2.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'add',
                field: 'PriceGroups2',
                value: key,
              })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'PriceGroups2',
                value: key,
              })}}},[_vm._v(" Remove ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v(_vm._s(_vm.priceGroup3Name))])]),_c('div',{staticClass:"inner"},_vm._l((_vm.priceGroups3),function(value,key){return _c('div',{key:'cat-' + key,class:{ hidden: _vm.isHidden(key) }},[(key != 'name')?_c('div',{staticClass:"value-wrapper"},[_c('span',[_vm._v(_vm._s(value))]),(_vm.selectedPriceGroups3.indexOf(key) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'add',
                field: 'PriceGroups3',
                value: key,
              })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'PriceGroups3',
                value: key,
              })}}},[_vm._v(" Remove ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"admin-table"},[_c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Companies")]),_c('div',{staticClass:"company-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","placeholder":"Search company..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})])]),_c('div',{staticClass:"inner"},_vm._l((_vm.getCompaniesBySearch(_vm.search)),function(value,key){return _c('div',{key:'cat-' + key},[_c('span',[_vm._v(_vm._s(value.name))]),(_vm.selectedCompanies.indexOf(value.vismaId) === -1)?_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
              type: 'add',
              field: 'Companies',
              value: value.vismaId,
            })}}},[_vm._v(" Add ")]):_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
              type: 'remove',
              field: 'Companies',
              value: value.vismaId,
            })}}},[_vm._v(" Remove ")])])}),0)]),_c('SharedShortlistsFinalCompanies')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-header"},[_c('span',{staticClass:"category label"},[_vm._v("Company Category")])])
}]

export { render, staticRenderFns }