<template>
  <div class="shortlists-final-companies">
    <h4>Current companies with list - {{ currentCompanies.length }}</h4>
    <div class="inner-selected">
      <span
        v-for="(company, index) in currentCompanies"
        :key="index + company.vismaId.toString()"
      >
        {{ getCompanyByVismaId(company.vismaId).name }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UpsellCurrentListCompanies",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("cartUpsell", ["currentCompanies"]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
  },
  components: {},
  methods: {},
};
</script>
