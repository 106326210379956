<template>
  <div class="shortlists-final-companies">
    <h4>Selected companies - {{ finalCompanies.length }}</h4>
    <div class="inner-selected">
      <span
        v-for="company in finalCompanies"
        :key="company.firebaseId + company.vismaId"
      >
        {{ getCompanyByVismaId(company.vismaId).name }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('sharedShortlists/updateSelection', {
              type: 'add',
              field: 'Exclusion',
              value: company.vismaId,
            })
          "
          >close</span
        >
      </span>
    </div>
    <div v-if="excludedCompanies.length" class="inner-excluded">
      <h4>Excluded companies</h4>
      <div class="inner">
        <span v-for="company in excludedCompanies" :key="'excluded-' + company">
          <span v-if="getCompanyByVismaId(company) !== undefined">{{
            getCompanyByVismaId(company).name
          }}</span>
          <span v-else>Deleted company: {{ company }}</span>
          <span
            class="material-icons"
            @click="
              $store.dispatch('sharedShortlists/updateSelection', {
                type: 'remove',
                field: 'Exclusion',
                value: company,
              })
            "
            >close</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SharedShortlistsFinalCompanies",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      excludedCompanies: (state) => state.sharedShortlists.selectedExclusion,
    }),
    ...mapGetters("sharedShortlists", ["finalCompanies"]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
  },
  components: {},
  methods: {},
};
</script>
