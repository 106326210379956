<template>
  <div class="shortlist-edit-header">
    <div class="shortlist-names">
      <input
        type="text"
        placeholder="Shortlist name..."
        v-model="editingName"
      />
    </div>

    <div class="account-owner-selector" v-if="currentCompanies.length == 0">
      <div class="account-owner-all" @click="selectListType('cart')">
        <div class="radio" :class="{ active: editingType == 'cart' }"></div>
        Cart
      </div>
      <div class="account-owner-mine" @click="selectListType('home')">
        <div class="radio" :class="{ active: editingType == 'home' }"></div>
        Home
      </div>
    </div>

    <span class="shortlist-delete" @click="deleteUpsellList">
      <span class="material-icons">delete</span>
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "UpsellListHeader",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
      editingType: (state) => state.cartUpsell.editingType,
    }),
    ...mapGetters("cartUpsell", ["currentCompanies"]),
    editingName: {
      get() {
        return this.$store.state.cartUpsell.editingName;
      },
      set(value) {
        this.$store.commit("cartUpsell/setEditingName", value);
      },
    },
  },
  components: {},
  methods: {
    selectListType: function (type) {
      this.$store.commit("cartUpsell/setEditingType", type);
    },
    deleteUpsellList: function () {
      if (this.loading) {
        return;
      }
      let confirm = window.confirm(
        "Permanently delete upsell list?\nThis can not be undone."
      );
      if (confirm) {
        this.$store.commit("cartUpsell/setLoading", true);

        this.$store.dispatch("cartUpsell/deleteUpsellList").then(() => {
          this.$store.commit("cartUpsell/setLoading", false);
        });
      }
    },
  },
};
</script>
