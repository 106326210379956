<template>
  <div class="shortlist-edit-header">
    <div class="shortlist-names">
      <input
        type="text"
        placeholder="Internal shortlist name..."
        v-model="editingName"
      />
    </div>

    <span class="shortlist-delete" @click="deleteShortlist">
      <span class="material-icons">delete</span>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CompanyShortlistsHeader",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.companyShortlists.loading,
    }),
    editingName: {
      get() {
        return this.$store.state.companyShortlists.editingName;
      },
      set(value) {
        this.$store.commit("companyShortlists/setEditingName", value);
      },
    },
  },
  components: {},
  methods: {
    deleteShortlist: function () {
      if (this.loading) {
        console.log("loading");
        return;
      }

      let confirm = window.confirm(
        "Permanently delete shortlist?\nThis can not be undone."
      );
      if (confirm) {
        this.$store.commit("companyShortlists/setLoading", true);

        this.$store.dispatch("companyShortlists/deleteShortlist").then(() => {
          this.$store.commit("companyShortlists/setLoading", false);
        });
      }
    },
  },
};
</script>
