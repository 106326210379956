import { db } from "./../main.js";
import { firestoreAction } from "vuexfire";

const logs = {
  namespaced: true,
  state: {
    init: false,
    productSyncLog: null,
    adminUserSyncLog: null,
    userSyncLog: null,
    companySyncLog: null,
    orderSyncLog: null,
    priceListSyncLog: null,
    priceListRemoveLog: null,
    priceListNewSyncLog: null,
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    productSyncDatesTick: (state, payload) => {
      state.poductSyncLog = payload;
    },
    adminUserSyncDatesTick: (state, payload) => {
      state.adminUserSyncLog = payload;
    },
    userSyncDatesTick: (state, payload) => {
      state.userSyncLog = payload;
    },
    companySyncDatesTick: (state, payload) => {
      state.companySyncLog = payload;
    },
    orderSyncDatesTick: (state, payload) => {
      state.orderSyncLog = payload;
    },
  },
  getters: {},
  actions: {
    bindProductSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "productSyncLog",
        db
          .collection("logs")
          .doc("productSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    bindAdminUserSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "adminUserSyncLog",
        db
          .collection("logs")
          .doc("adminUserSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    bindUserSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "userSyncLog",
        db
          .collection("logs")
          .doc("userSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    bindCompanySyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "companySyncLog",
        db
          .collection("logs")
          .doc("companySync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    bindOrderSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "orderSyncLog",
        db
          .collection("logs")
          .doc("orderSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    unbindSystemLogs: firestoreAction((context) => {
      context.unbindFirestoreRef("productSyncLog");
      context.unbindFirestoreRef("adminUserSyncLog");
      context.unbindFirestoreRef("userSyncLog");
      context.unbindFirestoreRef("companySyncLog");
      context.unbindFirestoreRef("orderSyncLog");
    }),
    bindPriceListSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "priceListSyncLog",
        db
          .collection("logs")
          .doc("priceListSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    bindPriceListNewSyncLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "priceListNewSyncLog",
        db
          .collection("logs")
          .doc("priceListNewSync")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(20)
      );
    }),
    bindPriceListRemoveLog: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "priceListRemoveLog",
        db
          .collection("logs")
          .doc("priceListRemove")
          .collection("log")
          .orderBy("completedAt", "desc")
          .limit(10)
      );
    }),
    tickProductSyncDates: (context) => {
      context.commit(
        "productSyncDatesTick",
        context.state.productSyncLog.map((entry) => {
          entry.completedAt.nanoseconds += 1;
          return entry;
        })
      );
    },
    tickAdminUserSyncDates: (context) => {
      context.commit(
        "adminUserSyncDatesTick",
        context.state.adminUserSyncLog.map((entry) => {
          entry.completedAt.nanoseconds += 1;
          return entry;
        })
      );
    },
    tickUserSyncDates: (context) => {
      context.commit(
        "userSyncDatesTick",
        context.state.userSyncLog.map((entry) => {
          entry.completedAt.nanoseconds += 1;
          return entry;
        })
      );
    },
    tickCompanySyncDates: (context) => {
      context.commit(
        "companySyncDatesTick",
        context.state.companySyncLog.map((entry) => {
          entry.completedAt.nanoseconds += 1;
          return entry;
        })
      );
    },
    tickOrderSyncDates: (context) => {
      context.commit(
        "orderSyncDatesTick",
        context.state.orderSyncLog.map((entry) => {
          entry.completedAt.nanoseconds += 1;
          return entry;
        })
      );
    },
  },
};
export default logs;
