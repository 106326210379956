<template>
  <div class="upsell-company-filters">
    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">Company Category</span>
      </div>
      <div class="inner">
        <div v-for="(value, key) in companyCategories" :key="'cat-' + key">
          <span>{{ value }}</span>
          <div class="actions">
            <span
              v-if="selectedCompanyCategories.indexOf(key) === -1"
              @click="
                $store.dispatch('cartUpsell/updateSelection', {
                  type: 'add',
                  field: 'CompanyCategories',
                  value: key,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('cartUpsell/updateSelection', {
                  type: 'remove',
                  field: 'CompanyCategories',
                  value: key,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup1Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups1"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="
                  excludedPriceGroups1.indexOf(key) === -1 &&
                  selectedPriceGroups1.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('cartUpsell/updateExclusion', {
                    type: 'add',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedPriceGroups1.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateExclusion', {
                    type: 'remove',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedPriceGroups1.indexOf(key) === -1 &&
                  excludedPriceGroups1.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('cartUpsell/updateSelection', {
                    type: 'add',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action add"
                >Add</span
              >
              <span
                v-if="selectedPriceGroups1.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateSelection', {
                    type: 'remove',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action remove"
                >Remove</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup2Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups2"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="
                  excludedPriceGroups2.indexOf(key) === -1 &&
                  selectedPriceGroups2.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('cartUpsell/updateExclusion', {
                    type: 'add',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedPriceGroups2.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateExclusion', {
                    type: 'remove',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedPriceGroups2.indexOf(key) === -1 &&
                  excludedPriceGroups2.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('cartUpsell/updateSelection', {
                    type: 'add',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action add"
              >
                Add
              </span>
              <span
                v-if="selectedPriceGroups2.indexOf(key) !== -1"
                @click="
                  $store.dispatch('cartUpsell/updateSelection', {
                    type: 'remove',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action remove"
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "UpsellCompanyFilters",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
      companyCategories: (state) => state.settings.taxonomies.categories,
      selectedCompanyCategories: (state) =>
        state.cartUpsell.selectedCompanyCategories,
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      priceGroups3: (state) => state.settings.taxonomies.priceGroup3,
      selectedPriceGroups1: (state) => state.cartUpsell.selectedPriceGroups1,
      excludedPriceGroups1: (state) => state.cartUpsell.excludedPriceGroups1,
      selectedPriceGroups2: (state) => state.cartUpsell.selectedPriceGroups2,
      excludedPriceGroups2: (state) => state.cartUpsell.excludedPriceGroups2,
    }),
    ...mapGetters("settings", [
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
    ]),
  },
  components: {},
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
  },
};
</script>
