<template>
  <div class="chat-mass-message-selector">
    <div class="chat-mass-message-selector-wrapper" v-if="filterOpen">
      <div class="chat-mass-message-selector-inner">
        <div class="notifications-group-select">
          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">Company Category</span>
            </div>
            <div class="inner">
              <div
                v-for="(value, key) in companyCategories"
                :key="'cat-' + key"
              >
                <span>{{ value }}</span>
                <div class="actions">
                  <span
                    v-if="selectedCompanyCategories.indexOf(key) === -1"
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'CompanyCategories',
                        value: key,
                      })
                    "
                    class="action add"
                  >
                    Add
                  </span>
                  <span
                    v-else
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'CompanyCategories',
                        value: key,
                      })
                    "
                    class="action remove"
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">{{ priceGroup1Name }}</span>
            </div>
            <div class="inner">
              <div
                v-for="(value, key) in priceGroups1"
                :key="'cat-' + key"
                :class="{ hidden: isHidden(key) }"
              >
                <div v-if="key != 'name'">
                  <span>{{ value }}</span>

                  <div class="actions">
                    <span
                      v-if="
                        excludedPriceGroups1.indexOf(key) === -1 &&
                        selectedPriceGroups1.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'add',
                          field: 'PriceGroups1',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Exclude</span
                    >
                    <span
                      v-if="excludedPriceGroups1.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'remove',
                          field: 'PriceGroups1',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Remove exclusion</span
                    >
                    <span
                      v-if="
                        selectedPriceGroups1.indexOf(key) === -1 &&
                        excludedPriceGroups1.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'add',
                          field: 'PriceGroups1',
                          value: key,
                        })
                      "
                      class="action add"
                      >Add</span
                    >
                    <span
                      v-if="selectedPriceGroups1.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'remove',
                          field: 'PriceGroups1',
                          value: key,
                        })
                      "
                      class="action remove"
                      >Remove</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">{{ priceGroup2Name }}</span>
            </div>
            <div class="inner">
              <div
                v-for="(value, key) in priceGroups2"
                :key="'cat-' + key"
                :class="{ hidden: isHidden(key) }"
              >
                <div v-if="key != 'name'">
                  <span>{{ value }}</span>

                  <div class="actions">
                    <span
                      v-if="
                        excludedPriceGroups2.indexOf(key) === -1 &&
                        selectedPriceGroups2.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'add',
                          field: 'PriceGroups2',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Exclude</span
                    >
                    <span
                      v-if="excludedPriceGroups2.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'remove',
                          field: 'PriceGroups2',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Remove exclusion</span
                    >
                    <span
                      v-if="
                        selectedPriceGroups2.indexOf(key) === -1 &&
                        excludedPriceGroups2.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'add',
                          field: 'PriceGroups2',
                          value: key,
                        })
                      "
                      class="action add"
                    >
                      Add
                    </span>
                    <span
                      v-if="selectedPriceGroups2.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'remove',
                          field: 'PriceGroups2',
                          value: key,
                        })
                      "
                      class="action remove"
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">{{ priceGroup3Name }}</span>
            </div>
            <div class="inner">
              <div
                v-for="(value, key) in priceGroups3"
                :key="'cat-' + key"
                :class="{ hidden: isHidden(key) }"
              >
                <div v-if="key != 'name'">
                  <span>{{ value }}</span>
                  <div class="actions">
                    <span
                      v-if="
                        excludedPriceGroups3.indexOf(key) === -1 &&
                        selectedPriceGroups3.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'add',
                          field: 'PriceGroups3',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Exclude</span
                    >
                    <span
                      v-if="excludedPriceGroups3.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'remove',
                          field: 'PriceGroups3',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Remove exclusion</span
                    >
                    <span
                      v-if="
                        selectedPriceGroups3.indexOf(key) === -1 &&
                        excludedPriceGroups3.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'add',
                          field: 'PriceGroups3',
                          value: key,
                        })
                      "
                      class="action add"
                    >
                      Add
                    </span>
                    <span
                      v-if="selectedPriceGroups3.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'remove',
                          field: 'PriceGroups3',
                          value: key,
                        })
                      "
                      class="action remove"
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">Routes</span>
            </div>
            <div class="inner">
              <div v-for="(value, key) in routes" :key="'route-' + key">
                <div>
                  <span>{{ value }}</span>
                  <div class="actions">
                    <span
                      v-if="
                        excludedRoutes.indexOf(key) === -1 &&
                        selectedRoutes.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'add',
                          field: 'Routes',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Exclude</span
                    >
                    <span
                      v-if="excludedRoutes.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateExclusion', {
                          type: 'remove',
                          field: 'Routes',
                          value: key,
                        })
                      "
                      class="action exclude"
                      >Remove exclusion</span
                    >
                    <span
                      v-if="
                        selectedRoutes.indexOf(key) === -1 &&
                        excludedRoutes.indexOf(key) === -1
                      "
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'add',
                          field: 'Routes',
                          value: key,
                        })
                      "
                      class="action add"
                    >
                      Add
                    </span>
                    <span
                      v-if="selectedRoutes.indexOf(key) !== -1"
                      @click="
                        $store.dispatch('chat/updateSelection', {
                          type: 'remove',
                          field: 'Routes',
                          value: key,
                        })
                      "
                      class="action remove"
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">Companies</span>
              <div class="company-search">
                <input
                  type="text"
                  v-model="companySearch"
                  placeholder="Search company..."
                />
              </div>
            </div>
            <div class="inner">
              <div
                v-for="(value, key) in getCompaniesBySearch(companySearch)"
                :key="'cat-' + key"
              >
                <span>{{ value.name }}</span>
                <div class="actions">
                  <span
                    v-if="selectedCompanies.indexOf(value.vismaId) === -1"
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'Companies',
                        value: value.vismaId,
                      })
                    "
                    class="action add"
                  >
                    Add
                  </span>
                  <span
                    v-else
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'Companies',
                        value: value.vismaId,
                      })
                    "
                    class="action remove"
                  >
                    Remove
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="admin-table company-categories">
            <div class="table-header">
              <span class="category label">Filter by sales admin</span>
            </div>
            <div class="inner">
              <div
                v-for="(value, index) in getAdminAccountOwners"
                :key="'sales-users-' + index"
              >
                <span>{{ value.name }}</span>
                <div class="actions">
                  <span
                    v-if="selectedSalesAdmins.indexOf(value.vismaId) === -1"
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'add',
                        field: 'SalesAdmins',
                        value: value.vismaId,
                      })
                    "
                    class="action add"
                  >
                    Add filter
                  </span>
                  <span
                    v-else
                    @click="
                      $store.dispatch('chat/updateSelection', {
                        type: 'remove',
                        field: 'SalesAdmins',
                        value: value.vismaId,
                      })
                    "
                    class="action remove"
                  >
                    Remove filter
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ChatFilterSelections />

          <div class="admin-table">
            <div class="table-header">
              <span class="category label"
                >Final companies - {{ finalCompanies.length }}</span
              >
            </div>
            <div class="inner">
              <div v-for="(value, i) in finalCompanies" :key="'cat-' + i">
                <span>{{ getCompanyByVismaId(value.vismaId).name }}</span>
                <span
                  class="action remove"
                  @click="
                    $store.dispatch('chat/updateExclusion', {
                      type: 'add',
                      field: 'Companies',
                      value: value.vismaId,
                    })
                  "
                >
                  Exclude
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="chat-mass-message-selector-actions">
          <div>
            <button @click="selectAll">Select all</button>
            <button @click="$store.dispatch('chat/clearAllSelections')">
              Clear all
            </button>
          </div>

          <div class="chat-mass-message-selector-type">
            <div
              class="chat-new-filter-check"
              @click="$store.commit('chat/setSelectedType', 'company')"
            >
              <div
                class="check"
                :class="{ active: selectedType == 'company' }"
              ></div>
              <label>Company</label>
            </div>
            <div
              class="chat-new-filter-check"
              @click="$store.commit('chat/setSelectedType', 'personal')"
            >
              <div
                class="check"
                :class="{ active: selectedType == 'personal' }"
              ></div>
              <label>Personal</label>
            </div>
          </div>

          <div>
            <button @click="close">Cancel</button>
            <button @click="save">Continue</button>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="!massSending"
      @click="$store.commit('chat/setFilterOpen', true)"
    >
      Create mass message
    </button>

    <div v-if="massSending" class="mass-sending-in-progress">
      <span class="title">Sending mass message</span>
      <span class="target">Target: {{ finalCompanies.length }} companies</span>
      <span class="type">Roomtype: {{ selectedType }}</span>
      <div class="actions">
        <button @click="close">Cancel</button>
        <button @click="$store.commit('chat/setFilterOpen', true)">
          Change selection
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
// let audio = new Audio(require('@/assets/message.mp3'))

export default {
  name: "ChatMassMessageSelector",
  data() {
    return {
      companySearch: "",
    };
  },
  components: {},
  computed: {
    ...mapState({
      companies: (state) => state.companies.companies,
      companyCategories: (state) => state.settings.taxonomies.categories,
      selectedCompanyCategories: (state) =>
        state.chat.selectedCompanyCategories,
      selectedPriceGroups1: (state) => state.chat.selectedPriceGroups1,
      selectedPriceGroups2: (state) => state.chat.selectedPriceGroups2,
      selectedPriceGroups3: (state) => state.chat.selectedPriceGroups3,
      selectedRoutes: (state) => state.chat.selectedRoutes,
      excludedPriceGroups1: (state) => state.chat.excludedPriceGroups1,
      excludedPriceGroups2: (state) => state.chat.excludedPriceGroups2,
      excludedPriceGroups3: (state) => state.chat.excludedPriceGroups3,
      excludedRoutes: (state) => state.chat.excludedRoutes,
      selectedCompanies: (state) => state.chat.selectedCompanies,
      selectedSalesAdmins: (state) => state.chat.selectedSalesAdmins,
      excludedCompanies: (state) => state.chat.excludedCompanies,
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      priceGroups3: (state) => state.settings.taxonomies.priceGroup3,
      routes: (state) => state.settings.taxonomies.routes,
      selectedType: (state) => state.chat.selectedType,
      filterOpen: (state) => state.chat.filterOpen,
      massSending: (state) => state.chat.massSending,
      selectedChatRoom: (state) => state.chat.selectedChatRoom,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId", "getCompaniesBySearch"]),
    ...mapGetters("users", ["getUserNameByVismaId", "getAdminAccountOwners"]),
    ...mapGetters("chat", ["finalCompanies"]),
    ...mapGetters("settings", [
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
    ]),
  },
  mounted() {},
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
    selectAll: function () {
      this.$store.dispatch("chat/clearAllSelections");
      this.$store.dispatch("chat/selectAll", this.companyCategories);
    },
    close: function () {
      this.$store.dispatch("chat/clearAllSelections");
      this.$store.dispatch("chat/unbindChatRoom");
      this.$store.commit("chat/setSelectedChatRoom", false);
      this.$store.commit("chat/setFilterOpen", false);
      this.$store.commit("chat/setMassSending", false);
    },
    save: function () {
      if (this.finalCompanies.length > 0) {
        if (this.selectedChatRoom !== "mass") {
          // this.$store.commit("chat/setSelectedChatRoom", "mass");
          this.$store.dispatch("chat/bindChatRoom", "mass");
        }
        this.$store.commit("chat/setMassSending", true);
      } else {
        this.$store.dispatch("chat/clearAllSelections");
      }
      this.$store.commit("chat/setFilterOpen", false);
    },
  },
};
</script>
