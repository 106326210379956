import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import { nanoid } from "nanoid";
const popups = {
  namespaced: true,
  state: {
    init: false,
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
  },
  getters: {
    pushDisabledPopups: (state, getters, rootState) => {
      return Object.values(
        rootState.settings.appHelpers.pushDisabledPopups
      ).sort((a, b) => b.endTime.toDate() - a.endTime.toDate());
    },

    pushDisabledPopupsActive: (state, getters) => {
      if (getters.pushDisabledPopups.length === 0) {
        return false;
      }
      let now = new Date();

      return getters.pushDisabledPopups[0].endTime.toDate() > now;
    },
    pushReminderPopups: (state, getters, rootState) => {
      return Object.values(
        rootState.settings.appHelpers.pushReminderPopups
      ).sort((a, b) => b.endTime.toDate() - a.endTime.toDate());
    },
    pushReminderPopupsActive: (state, getters) => {
      if (getters.pushReminderPopups.length === 0) {
        return false;
      }
      let now = new Date();

      return getters.pushReminderPopups[0].endTime.toDate() > now;
    },
    pushNotificationsReminderUsers: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      const users =
        rootGetters[
          "analytics/activeAnalyticsSignedInPushNotificationsEnabledDocs"
        ];

      const data = [];

      users.forEach((user) => {
        const settings = rootGetters["users/getPushNotificationsSettings"](
          user.id
        );

        if (
          settings.chat === true &&
          settings.deliveryInfo === true &&
          settings.newsAndOffers === true
        ) {
          console.log("All notifications enabled");
          return;
        } else {
          data.push(settings);
        }
      });

      return data;
    },
    pushNotificationsDisabledCount: (state, getters) => {
      const arr = getters.pushNotificationsReminderUsers;

      let chat = 0;
      let deliveryInfo = 0;
      let newsAndOffers = 0;

      arr.forEach((settings) => {
        if (settings.chat === false) {
          chat++;
        }
        if (settings.deliveryInfo === false) {
          deliveryInfo++;
        }
        if (settings.newsAndOffers === false) {
          newsAndOffers++;
        }
      });

      return {
        chat: chat,
        deliveryInfo: deliveryInfo,
        newsAndOffers: newsAndOffers,
      };
    },
  },
  actions: {
    bindProducts: firestoreAction((context) => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef("products", db.collection("productsGs1"));
    }),
    unbindProducts: firestoreAction((context) => {
      context.unbindFirestoreRef("products");
    }),
    triggerPushDisabledPopup: async (context) => {
      const id = nanoid();

      const now = new Date();
      const endTime = new Date(now.getTime() + 1000 * 60 * 60 * 24 * 7);

      await db
        .collection("app")
        .doc("appHelpers")
        .update({
          ["pushDisabledPopups." + id]: {
            id: id,
            endTime: endTime,
            endCount: false,
            startCount:
              context.rootGetters[
                "analytics/activeAnalyticsPushNotificationsDisabledCount"
              ],
            openCount: 0,
          },
        });

      return;
    },
    triggerPushReminderPopup: async (context) => {
      const id = nanoid();

      const now = new Date();
      const endTime = new Date(now.getTime() + 1000 * 60 * 60 * 24 * 7);

      await db
        .collection("app")
        .doc("appHelpers")
        .update({
          ["pushReminderPopups." + id]: {
            id: id,
            endTime: endTime,
            endStats: false,
            startCount: context.getters.pushNotificationsReminderUsers.length,
            openCount: 0,
            startStats: context.getters.pushNotificationsDisabledCount,
          },
        });

      return;
    },
    deletePushDisabledPopup: async (context, popup) => {
      await db
        .collection("app")
        .doc("appHelpers")
        .update({
          ["pushDisabledPopups." + popup.id]:
            firebase.firestore.FieldValue.delete(),
        });
    },
    deletePushReminderPopup: async (context, popup) => {
      await db
        .collection("app")
        .doc("appHelpers")
        .update({
          ["pushReminderPopups." + popup.id]:
            firebase.firestore.FieldValue.delete(),
        });
    },
  },
};
export default popups;
