<template>
  <div class="posts-template-handler">
    <div class="posts-template-save posts-template-fixed" v-if="saveOpen">
      <div class="posts-template-save-container">
        <div class="loading" v-if="loading">
          <span class="loader">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                          c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                          c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                          s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                          c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                          c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                          c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                          c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                          c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                          c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                          c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                          c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                          c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                          M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                          c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <h4>Saving template...</h4>
        </div>
        <span class="material-icons close-icon" @click="closeSave">close</span>
        <h4>Create new template</h4>
        <div class="posts-new-template">
          <input
            type="text"
            placeholder="Template name"
            v-model="newTemplateName"
          />
          <button @click="saveTemplate(false)">Create</button>
        </div>
        <h4>Overwrite existing</h4>
        <div class="posts-existing-templates">
          <div
            class="templates-dropdown"
            @click="saveExistingDropdown = !saveExistingDropdown"
          >
            <span class="selected">
              <span class="name" v-if="!saveExistingSelected"
                >Select template</span
              >
              <span class="name" v-else>{{
                templateNameById(saveExistingSelected)
              }}</span>
              <span
                class="material-icons"
                :class="{ active: saveExistingDropdown }"
                >expand_more</span
              ></span
            >
            <div class="options" v-if="saveExistingDropdown">
              <div class="search" @click.stop>
                <span class="material-icons" @click="closeSave">search</span>
                <input
                  type="text"
                  placeholder="Search template"
                  v-model="saveSearch"
                />
              </div>
              <span
                v-for="template in searchTemplates(saveSearch)"
                :key="template.id"
                @click="saveExistingSelected = template.id"
              >
                {{ template.name }}
              </span>
            </div>
          </div>
          <button @click="saveTemplate(saveExistingSelected)">Save</button>
        </div>
      </div>
    </div>

    <div class="posts-template-load posts-template-fixed" v-if="loadOpen">
      <div class="posts-template-save-container">
        <span class="material-icons close-icon" @click="closeLoad">close</span>
        <h4>Load existing template</h4>
        <div class="posts-existing-templates">
          <div
            class="templates-dropdown"
            @click="loadExistingDropdown = !loadExistingDropdown"
          >
            <span class="selected">
              <span class="name" v-if="!loadExistingSelected"
                >Select template</span
              >
              <span class="name" v-else>{{
                templateNameById(loadExistingSelected)
              }}</span>
              <span
                class="material-icons"
                :class="{ active: loadExistingDropdown }"
                >expand_more</span
              ></span
            >
            <div class="options" v-if="loadExistingDropdown">
              <div class="search" @click.stop>
                <span class="material-icons" @click="closeSave">search</span>
                <input
                  type="text"
                  placeholder="Search template"
                  v-model="loadSearch"
                />
              </div>
              <span
                v-for="template in searchTemplates(loadSearch)"
                :key="template.id"
                @click="loadExistingSelected = template.id"
              >
                {{ template.name }}
              </span>
            </div>
          </div>
          <button @click="loadTemplate(loadExistingSelected)">Load</button>
        </div>
      </div>
    </div>

    <button @click="loadOpen = true">Load template</button>
    <button @click="saveOpen = true">Save as template</button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PostsListing",
  data() {
    return {
      saveOpen: false,
      loadOpen: false,
      loadExistingDropdown: false,
      loadExistingSelected: null,
      saveExistingDropdown: false,
      newTemplateName: "",
      saveExistingSelected: null,
      loadSearch: "",
      saveSearch: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      showOnlyOwnPosts: (state) => state.notifications.showOnlyOwnPosts,
      templates: (state) => state.notifications.templates,
      blocks: (state) => state.notifications.blocks,
    }),
    ...mapGetters("users", ["getUserNameById", "getCompanyNameByUserId"]),
    ...mapGetters("notifications", [
      "templateNameById",
      "finalUsers",
      "searchTemplates",
    ]),
  },
  methods: {
    saveTemplate: function (id) {
      let name;

      if (id !== null && id !== false) {
        name = this.templateNameById(id);
      } else {
        name = this.newTemplateName.trim();
      }

      if (name === "" && id === null) {
        return false;
      }

      this.loading = true;
      this.saveExistingDropdown = false;
      this.$store
        .dispatch("notifications/saveTemplate", {
          id: id,
          name: name,
        })
        .then(() => {
          if (id === false) {
            this.$toast.center(
              "Successfully created template <span>" +
                this.newTemplateName +
                "</span>"
            );
          } else {
            this.$toast.center(
              "Successfully overwrote template <span>" + name + "</span>"
            );
            this.saveExistingSelected = null;
          }
          this.saveOpen = false;
          this.newTemplateName = "";
          this.saveSearch = "";
          this.loading = false;
        });
    },
    loadTemplate: function (id) {
      if (id === null) {
        return false;
      }

      this.loadExistingDropdown = false;

      this.$store.dispatch("notifications/loadTemplate", id).then(() => {
        this.$toast.center(
          "Loaded template <span>" + this.templateNameById(id) + "</span>"
        );
        this.loadExistingSelected = null;
        this.loadOpen = false;
        this.loadSearch = "";
      });
    },
    closeSave: function () {
      this.saveOpen = false;
      this.saveExistingSelected = null;
      this.saveExistingDropdown = false;
      this.newTemplateName = "";
      this.saveSearch = "";
    },
    closeLoad: function () {
      this.loadOpen = false;
      this.loadExistingDropdown = false;
      this.loadExistingSelected = null;
      this.loadSearch = "";
    },
  },
};
</script>
