<template>
  <!-- Selection list -->
  <div class="notifications-selected-items">
    <div
      v-if="
        selectedCompanyCategories.length > 0 &&
        selectedCompanyCategories.length ===
          Object.keys(companyCategories).length
      "
    >
      <b>Company categories: </b><br />
      <span> All categories are selected. </span>
    </div>
    <div v-else-if="selectedCompanyCategories.length > 0">
      <b>Company categories: </b><br />
      <span v-for="item in selectedCompanyCategories" :key="'selected-' + item">
        {{ companyCategories[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'CompanyCategories',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="selectedPriceGroups1.length > 0">
      <b>{{ priceGroup1Name }}: </b><br />
      <span v-for="item in selectedPriceGroups1" :key="'selected-' + item">
        {{ priceGroups1[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'PriceGroups1',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="selectedPriceGroups2.length > 0">
      <b>{{ priceGroup2Name }} </b><br />
      <span v-for="item in selectedPriceGroups2" :key="'selected-' + item">
        {{ priceGroups2[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'PriceGroups2',
              value: item,
            })
          "
          >close</span
        >
        <br />
      </span>
    </div>

    <div v-if="selectedPriceGroups3.length > 0">
      <b>{{ priceGroup3Name }}: </b><br />
      <span v-for="item in selectedPriceGroups3" :key="'selected-' + item">
        {{ priceGroups3[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'PriceGroups3',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="selectedRoutes.length > 0">
      <b>Routes: </b><br />
      <span v-for="item in selectedRoutes" :key="'selected-route-' + item">
        {{ routes[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'Routes',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="excludedPriceGroups1.length > 0">
      <b>Excluded {{ priceGroup1Name }}: </b><br />
      <span v-for="item in excludedPriceGroups1" :key="'excluded-' + item">
        {{ priceGroups1[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateExclusion', {
              type: 'remove',
              field: 'PriceGroups1',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="excludedPriceGroups2.length > 0">
      <b>Excluded {{ priceGroup2Name }}: </b><br />
      <span v-for="item in excludedPriceGroups2" :key="'excluded-' + item">
        {{ priceGroups2[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateExclusion', {
              type: 'remove',
              field: 'PriceGroups2',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="excludedPriceGroups3.length > 0">
      <b>Excluded {{ priceGroup3Name }}: </b><br />
      <span v-for="item in excludedPriceGroups3" :key="'excluded-' + item">
        {{ priceGroups3[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateExclusion', {
              type: 'remove',
              field: 'PriceGroups3',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="excludedRoutes.length > 0">
      <b>Excluded routes: </b><br />
      <span v-for="item in excludedRoutes" :key="'excluded-route-' + item">
        {{ routes[item] }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateExclusion', {
              type: 'remove',
              field: 'Routes',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="selectedCompanies.length > 0">
      <b>Companies: </b><br />
      <span v-for="item in selectedCompanies" :key="'selected-' + item">
        <div v-if="getCompanyByVismaId(item)">
          {{ getCompanyByVismaId(item).name }}
        </div>
        <div v-else>Deleted company: {{ item }}</div>
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'Companies',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="selectedSalesAdmins.length > 0">
      <b>Filter by sales admin: </b><br />
      <span v-for="item in selectedSalesAdmins" :key="'selected-' + item">
        {{ getUserNameByVismaId(item) }}
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateSelection', {
              type: 'remove',
              field: 'SalesAdmins',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <div v-if="excludedCompanies.length > 0">
      <b>Excluded companies: </b><br />
      <span v-for="item in excludedCompanies" :key="'selected-' + item">
        <div v-if="getCompanyByVismaId(item) !== undefined">
          {{ getCompanyByVismaId(item).name }}
        </div>
        <div v-else>Deleted company: {{ item }}</div>
        <span
          class="material-icons"
          @click="
            $store.dispatch('chat/updateExclusion', {
              type: 'remove',
              field: 'Companies',
              value: item,
            })
          "
          >close</span
        >
      </span>
    </div>

    <!-- TODO -->
    <!-- <div v-if="finalCompanies.length === 0" class="no-matches">
      <span>No company matches selection</span>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ChatFilterSelections",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    console.log(this.priceGroups1);
  },
  computed: {
    ...mapState({
      companyCategories: (state) => state.settings.taxonomies.categories,
      selectedCompanyCategories: (state) =>
        state.chat.selectedCompanyCategories,
      selectedPriceGroups1: (state) => state.chat.selectedPriceGroups1,
      selectedPriceGroups2: (state) => state.chat.selectedPriceGroups2,
      selectedPriceGroups3: (state) => state.chat.selectedPriceGroups3,
      selectedRoutes: (state) => state.chat.selectedRoutes,
      excludedPriceGroups1: (state) => state.chat.excludedPriceGroups1,
      excludedPriceGroups2: (state) => state.chat.excludedPriceGroups2,
      excludedPriceGroups3: (state) => state.chat.excludedPriceGroups3,
      excludedRoutes: (state) => state.chat.excludedRoutes,
      selectedCompanies: (state) => state.chat.selectedCompanies,
      selectedSalesAdmins: (state) => state.chat.selectedSalesAdmins,
      excludedCompanies: (state) => state.chat.excludedCompanies,
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      priceGroups3: (state) => state.settings.taxonomies.priceGroup3,
      routes: (state) => state.settings.taxonomies.routes,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("users", ["getUserNameByVismaId"]),
    ...mapGetters("chat", ["finalCompanies"]),
    ...mapGetters("settings", [
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
    ]),
  },
};
</script>
