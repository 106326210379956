<template>
  <div :class="[{ active: init }, appBrand]" id="app">
    <Sidebar v-if="init" />

    <div :class="$route.path.replace(/\//g, '')" id="view-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
// import { db } from './main'
import { mapState, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      init: (state) => state.settings.init,
      appBrand: (state) => state.settings.appSettings.app,
      notifications: (state) => state.chat.notifications,
      loggedInVismaId: (state) => state.settings.user.vismaId,
    }),
    ...mapGetters("chat", ["getChatRoomById"]),
  },
  created() {
    if (
      window.location.hostname.includes("fiskerikajen") ||
      (window.location.hostname.includes("localhost") &&
        this.$cookies.get("brand") === "fiskerikajen")
    ) {
      document.title = "Fiskerikajen Admin";
    } else if (
      window.location.hostname.includes("kvalitetsfisk") ||
      (window.location.hostname.includes("localhost") &&
        this.$cookies.get("brand") === "kvalitetsfisk")
    ) {
      document.title = "Kvalitetsfisk Admin";
    } else if (
      window.location.hostname.includes("fiskidag") ||
      (window.location.hostname.includes("localhost") &&
        this.$cookies.get("brand") === "fiskidag")
    ) {
      document.title = "Fisk Idag Admin";
    } else if (
      window.location.hostname.includes("fsgfoods") ||
      (window.location.hostname.includes("localhost") &&
        this.$cookies.get("brand") === "fsg")
    ) {
      document.title = "FSG Admin";
    }
  },
  watch: {
    notifications(newVal, oldVal) {
      // Dont run on page load.
      if (Object.keys(oldVal).length === 0) {
        return;
      }

      let oldCount = 0;
      let newCount = 0;

      let oldKeys = [];
      let newKeys = [];

      for (const [key, value] of Object.entries(oldVal)) {
        oldCount += value.count;
        oldKeys.push(key);
      }

      for (const [key, value] of Object.entries(newVal)) {
        newCount += value.count;
        newKeys.push(key);
      }

      // Notification has been cleared; do nothing.
      if (oldCount >= newCount) {
        return;
      }

      let chatRoomId = null;

      // If message is from a new room
      if (newKeys.length > oldKeys.length) {
        let diff = newKeys.filter((x) => !oldKeys.includes(x));

        chatRoomId = diff[0];
      } else {
        newKeys.forEach((key) => {
          if (newVal[key].count > oldVal[key].count) {
            chatRoomId = key;
          }
        });
      }

      console.log(chatRoomId);

      const chatRoom = this.getChatRoomById(chatRoomId);

      // Couldn't find chat room.
      if (!chatRoom) {
        return;
      }

      // Only send notification to owner.
      if (chatRoom.accountOwnerVismaId === this.loggedInVismaId) {
        const notification = {
          title: "New chat message",
          options: {
            body: chatRoom.name,
          },
          events: {
            onclick: () => {
              this.$store.dispatch("chat/bindChatRoom", chatRoomId).then(() => {
                this.$store
                  .dispatch("chat/removeNotification", chatRoomId)
                  .then(() => {
                    if (this.$router.currentRoute.name !== "Chat") {
                      this.$router.push({ name: "Chat" });
                    }
                  });
              });
            },
          },
        };

        this.$notification.show(
          notification.title,
          notification.options,
          notification.events
        );
      }
    },
    // },
  },
};
</script>
