<template>
  <div
    v-if="lightboxImage !== null"
    class="image-lightbox"
    @click="$store.commit('chat/setLightboxImage', null)"
  >
    <img :src="lightboxImage" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
// let audio = new Audio(require('@/assets/message.mp3'))

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "ChatLightbox",
  data() {
    return {};
  },
  components: {
    simplebar,
  },
  computed: {
    ...mapState({
      lightboxImage: (state) => state.chat.lightboxImage,
    }),
  },
  created() {
    console.log(this.lightboxImage);
  },
  methods: {},
};
</script>
