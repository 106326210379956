<template>
  <div class="shortlists-product-selection-container">
    <div class="shortlists-products-outer">
      <div class="shortlists-products-display">
        <h4>Products</h4>
        <div class="shortlists-products-inner">
          <div
            v-for="product in sortedProductsWithData"
            :key="'display-' + product.firebaseId"
          >
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="Object.keys(products).includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
          </div>
        </div>
      </div>

      <div class="shortlists-products-search">
        <input type="search" placeholder="Search product..." v-model="search" />
        <div class="shortlist-search-container">
          <div v-for="product in getProductsBySearch(search)" :key="product.id">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="Object.keys(products).includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
            <span
              v-else
              class="action add"
              @click="productAdd(product.firebaseId)"
              >Add</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompanyShortlistsProductSelection",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.companyShortlists.products,
    }),
    ...mapGetters("products", ["getProductsBySearch"]),
    ...mapGetters("companyShortlists", ["sortedProductsWithData"]),
  },
  components: {},
  methods: {
    productRemove: function (firebaseId) {
      let newProducts = JSON.parse(JSON.stringify(this.products));
      delete newProducts[firebaseId];
      this.$store.commit("companyShortlists/setProducts", newProducts);
    },
    productAdd: function (firebaseId) {
      let newProducts = JSON.parse(JSON.stringify(this.products));
      console.log(newProducts);
      newProducts[firebaseId] = true;
      console.log(newProducts);
      this.$store.commit("companyShortlists/setProducts", newProducts);
    },
  },
};
</script>
