<template>
  <div
    style="min-width: 100%"
    class="shortlists-container upsell-container"
    v-if="init"
  >
    <UpsellSetListsConfirm v-if="setListsConfirm" />

    <!-- Left sidebar -->
    <div class="shortlists-selection">
      <button @click="createUpsellList">
        <span class="material-icons">add</span>
        Create new upsell list
      </button>

      <!-- Existing shortlists -->
      <div class="upsell-lists-selectors-wrapper">
        <UpsellListSelector type="cart" />

        <UpsellListSelector type="home" />
      </div>
    </div>

    <!--- Edit shortlist -->
    <div
      class="shortlist-edit"
      v-if="selectedUpsellList !== null && setListsConfirm === false"
    >
      <!-- Header -- Name + Delete -->
      <UpsellListHeader />

      <div class="shortlists-main-area">
        <UpsellProductSelection />
      </div>

      <UpsellEditFooter />
    </div>

    <div
      v-if="selectedUpsellList === null"
      class="cart-upsell-company-selection"
    >
      <UpsellCompanies />
      <UpsellCompanyFilters />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CartUpsell",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedUpsellList: (state) => state.cartUpsell.selectedUpsellList,
      loading: (state) => state.cartUpsell.loading,
      currentStep: (state) => state.cartUpsell.currentStep,
      setListsConfirm: (state) => state.cartUpsell.setListsConfirm,
      init: (state) => state.cartUpsell.init,
    }),
  },
  async created() {
    if (!this.init) {
      await this.$store.dispatch("cartUpsell/bindUpsellLists");
      this.$store.commit("cartUpsell/setInit", true);
    }
  },
  async beforeDestroy() {
    this.$store.dispatch("cartUpsell/unbindUpsellLists");
    this.$store.commit("cartUpsell/setInit", false);
  },
  components: {},
  methods: {
    createUpsellList: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.commit("cartUpsell/setSelectedCompanies", []);
      this.$store.commit("cartUpsell/setLoading", true);

      this.$store.dispatch("cartUpsell/createUpsellList").then(() => {
        this.$store.commit("cartUpsell/setLoading", false);
      });
    },
  },
};
</script>
