<template>
  <div class="web-pages-editor">
    <!-- Header top logged in-->
    <div class="web-pages-menu-editor">
      <h2>Header top menu logged in</h2>
      <div
        v-if="Object.keys(editingTopMenuLoggedIn).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingTopMenuLoggedIn[lang.code]"
              :key="
                'lang-' + lang.code + '-top-logged-in-menu-item-' + menuIndex
              "
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingTopMenuLoggedIn',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingTopMenuLoggedIn',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Header top logged out-->
    <div class="web-pages-menu-editor">
      <h2>Header top menu logged out</h2>
      <div
        v-if="Object.keys(editingTopMenuLoggedOut).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingTopMenuLoggedOut[
                lang.code
              ]"
              :key="
                'lang-' + lang.code + '-top-logged-ut-menu-item-' + menuIndex
              "
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingTopMenuLoggedOut',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingTopMenuLoggedOut',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Header bot logged in -->
    <div class="web-pages-menu-editor">
      <h2>Header bottom menu logged in</h2>
      <div
        v-if="Object.keys(editingTopMenuLoggedIn).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingBotMenuLoggedIn[lang.code]"
              :key="
                'lang-' + lang.code + '-bot-logged-in-menu-item-' + menuIndex
              "
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingBotMenuLoggedIn',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingBotMenuLoggedIn',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Header bot logged out -->
    <div class="web-pages-menu-editor">
      <h2>Header bottom menu logged out</h2>
      <div
        v-if="Object.keys(editingBotMenuLoggedOut).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingBotMenuLoggedOut[
                lang.code
              ]"
              :key="
                'lang-' + lang.code + '-bot-logged-ut-menu-item-' + menuIndex
              "
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingBotMenuLoggedOut',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingBotMenuLoggedOut',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer menu 1 -->
    <div class="web-pages-menu-editor">
      <h2>Footer menu 1</h2>
      <div
        v-if="Object.keys(editingFooterMenu1).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingFooterMenu1[lang.code]"
              :key="'lang-' + lang.code + '-footer-1-menu-item-' + menuIndex"
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu1',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu1',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer menu 2 -->
    <div class="web-pages-menu-editor">
      <h2>Footer menu 2</h2>
      <div
        v-if="Object.keys(editingFooterMenu2).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingFooterMenu2[lang.code]"
              :key="'lang-' + lang.code + '-footer-2-menu-item-' + menuIndex"
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu2',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu2',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer menu 3 -->
    <div class="web-pages-menu-editor">
      <h2>Footer menu 3</h2>
      <div
        v-if="Object.keys(editingFooterMenu3).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingFooterMenu3[lang.code]"
              :key="'lang-' + lang.code + '-footer-3-menu-item-' + menuIndex"
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu3',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenu3',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Foter menu bot -->
    <div class="web-pages-menu-editor">
      <h2>Footer menu bottom</h2>
      <div
        v-if="Object.keys(editingFooterMenuBot).length > 0"
        class="web-pages-hero-editing web-pages-editing-block"
      >
        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, langIndex) in enabledLanguages"
            :key="'lang-' + langIndex"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div
              v-for="(menuItem, menuIndex) in editingFooterMenuBot[lang.code]"
              :key="'lang-' + lang.code + '-footer-bot-menu-item-' + menuIndex"
              class="web-pages-menu-group"
            >
              <div class="form-input">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  :value="menuItem.text"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenuBot',
                      key: lang.code + '.' + menuIndex + '.text',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
              <div class="form-input">
                <label>Url</label>
                <input
                  type="text"
                  placeholder="Url"
                  :value="menuItem.url"
                  @input="
                    $store.commit('webPages/updateMenu', {
                      type: 'editingFooterMenuBot',
                      key: lang.code + '.' + menuIndex + '.url',
                      value: $event.target.value,
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <WebPagesEditorActions />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "WebPagesMenuEditor",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      webPages: (state) => state.webPages.webPages,
      editingBotMenuLoggedIn: (state) => state.webPages.editingBotMenuLoggedIn,
      editingBotMenuLoggedOut: (state) =>
        state.webPages.editingBotMenuLoggedOut,
      editingTopMenuLoggedIn: (state) => state.webPages.editingTopMenuLoggedIn,
      editingTopMenuLoggedOut: (state) =>
        state.webPages.editingTopMenuLoggedOut,
      editingFooterMenu1: (state) => state.webPages.editingFooterMenu1,
      editingFooterMenu2: (state) => state.webPages.editingFooterMenu2,
      editingFooterMenu3: (state) => state.webPages.editingFooterMenu3,
      editingFooterMenuBot: (state) => state.webPages.editingFooterMenuBot,
    }),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
  created() {
    this.$store.dispatch("webPages/setupEditingMenus");
    console.log(this.editingTopMenuLoggedIn);
  },
};
</script>
