var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-editor"},[_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Header top menu logged in")]),(Object.keys(_vm.editingTopMenuLoggedIn).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingTopMenuLoggedIn[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-top-logged-in-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingTopMenuLoggedIn',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingTopMenuLoggedIn',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Header top menu logged out")]),(Object.keys(_vm.editingTopMenuLoggedOut).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingTopMenuLoggedOut[
              lang.code
            ]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-top-logged-ut-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingTopMenuLoggedOut',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingTopMenuLoggedOut',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Header bottom menu logged in")]),(Object.keys(_vm.editingTopMenuLoggedIn).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingBotMenuLoggedIn[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-bot-logged-in-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingBotMenuLoggedIn',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingBotMenuLoggedIn',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Header bottom menu logged out")]),(Object.keys(_vm.editingBotMenuLoggedOut).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingBotMenuLoggedOut[
              lang.code
            ]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-bot-logged-ut-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingBotMenuLoggedOut',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingBotMenuLoggedOut',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Footer menu 1")]),(Object.keys(_vm.editingFooterMenu1).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingFooterMenu1[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-footer-1-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu1',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu1',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Footer menu 2")]),(Object.keys(_vm.editingFooterMenu2).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingFooterMenu2[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-footer-2-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu2',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu2',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Footer menu 3")]),(Object.keys(_vm.editingFooterMenu3).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingFooterMenu3[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-footer-3-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu3',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenu3',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('div',{staticClass:"web-pages-menu-editor"},[_c('h2',[_vm._v("Footer menu bottom")]),(Object.keys(_vm.editingFooterMenuBot).length > 0)?_c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_vm._l((_vm.editingFooterMenuBot[lang.code]),function(menuItem,menuIndex){return _c('div',{key:'lang-' + lang.code + '-footer-bot-menu-item-' + menuIndex,staticClass:"web-pages-menu-group"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":menuItem.text},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenuBot',
                    key: lang.code + '.' + menuIndex + '.text',
                    value: $event.target.value,
                  })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Url")]),_c('input',{attrs:{"type":"text","placeholder":"Url"},domProps:{"value":menuItem.url},on:{"input":function($event){return _vm.$store.commit('webPages/updateMenu', {
                    type: 'editingFooterMenuBot',
                    key: lang.code + '.' + menuIndex + '.url',
                    value: $event.target.value,
                  })}}})])])})],2)}),0)]):_vm._e()]),_c('WebPagesEditorActions')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }