import { db } from "../main.js";
// import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import { nanoid } from "nanoid";

const categories = {
  namespaced: true,
  state: {
    editingCategoryId: null,
    editingIcon: null,
    editingCategories: null,
    editingExtraCategories: null,
    editingLocations: null,
    editingSegments: null,
    editingTranslations: null,
    editingMode: null,
    editingProcess: "",
  },
  mutations: {
    setEditingCategoryId(state, data) {
      state.editingCategoryId = data;
    },
    setEditingIcon(state, data) {
      state.editingIcon = data;
    },
    setEditingCategories(state, data) {
      state.editingCategories = data;
    },
    setEditingExtraCategories(state, data) {
      state.editingExtraCategories = data;
    },
    setEditingLocations(state, data) {
      state.editingLocations = data;
    },
    setEditingSegments(state, data) {
      state.editingSegments = data;
    },
    setEditingTranslations(state, data) {
      state.editingTranslations = data;
    },
    setEditingMode(state, data) {
      state.editingMode = data;
    },
    addEditingCategory(state, data) {
      if (state.editingCategories.includes(data)) {
        return;
      }

      state.editingCategories.push(data);
    },
    removeEditingCategory(state, data) {
      state.editingCategories = state.editingCategories.filter(
        (category) => category !== data
      );
    },
    addEditingExtraCategory(state, data) {
      if (state.editingExtraCategories.includes(data)) {
        return;
      }

      state.editingExtraCategories.push(data);
    },
    removeEditingExtraCategory(state, data) {
      state.editingExtraCategories = state.editingExtraCategories.filter(
        (category) => category !== data
      );
    },
    addEditingSegment(state, data) {
      if (state.editingSegments.includes(data)) {
        return;
      }

      state.editingSegments.push(data);
    },
    removeEditingSegment(state, data) {
      state.editingSegments = state.editingSegments.filter(
        (segment) => segment !== data
      );
    },
    toggleEditingLocation(state, data) {
      state.editingLocations[data] = !state.editingLocations[data];
    },
    updateCategoryName(state, data) {
      state.editingTranslations[data.lang] = data.value;
    },
    setEditingProcess(state, data) {
      state.editingProcess = data;
    },
  },
  getters: {
    sortableCategoryGroups: (state, getters, rootState) => {
      let categoryGroupsOrder =
        rootState.settings.appHelpers.categoryGroupsOrder;

      // Sort by object value
      let sorted = Object.keys(categoryGroupsOrder).sort(function (a, b) {
        return categoryGroupsOrder[a] - categoryGroupsOrder[b];
      });

      return sorted;
    },
    sortableCategoryGroupsWithData: (state, getters, rootState) => {
      let categoryGroups = getters.sortableCategoryGroups;
      let returnArr = [];

      categoryGroups.forEach((groupId) => {
        const group =
          rootState.settings.productSettings.categoryGroups[groupId];

        if (group !== undefined) {
          group.id = groupId;
          returnArr.push(group);
        }
      });

      return returnArr;
    },
    selectedEditingCategoriesNames(state, getters, rootState, rootGetters) {
      if (
        state.editingCategories === null ||
        state.editingCategories.length === 0
      ) {
        return "none";
      }

      return state.editingCategories
        .map((category) =>
          rootGetters["settings/getCategoryNameById"](category)
        )
        .join(", ");
    },
    selectedEditingExtraCategoriesNames(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      if (
        state.editingExtraCategories === null ||
        state.editingExtraCategories.length === 0
      ) {
        return "none";
      }

      return state.editingExtraCategories
        .map((category) =>
          rootGetters["settings/getExtraCategoryNameById"](category)
        )
        .join(", ");
    },
    selectedEditingSegmentsNames(state, getters, rootState, rootGetters) {
      if (
        state.editingSegments === null ||
        state.editingSegments.length === 0
      ) {
        return "none";
      }

      return state.editingSegments
        .map((segment) => rootGetters["settings/getSegmentNameById"](segment))
        .join(", ");
    },
  },
  actions: {
    clearEditing({ commit }) {
      commit("setEditingCategoryId", null);
      commit("setEditingIcon", null);
      commit("setEditingCategories", null);
      commit("setEditingExtraCategories", null);
      commit("setEditingLocations", null);
      commit("setEditingSegments", null);
      commit("setEditingTranslations", null);
      commit("setEditingMode", null);
      commit("setEditingProcess", null);
    },
    initCreateEditing({ commit, rootGetters }) {
      commit("setEditingCategoryId", nanoid());
      commit("setEditingIcon", null);
      commit("setEditingCategories", []);
      commit("setEditingExtraCategories", []);
      commit("setEditingLocations", {
        home: false,
        products: false,
      });
      commit("setEditingSegments", []);
      commit("setEditingProcess", "");

      const languages = rootGetters["languages/enabledLanguages"];
      const translations = {};

      languages.forEach((lang) => {
        translations[lang.code] = "";
      });

      commit("setEditingTranslations", translations);
      commit("setEditingMode", "create");
    },
    initEditEditing({ commit, rootState, rootGetters }, id) {
      commit("setEditingCategoryId", id);

      const data = rootGetters["settings/getCategoryGroupById"](id);

      if (data === undefined) {
        return;
      }

      const translationData =
        rootState.settings.productSettings.categoryGroupsTranslated;

      const languages = rootGetters["languages/enabledLanguages"];
      const translations = {};

      languages.forEach((lang) => {
        translations[lang.code] =
          translationData[lang.code] !== undefined &&
          translationData[lang.code][id] !== undefined
            ? translationData[lang.code][id]
            : "";
      });

      commit("setEditingCategoryId", id);
      commit("setEditingIcon", data.icon);
      commit("setEditingCategories", data.categories);
      commit("setEditingExtraCategories", data.extraCategories);
      commit("setEditingLocations", data.locations);
      commit("setEditingSegments", data.segments);
      commit("setEditingTranslations", translations);
      commit("setEditingProcess", data.process);
      commit("setEditingMode", "edit");
    },
    async saveCategoryGroup({ state, rootState }) {
      const translationData = {};

      const id = state.editingCategoryId;

      const categories = state.editingCategories.map((category) =>
        parseInt(category)
      );

      const segments = state.editingSegments.map((segment) =>
        parseInt(segment)
      );

      console.log(state.editingProcess);

      const groupData = {
        categories: categories,
        extraCategories: state.editingExtraCategories,
        icon: state.editingIcon,
        locations: state.editingLocations,
        segments: segments,
        process: state.editingProcess,
      };

      if (state.editingMode === "create") {
        groupData.createdAt = new Date();
      }

      Object.keys(state.editingTranslations).forEach((lang) => {
        translationData[lang] = {};
        translationData[lang][id] = state.editingTranslations[lang];
      });

      await db.collection("app").doc("productSettings").set(
        {
          categoryGroupsTranslated: translationData,
        },
        { merge: true }
      );

      await db
        .collection("app")
        .doc("productSettings")
        .update({
          ["categoryGroups." + id]: groupData,
        });

      if (
        state.editingLocations.home === true ||
        state.editingLocations.products === true
      ) {
        if (
          rootState.settings.appHelpers.categoryGroupsOrder[id] === undefined
        ) {
          const length = Object.keys(
            rootState.settings.appHelpers.categoryGroupsOrder
          ).length;

          await db
            .collection("app")
            .doc("appHelpers")
            .update({
              ["categoryGroupsOrder." + id]: length,
            });
        }
      } else {
        await db
          .collection("app")
          .doc("appHelpers")
          .update({
            ["categoryGroupsOrder." + id]:
              firebase.firestore.FieldValue.delete(),
          });
      }
    },
    async deleteCategoryGroup({ state, rootState }) {
      const id = state.editingCategoryId;
      const translationData = {};
      Object.keys(state.editingTranslations).forEach((lang) => {
        translationData[lang] = {};
        translationData[lang][id] = firebase.firestore.FieldValue.delete();
      });

      await db.collection("app").doc("productSettings").set(
        {
          categoryGroupsTranslated: translationData,
        },
        { merge: true }
      );

      await db
        .collection("app")
        .doc("productSettings")
        .update({
          ["categoryGroups." + id]: firebase.firestore.FieldValue.delete(),
        });

      if (rootState.settings.appHelpers.categoryGroupsOrder[id] !== undefined) {
        await db
          .collection("app")
          .doc("appHelpers")
          .update({
            ["categoryGroupsOrder." + id]:
              firebase.firestore.FieldValue.delete(),
          });
      }
    },
  },
};
export default categories;
