import { db } from "../main.js";
import { firestoreAction } from "vuexfire";

const orders = {
  namespaced: true,
  state: {
    orders: null,
    singleOrder: null,
    offlineOrders: null,
    init: false,
  },
  mutations: {
    setInit(state, payload) {
      state.init = payload;
    },
  },
  getters: {
    regularOrders: (state) => {
      // Company 123456789 indicates a resolved offline order.
      return state.orders.filter(
        (order) => order.vismaDown !== true && order.company !== 123456789
      );
    },
    offlineOrderCount: (state) => {
      return state.offlineOrders.length;
    },
  },
  actions: {
    bindOrders: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "orders",
        db.collection("orders").orderBy("createdAt", "desc").limit(500)
      );
    }),
    unbindOrders: firestoreAction((context) => {
      return context.unbindFirestoreRef("orders");
    }),
    bindOfflineOrders: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "offlineOrders",
        db
          .collection("orders")
          .where("vismaDown", "==", true)
          .orderBy("createdAt", "desc")
      );
    }),
    bindSingleOrder: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        "singleOrder",
        db.collection("orders").doc(payload.id)
      );
    }),
    resolveOfflineOrder: firestoreAction((context, payload) => {
      console.log(payload);
      if (payload.completion === true) {
        return db.collection("orders").doc(payload.id).update({
          vismaDown: false,
        });
      } else {
        return db.collection("orders").doc(payload.id).update({
          vismaDown: false,
          company: 123456789,
        });
      }
    }),
  },
};
export default orders;
