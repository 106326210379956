import { db } from "./../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const companies = {
  namespaced: true,
  state: {
    init: false,
    singleCompanyInit: false,
    companies: [],
    singleCompany: false,
    singleCompanyUsers: [],
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    setSingleCompanyInit: (state, data) => {
      state.singleCompanyInit = data;
    },
  },
  getters: {
    getCompaniesBySearch: (state) => (searchString) => {
      if (!searchString) {
        return state.companies;
      }
      return state.companies.filter((company) =>
        company.name.toLowerCase().includes(searchString.toLowerCase())
      );
    },
    getCompanyByVismaId: (state) => (id) => {
      return state.companies.find((company) => company.vismaId === id);
    },
    getCompanyByFirebaseId: (state) => (id) => {
      return state.companies.find((company) => company.firebaseId === id);
    },
    getCompanyNameByVismaId: (state) => (id) => {
      var name = "";
      state.companies.forEach((company) => {
        if (company.vismaId === parseInt(id)) {
          name = company.name;
        }
      });
      return name;
    },
    getCompanyAccountOwnerByVismaId: (state) => (id) => {
      var accountOwnerVismaId = 0;

      const company = state.companies.find(
        (company) => company.vismaId === parseInt(id)
      );

      if (company !== undefined) {
        accountOwnerVismaId = company.accountOwnerVismaId;
      }

      return accountOwnerVismaId;
    },
    getCompanyNameById: (state) => (id) => {
      var name = "";
      state.companies.forEach((company) => {
        if (company.id === id) {
          name = company.name;
        }
      });
      return name;
    },
    companyCount: (state) => {
      return state.companies.length;
    },
    singleCompanySharedShortlists: (state) => {
      const returnArr = [];
      Object.entries(state.singleCompany[0].shortlists).map((list) => {
        const listId = list[0];
        const listData = list[1];

        if (listData.type == "shared") {
          returnArr.push({
            id: listId,
            name: listData.name,
          });
        }

        return;
      });
      return returnArr;
    },
    singleCompanyCompanyShortlists: (state) => {
      const returnArr = [];
      Object.entries(state.singleCompany[0].shortlists).map((list) => {
        const listId = list[0];
        const listData = list[1];

        if (listData.type == "company") {
          returnArr.push({
            id: listId,
            name: listData.name,
          });
        }

        return;
      });
      return returnArr;
    },
    singleCompanyProducts: (state, getters, rootState) => {
      const companyData = state.singleCompany[0];

      const companyPriceList = rootState.priceLists.selectedPriceList;
      const productList = rootState.products.productList;

      const shownProducts = [];

      console.log(companyPriceList);
      console.log(productList);

      productList.products.forEach((product) => {
        if (companyPriceList.products[product.vismaId] !== undefined) {
          product.price = 0;

          if (
            companyData.productsPrice === 3 ||
            companyData.productsPrice === 4
          ) {
            const discount =
              1 - companyPriceList.products[product.vismaId].discount / 100;
            const basePrice = companyPriceList.products[product.vismaId].price;
            product.price = basePrice * discount;
          }

          shownProducts.push(product);
        }
      });

      return shownProducts;
    },
    singleCompanyProductPriceString: (state) => {
      let returnString = "";

      switch (state.singleCompany[0].productsPrice) {
        case 0:
          returnString = "0";
          break;
        case 1:
          returnString = "1 - All products, no prices";
          break;
        case 2:
          returnString = "2 - Own products, no prices";
          break;
        case 3:
          returnString = "3 - All products, prices";
          break;
        case 4:
          returnString = "4 - Own products, prices";
          break;
        default:
          returnString = "0";
      }

      return returnString;
    },
    companiesWithUpsellSorting: (state) => {
      const noUpsell = state.companies.filter(
        (company) => company.upsell === undefined || company.upsell === false
      );

      const withUpsell = state.companies.filter(
        (company) => company.upsell !== undefined && company.upsell !== false
      );

      return [...noUpsell, ...withUpsell];
    },
  },
  actions: {
    bindCompanies: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "companies",
        db.collection("companies").orderBy("name")
      );
    }),
    bindSingleCompany: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        "singleCompany",
        db.collection("companies").where("vismaId", "==", payload.id)
      );
    }),
    unbindSingleCompany: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("singleCompany");
    }),
    bindSingleCompanyUsers: firestoreAction(({ bindFirestoreRef }, payload) => {
      return bindFirestoreRef(
        "singleCompanyUsers",
        db.collection("users").where("companyId", "==", payload.id)
      );
    }),
    unbindSingleCompanyUsers: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("singleCompanyUsers");
    }),
    shortlistAdd: firestoreAction((context, payload) => {
      console.log(context);
      console.log(payload);
      return db
        .collection("companies")
        .doc(payload.companyId)
        .update({
          ["shortlist." + payload.productId]: true,
        });
    }),
    shortlistRemove: firestoreAction((context, payload) => {
      console.log(context);
      console.log(payload);
      console.log(db.FieldValue);
      return db
        .collection("companies")
        .doc(payload.companyId)
        .update({
          ["shortlist." + payload.productId]:
            firebase.firestore.FieldValue.delete(),
        });
    }),
  },
};
export default companies;
