<template>
  <div class="notifications-group-select">
    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">Company Category</span>
      </div>
      <div class="inner">
        <div v-for="(value, key) in companyCategories" :key="'cat-' + key">
          <span>{{ value }}</span>
          <div class="actions">
            <span
              v-if="selectedCompanyCategories.indexOf(key) === -1"
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'CompanyCategories',
                  value: key,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'CompanyCategories',
                  value: key,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup1Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups1"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="
                  excludedPriceGroups1.indexOf(key) === -1 &&
                  selectedPriceGroups1.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'add',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedPriceGroups1.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'remove',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedPriceGroups1.indexOf(key) === -1 &&
                  excludedPriceGroups1.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'add',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action add"
                >Add</span
              >
              <span
                v-if="selectedPriceGroups1.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'remove',
                    field: 'PriceGroups1',
                    value: key,
                  })
                "
                class="action remove"
                >Remove</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup2Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups2"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>

            <div class="actions">
              <span
                v-if="
                  excludedPriceGroups2.indexOf(key) === -1 &&
                  selectedPriceGroups2.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'add',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedPriceGroups2.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'remove',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedPriceGroups2.indexOf(key) === -1 &&
                  excludedPriceGroups2.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'add',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action add"
              >
                Add
              </span>
              <span
                v-if="selectedPriceGroups2.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'remove',
                    field: 'PriceGroups2',
                    value: key,
                  })
                "
                class="action remove"
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">{{ priceGroup3Name }}</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in priceGroups3"
          :key="'cat-' + key"
          :class="{ hidden: isHidden(key) }"
        >
          <div v-if="key != 'name'">
            <span>{{ value }}</span>
            <div class="actions">
              <span
                v-if="
                  excludedPriceGroups3.indexOf(key) === -1 &&
                  selectedPriceGroups3.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'add',
                    field: 'PriceGroups3',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedPriceGroups3.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'remove',
                    field: 'PriceGroups3',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedPriceGroups3.indexOf(key) === -1 &&
                  excludedPriceGroups3.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'add',
                    field: 'PriceGroups3',
                    value: key,
                  })
                "
                class="action add"
              >
                Add
              </span>
              <span
                v-if="selectedPriceGroups3.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'remove',
                    field: 'PriceGroups3',
                    value: key,
                  })
                "
                class="action remove"
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">Routes</span>
      </div>
      <div class="inner">
        <div v-for="(value, key) in routes" :key="'route-' + key">
          <div>
            <span>{{ value }}</span>
            <div class="actions">
              <span
                v-if="
                  excludedRoutes.indexOf(key) === -1 &&
                  selectedRoutes.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'add',
                    field: 'Routes',
                    value: key,
                  })
                "
                class="action exclude"
                >Exclude</span
              >
              <span
                v-if="excludedRoutes.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateExclusion', {
                    type: 'remove',
                    field: 'Routes',
                    value: key,
                  })
                "
                class="action exclude"
                >Remove exclusion</span
              >
              <span
                v-if="
                  selectedRoutes.indexOf(key) === -1 &&
                  excludedRoutes.indexOf(key) === -1
                "
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'add',
                    field: 'Routes',
                    value: key,
                  })
                "
                class="action add"
              >
                Add
              </span>
              <span
                v-if="selectedRoutes.indexOf(key) !== -1"
                @click="
                  $store.dispatch('notifications/updateSelection', {
                    type: 'remove',
                    field: 'Routes',
                    value: key,
                  })
                "
                class="action remove"
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">Companies</span>
        <div class="company-search">
          <input
            type="text"
            v-model="companySearch"
            placeholder="Search company..."
          />
        </div>
      </div>
      <div class="inner">
        <div
          v-for="(value, key) in getCompaniesBySearch(companySearch)"
          :key="'cat-' + key"
        >
          <span>{{ value.name }}</span>
          <div class="actions">
            <span
              v-if="selectedCompanies.indexOf(value.vismaId) === -1"
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'Companies',
                  value: value.vismaId,
                })
              "
              class="action add"
            >
              Add
            </span>
            <span
              v-else
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'Companies',
                  value: value.vismaId,
                })
              "
              class="action remove"
            >
              Remove
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table company-categories">
      <div class="table-header">
        <span class="category label">Filter by sales admin</span>
      </div>
      <div class="inner">
        <div
          v-for="(value, index) in getAdminAccountOwners"
          :key="'sales-users-' + index"
        >
          <span>{{ value.name }}</span>
          <div class="actions">
            <span
              v-if="selectedSalesAdmins.indexOf(value.vismaId) === -1"
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'add',
                  field: 'SalesAdmins',
                  value: value.vismaId,
                })
              "
              class="action add"
            >
              Add filter
            </span>
            <span
              v-else
              @click="
                $store.dispatch('notifications/updateSelection', {
                  type: 'remove',
                  field: 'SalesAdmins',
                  value: value.vismaId,
                })
              "
              class="action remove"
            >
              Remove filter
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PostsSelectionBoxes",
  data() {
    return {
      companySearch: "",
    };
  },
  methods: {
    isHidden: (key) => (key == "name" ? true : false),
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies.companies,
      companyCategories: (state) => state.settings.taxonomies.categories,
      selectedCompanyCategories: (state) =>
        state.notifications.selectedCompanyCategories,
      selectedPriceGroups1: (state) => state.notifications.selectedPriceGroups1,
      selectedPriceGroups2: (state) => state.notifications.selectedPriceGroups2,
      selectedPriceGroups3: (state) => state.notifications.selectedPriceGroups3,
      selectedRoutes: (state) => state.notifications.selectedRoutes,
      excludedPriceGroups1: (state) => state.notifications.excludedPriceGroups1,
      excludedPriceGroups2: (state) => state.notifications.excludedPriceGroups2,
      excludedPriceGroups3: (state) => state.notifications.excludedPriceGroups3,
      excludedRoutes: (state) => state.notifications.excludedRoutes,
      selectedCompanies: (state) => state.notifications.selectedCompanies,
      selectedSalesAdmins: (state) => state.notifications.selectedSalesAdmins,
      excludedCompanies: (state) => state.notifications.excludedCompanies,
      priceGroups1: (state) => state.settings.taxonomies.priceGroup1,
      priceGroups2: (state) => state.settings.taxonomies.priceGroup2,
      priceGroups3: (state) => state.settings.taxonomies.priceGroup3,
      routes: (state) => state.settings.taxonomies.routes,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId", "getCompaniesBySearch"]),
    ...mapGetters("users", ["getUserNameByVismaId", "getAdminAccountOwners"]),
    ...mapGetters("settings", [
      "priceGroup1Name",
      "priceGroup2Name",
      "priceGroup3Name",
    ]),
  },
};
</script>

<style lang="scss">
.hidden {
  display: none !important;
}
</style>
