<template>
  <div class="web-pages-list-wrapper">
    <button
      class="add-new-button"
      @click="$store.commit('webPages/setEditing', 'create')"
    >
      Create new page
    </button>
    <div class="web-pages-list">
      <div
        v-for="webPage in webPages"
        :key="webPage.id"
        class="single-web-page-link"
        @click="$store.commit('webPages/setEditing', webPage.id)"
      >
        <div class="title-slug">
          <span class="slug">{{ webPage.id }}</span
          ><span class="title">{{ webPage.metaTitle }}</span>
        </div>
        <p class="date">
          {{ webPage.updated.toDate() | moment("from", "now") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WebPagesList",
  data() {
    return {};
  },

  computed: {
    ...mapState({
      webPages: (state) => state.webPages.webPages,
    }),
  },
  mounted() {},
  methods: {},
};
</script>
