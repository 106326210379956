var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortlists-final-companies"},[_c('h4',[_vm._v("Selected companies - "+_vm._s(_vm.finalCompanies.length))]),_c('div',{staticClass:"inner-selected"},_vm._l((_vm.finalCompanies),function(company){return _c('span',{key:company.firebaseId + company.vismaId},[_vm._v(" "+_vm._s(_vm.getCompanyByVismaId(company.vismaId).name)+" "),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
            type: 'add',
            field: 'Exclusion',
            value: company.vismaId,
          })}}},[_vm._v("close")])])}),0),(_vm.excludedCompanies.length)?_c('div',{staticClass:"inner-excluded"},[_c('h4',[_vm._v("Excluded companies")]),_c('div',{staticClass:"inner"},_vm._l((_vm.excludedCompanies),function(company){return _c('span',{key:'excluded-' + company},[(_vm.getCompanyByVismaId(company) !== undefined)?_c('span',[_vm._v(_vm._s(_vm.getCompanyByVismaId(company).name))]):_c('span',[_vm._v("Deleted company: "+_vm._s(company))]),_c('span',{staticClass:"material-icons",on:{"click":function($event){return _vm.$store.dispatch('sharedShortlists/updateSelection', {
              type: 'remove',
              field: 'Exclusion',
              value: company,
            })}}},[_vm._v("close")])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }