import { db } from "./../main.js";
import { vuexfireMutations, firestoreAction } from "vuexfire";

const products = {
  namespaced: true,
  state: {
    productList: [],
  },
  mutations: vuexfireMutations,
  getters: {
    products: (state) => {
      return state.productList.products;
    },
    getProductsBySearch: (state, getters) => (searchString) => {
      if (!searchString) {
        return getters.products;
      }
      return getters.products.filter(
        (product) =>
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.vismaId.includes(searchString.toLowerCase())
      );
    },
    productCount: (state, getters) => {
      return getters.products.length;
    },
    getShortlistProducts: (state, getters) => (products) => {
      return getters.products.filter((product) =>
        Object.keys(products).includes(product.firebaseId)
      );
    },
    getProductByFirebaseId: (state, getters) => (id) => {
      let returnVal = getters.products.find(
        (product) => product.firebaseId === id
      );

      if (returnVal === undefined) {
        returnVal = {
          name: "Deleted product",
          description: "",
          image: "",
          sku: "",
        };
      }

      return returnVal;
    },
    getProductByVismaId: (state, getters) => (id) => {
      return getters.products.find((product) => product.vismaId === id);
    },
  },
  actions: {
    bindProductList: firestoreAction((context) => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef(
        "productList",
        db.collection("app").doc("productList")
      );
    }),
  },
};
export default products;
