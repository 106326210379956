var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_vm._l((_vm.selectedBlock.groups),function(group,groupIndex){return _c('div',{key:'group-' + groupIndex},[_c('h2',{staticClass:"web-pages-group-title",staticStyle:{"margin-bottom":"15px"}},[_vm._v(" Group "+_vm._s(groupIndex + 1)+" "),_c('span',{staticClass:"material-icons delete-icon",on:{"click":function($event){return _vm.deleteCoworkerGroup(groupIndex)}}},[_vm._v("delete")])]),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + groupIndex + '-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].title[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'groups.' + groupIndex + '.title.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })}}})])])}),0),_c('h2',[_vm._v("Coworkers")]),_vm._l((group.coworkers),function(coworker,coworkerIndex){return _c('div',{key:'coworker-' + groupIndex + '-' + coworkerIndex,staticClass:"single-coworker"},[_c('div',{staticClass:"single-coworker-actions"},[_c('span',{staticClass:"material-icons delete-icon",on:{"click":function($event){return _vm.deleteCoworker(groupIndex, coworkerIndex)}}},[_vm._v("delete")]),_c('span',{staticClass:"material-icons move-icon",class:{ disabled: coworkerIndex === 0 },on:{"click":function($event){return _vm.$store.commit('webPages/moveCoworkerUp', {
              groupIndex,
              coworkerIndex,
            })}}},[_vm._v("arrow_upward")]),_c('span',{staticClass:"material-icons move-icon",class:{ disabled: coworkerIndex === group.coworkers.length - 1 },on:{"click":function($event){return _vm.$store.commit('webPages/moveCoworkerDown', {
              groupIndex,
              coworkerIndex,
            })}}},[_vm._v("arrow_downward")])]),_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Name")]),_c('input',{attrs:{"type":"text","placeholder":"Name"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].coworkers[coworkerIndex].name},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key:
                  'groups.' +
                  groupIndex +
                  '.coworkers.' +
                  coworkerIndex +
                  '.name',
                type: 'text',
                value: $event.target.value,
              })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Image url")]),_c('input',{attrs:{"type":"text","placeholder":"Image url"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].coworkers[coworkerIndex]
                .imageUrl},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key:
                  'groups.' +
                  groupIndex +
                  '.coworkers.' +
                  coworkerIndex +
                  '.imageUrl',
                type: 'trim',
                value: $event.target.value,
              })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Email")]),_c('input',{attrs:{"type":"text","placeholder":"Email"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].coworkers[coworkerIndex].email},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key:
                  'groups.' +
                  groupIndex +
                  '.coworkers.' +
                  coworkerIndex +
                  '.email',
                type: 'trim',
                value: $event.target.value,
              })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Phone")]),_c('input',{attrs:{"type":"text","placeholder":"Phone"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].coworkers[coworkerIndex].phone},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key:
                  'groups.' +
                  groupIndex +
                  '.coworkers.' +
                  coworkerIndex +
                  '.phone',
                type: 'text',
                value: $event.target.value,
              })}}})])]),_c('br'),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,langIndex){return _c('div',{key:'lang-' + groupIndex + '-' + coworkerIndex + '-' + langIndex,staticClass:"web-pages-lang-group"},[_c('img',{staticStyle:{"width":"24px"},attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Worktitle")]),_c('input',{attrs:{"type":"text","placeholder":"Worktitle"},domProps:{"value":_vm.selectedBlock.groups[groupIndex].coworkers[coworkerIndex]
                  .title[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                  key:
                    'groups.' +
                    groupIndex +
                    '.coworkers.' +
                    coworkerIndex +
                    '.title.' +
                    lang.code,
                  type: 'text',
                  value: $event.target.value,
                })}}})])])}),0)])}),_c('button',{staticClass:"add-coworker-button",on:{"click":function($event){return _vm.addCoworker(groupIndex)}}},[_vm._v(" Add coworker ")])],2)}),_c('button',{staticClass:"add-coworker-group-button",on:{"click":_vm.addCoworkerGroup}},[_vm._v(" Add coworker group ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }