<template>
  <div class="admin-table">
    <div class="table-header">
      <span class="category label"
        >Final companies - {{ finalCompanies.length }}</span
      >
    </div>
    <div class="inner">
      <div v-for="(value, i) in finalCompanies" :key="'cat-' + i">
        <span>{{ getCompanyByVismaId(value.vismaId).name }}</span>
        <span
          class="action remove"
          @click="
            $store.dispatch('notifications/updateExclusion', {
              type: 'add',
              field: 'Companies',
              value: value.vismaId,
            })
          "
        >
          Exclude
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PostsSelectionFinalCompanies",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("companies", ["getCompanyByVismaId"]),
    ...mapGetters("users", ["getUserNameByVismaId"]),
    ...mapGetters("notifications", ["finalCompanies"]),
  },
};
</script>
