import { db } from "../main.js";
// import { firestoreAction } from 'vuexfire'
import firebase from "firebase/app";

const massEditShortlists = {
  namespaced: true,
  state: {
    selectedProduct: null,
    excludedCompanyShortlists: [],
    excludedSharedShortlists: [],
    loading: false,
    replace: false,
    replaceProduct: null,
    init: false,
    add: false,
    selectedPriceGroups1: [],
    selectedPriceGroups2: [],
    excludedPriceGroups1: [],
    excludedPriceGroups2: [],
  },
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setSelectedProduct: (state, payload) => {
      state.selectedProduct = payload;
    },
    setExcludedCompanyShortlists: (state, payload) => {
      state.excludedCompanyShortlists = payload;
    },
    setExcludedSharedShortlists: (state, payload) => {
      state.excludedSharedShortlists = payload;
    },
    setReplace: (state, payload) => {
      state.replace = payload;
    },
    setReplaceProduct: (state, payload) => {
      state.replaceProduct = payload;
    },
    setInit: (state, payload) => {
      state.init = payload;
    },
    setAdd: (state, payload) => {
      state.add = payload;
    },
    setSelectedPriceGroups1: (state, payload) => {
      state.selectedPriceGroups1 = payload;
    },
    setSelectedPriceGroups2: (state, payload) => {
      state.selectedPriceGroups2 = payload;
    },
    setExcludedPriceGroups1: (state, payload) => {
      state.excludedPriceGroups1 = payload;
    },
    setExcludedPriceGroups2: (state, payload) => {
      state.excludedPriceGroups2 = payload;
    },
  },
  getters: {
    finalCompanies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;
      return companies
        .filter((company) => {
          if (
            state.selectedPriceGroups1.length === 0 &&
            state.selectedPriceGroups2.length === 0
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups1.length > 0 &&
            state.selectedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return true;
          }

          if (
            state.selectedPriceGroups2.length > 0 &&
            state.selectedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return true;
          }

          return false;
        })
        .filter((company) => {
          if (
            state.excludedPriceGroups1.length > 0 &&
            state.excludedPriceGroups1.indexOf(
              company.companyPriceGroup.toString()
            ) !== -1
          ) {
            return false;
          }

          if (
            state.excludedPriceGroups2.length > 0 &&
            state.excludedPriceGroups2.indexOf(
              company.companyPriceGroup2.toString()
            ) !== -1
          ) {
            return false;
          }

          return true;
        })
        .map((company) => {
          return {
            vismaId: company.vismaId,
            firebaseId: company.id,
            shortlists: company.shortlists,
          };
        });
    },
    selectedCompanyShortlists: (state, getters, rootState) => {
      const shortlists = rootState.companyShortlists.shortlists
        .filter((shortlist) => {
          return Object.keys(shortlist.products).find(
            (id) => id === state.selectedProduct
          );
        })
        .filter(
          (shortlist) =>
            state.excludedCompanyShortlists.indexOf(shortlist.id) === -1
        );

      if (
        state.selectedPriceGroups1.length === 0 &&
        state.selectedPriceGroups2.length === 0 &&
        state.excludedPriceGroups1.length === 0 &&
        state.excludedPriceGroups2.length === 0
      ) {
        return shortlists;
      } else {
        return shortlists.filter((shortlist) => {
          // Check if any one company in final companies has this shortlist

          return getters.finalCompanies.find((company) => {
            return company.shortlists[shortlist.id] !== undefined
              ? true
              : false;
          }) !== undefined
            ? true
            : false;
        });
      }
    },
    selectedSharedShortlists: (state, getters, rootState) => {
      const shortlists = rootState.sharedShortlists.shortlists
        .filter((shortlist) => {
          return Object.keys(shortlist.products).find(
            (id) => id === state.selectedProduct
          );
        })
        .filter(
          (shortlist) =>
            state.excludedSharedShortlists.indexOf(shortlist.id) === -1
        );

      if (
        state.selectedPriceGroups1.length === 0 &&
        state.selectedPriceGroups2.length === 0 &&
        state.excludedPriceGroups1.length === 0 &&
        state.excludedPriceGroups2.length === 0
      ) {
        return shortlists;
      } else {
        return shortlists.filter((shortlist) => {
          // Check if any one company in final companies has this shortlist

          return getters.finalCompanies.find((company) => {
            return company.shortlists[shortlist.id] !== undefined
              ? true
              : false;
          }) !== undefined
            ? true
            : false;
        });
      }
    },
    getCompanyShortlistNameById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).name;
    },
    getCompanyShortlistCompanyIdById: (state, getters, rootState) => (id) => {
      return rootState.companyShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).companyId;
    },
    getSharedShortlistNameInternalById: (state, getters, rootState) => (id) => {
      return rootState.sharedShortlists.shortlists.find(
        (shortlist) => shortlist.id === id
      ).nameInternal;
    },
  },
  actions: {
    filterSelection: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["selected" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setSelected" + field, newSelection);

      console.log(context.state["selected" + field]);
    },
    selectAll: function (context, payload) {
      const selection = Object.keys(payload).map((key) => {
        return key;
      });
      context.commit("setSelectedCompanyCategories", selection);
    },
    filterExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field, newSelection);
    },
    replaceProduct: (context) => {
      let shortlistIds = [];
      const removeProductId = context.state.selectedProduct;
      const addProductId = context.state.replaceProduct;

      context.getters.selectedSharedShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      context.getters.selectedCompanyShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      let updatePromises = shortlistIds.map((id) => {
        return new Promise((resolve) => {
          db.collection("shortlists")
            .doc(id)
            .update({
              ["products." + removeProductId]:
                firebase.firestore.FieldValue.delete(),
              ["products." + addProductId]: true,
            })
            .then(() => {
              resolve();
            });
        });
      });

      return Promise.all(updatePromises).then(() => {
        return context.dispatch("exitEditing").then(() => {
          console.log("finished updating");
          return {
            amount: shortlistIds.length,
            productRemoved: removeProductId,
            productAdded: addProductId,
          };
        });
      });
    },
    addProduct: (context) => {
      let shortlistIds = [];
      const addProductId = context.state.replaceProduct;

      context.getters.selectedSharedShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      context.getters.selectedCompanyShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      let updatePromises = shortlistIds.map((id) => {
        return new Promise((resolve) => {
          db.collection("shortlists")
            .doc(id)
            .update({
              ["products." + addProductId]: true,
            })
            .then(() => {
              resolve();
            });
        });
      });

      return Promise.all(updatePromises).then(() => {
        return context.dispatch("exitEditing").then(() => {
          console.log("finished updating");
          return {
            amount: shortlistIds.length,
            productAdded: addProductId,
          };
        });
      });
    },
    deleteProduct: (context) => {
      let shortlistIds = [];
      const productId = context.state.selectedProduct;

      context.getters.selectedSharedShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      context.getters.selectedCompanyShortlists.map((list) => {
        shortlistIds.push(list.id);
        return list;
      });

      let deletePromises = shortlistIds.map((id) => {
        return new Promise((resolve) => {
          db.collection("shortlists")
            .doc(id)
            .update({
              ["products." + productId]: firebase.firestore.FieldValue.delete(),
            })
            .then(() => {
              resolve();
            });
        });
      });

      return Promise.all(deletePromises).then(() => {
        return context.dispatch("exitEditing").then(() => {
          console.log("finished deleteing");
          return {
            amount: shortlistIds.length,
            product: productId,
          };
        });
      });
    },
    setupEditing: (context) => {
      console.log(context);
    },
    exitEditing: (context) => {
      console.log(context);
      context.commit("setSelectedProduct", null);
      context.commit("setExcludedCompanyShortlists", []);
      context.commit("setExcludedSharedShortlists", []);
      context.commit("setReplace", false);
      context.commit("setReplaceProduct", null);
      context.commit("setAdd", false);
    },
    updateExclusion: function (context, payload) {
      const type = payload.type;
      const field = payload.field;
      const value = payload.value;

      let newSelection = context.state["excluded" + field + "Shortlists"];

      // Do array action.
      if (type === "add") {
        newSelection = newSelection
          .filter((val) => val !== value)
          .concat(value);
      } else if (type === "remove") {
        newSelection = newSelection.filter((val) => val !== value);
      }

      context.commit("setExcluded" + field + "Shortlists", newSelection);
    },
  },
};
export default massEditShortlists;
