<template>
  <div class="admin-table company-shortlists-company-selection">
    <div class="table-header">
      <span class="category label">Companies</span>
      <div class="company-search">
        <input type="text" v-model="search" placeholder="Search company..." />
      </div>
    </div>
    <div class="inner">
      <div
        v-for="(value, key) in getCompaniesBySearch(search)"
        :key="'cat-' + key"
      >
        <span>{{ value.name }}</span>
        <span
          @click="
            $store.commit('companyShortlists/setCompanyId', value.vismaId)
          "
          class="action add"
        >
          Select
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CompanyShortlistsCompanySelection",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.companyShortlists.loading,
    }),
    ...mapGetters("companies", ["getCompanyByVismaId", "getCompaniesBySearch"]),
  },
  components: {},
  methods: {},
};
</script>
